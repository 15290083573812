import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Directive, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { ICity } from '@libs/services/location/city/city';
import { ICountry } from '@libs/services/location/country/country';
import { LocationServiceCommon } from '@libs/services/location/location.service.common';
import { IRegion } from '@libs/services/location/region/region';
import { DateCommon } from '@libs/shared/date/date.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { RegistrationInputedAction } from '@libs/store/registration/actions/registration.action';
import { arrayShuffle, range } from '@libs/utils/array-functions';
import { padStart } from '@libs/utils/pad-string';

@Directive()
export class FrictionlessStep1ComponentCommon implements OnDestroy, OnInit {
  @ViewChild('usernameElement', { static: true })
  usernameElement: ElementRef<HTMLInputElement>;
  @ViewChild('emailElement', { static: true })
  emailElement: ElementRef<HTMLInputElement>;

  public username: string;
  public checkingUsername: boolean;
  public usernameAvailable: boolean;
  public usernameError: string;
  public email: string;
  public checkingEmail: boolean;
  public emailAvailable: boolean;
  public emailError: string;
  public suggestionString: string;
  public suggestedEmail: string;
  public password: string;
  public passwordValid: boolean;
  public passwordError: string;
  public birthdayDay: string;
  public birthdayMonth: string;
  public birthdayYear: string;
  public birthdayValid: boolean;
  public birthdayError: string;
  public locationAuto: boolean;
  public locationLatitude: number;
  public locationLongitude: number;
  public locationCountry: string;
  public locationState: string;
  public locationCity: number;
  public reference: string;
  public referenceOptions: string[];

  public countries: ICountry[] = [];
  public regions: IRegion[] = [];
  public cities: ICity[] = [];

  protected subscriptions: Subscription[] = [];
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    protected userService: UserServiceCommon,
    protected location: LocationServiceCommon,
    protected store: Store<IApplicationState>,
  ) {
    const userOrigins = UserCommon.getReferenceStatusList();

    this.referenceOptions = arrayShuffle(userOrigins.slice(0, userOrigins.length - 1));
    this.referenceOptions.push(userOrigins[userOrigins.length - 1]);
  }

  public ngOnInit(): void {
    this.store
      .select('registration')
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((value): void => {
        if (value.username) {
          this.username = value.username;
          this.checkUsername();
        }

        if (value.email) {
          this.email = value.email;
          this.checkExistingEmail();
        }

        if (value.password) {
          this.password = value.password;
          this.checkPassword(true);
        }

        if (value.birthdate) {
          [this.birthdayYear, this.birthdayMonth, this.birthdayDay] = value.birthdate.split('-');
          this.checkDate();
        }

        this.locationCountry = value.country_id || 'BR';
        this.locationState = value.state_id;
        this.locationCity = value.city_id;
        this.suggestionString = '';
        this.suggestedEmail = '';

        const reference: string = UserCommon.getReferenceStatus(value['extended[select_one]']);

        if (reference !== '') {
          this.reference = reference;
        }
      });

    this.store
      .select('locationStoreCountries')
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((countries): void => {
        if (!this.locationCountry) {
          return;
        }
        this.countries = countries;
        this.location.loadRegions(this.locationCountry);
      });

    this.store
      .select('locationStoreRegions')
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((regions): void => {
        this.regions = regions;
        if (!this.locationState) {
          return;
        }

        this.regions = regions;
        this.location.loadCities(this.locationState);
      });

    this.store
      .select('locationStoreCities')
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((cities): void => {
        this.cities = cities;
      });

    this.location.loadCountries();

    this.initTypeAhead(this.usernameElement, this.checkUsernameDelayed);
    this.initTypeAhead(this.emailElement, this.checkExistingEmailDelayed);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  protected initTypeAhead(inputElement: ElementRef<HTMLInputElement>, callback: () => void): void {
    this.subscriptions.push(
      fromEvent(inputElement.nativeElement, 'input')
        .pipe(debounceTime(750))
        .subscribe((): void => callback.call(this)),
    );
  }

  public canContinue(): boolean {
    return (
      this.usernameAvailable && this.emailAvailable && this.passwordValid && this.birthdayValid && this.checkLocation()
    );
  }

  public commitStep(): void {
    this.store.dispatch(
      new RegistrationInputedAction({
        data: {
          username: this.username,
          email: this.email,
          re_email: this.email,
          password: this.password,
          re_password: this.password,
          birthdate: `${this.birthdayYear}-${this.birthdayMonth}-${this.birthdayDay}`,
          country_id: this.locationCountry,
          state_id: this.locationState,
          city_id: this.locationCity,
          'extended[select_one]': this.reference && UserCommon.getReferenceKey(this.reference),
        },
      }),
    );
  }

  public validateUsername(): boolean {
    if (!this.username) {
      this.usernameError = 'modules.registration.pages.location.username.missing';
      return false;
    }

    if (!UserCommon.isValidUserName(this.username)) {
      this.usernameError = 'modules.registration.pages.location.username.invalid';
      return false;
    }

    return true;
  }

  public checkUsername(): void {
    if (!this.validateUsername()) {
      this.usernameAvailable = false;
      return;
    }

    this.checkExistingUsername();
  }

  public checkExistingUsername(): void {
    this.usernameError = '';
    this.usernameAvailable = false;
    this.checkingUsername = true;

    this.userService.isUsernameUnique(this.username).subscribe((data): void => {
      this.checkingUsername = false;

      if (data.body === null) {
        this.usernameAvailable = true;
        this.usernameError = 'modules.frictionless.username.available';
        return;
      }

      this.usernameAvailable = false;
      this.usernameError = 'modules.registration.pages.location.username.taken';
    });
  }

  public checkUsernameDelayed(): void {
    this.assignUsernameWithoutSpacesBeggining();

    if (this.usernameAvailable) {
      this.usernameError = '';
    }

    if (this.usernameError) {
      this.validateUsername();
    }

    this.usernameAvailable = false;
    this.checkUsername();
  }

  public assignUsernameWithoutSpacesBeggining(): void {
    this.username = UserCommon.removeUndueSpacesAtTheBegginging(this.username);
  }

  public removeUndueSpacesAfterFocusOut(event: Event): void {
    this.username = UserCommon.removeUndueSpacesAfterFocusOut(this.username);
    event.stopPropagation();
    this.checkUsername();
  }

  public checkExistingEmail(): void {
    this.checkingEmail = true;
    this.emailAvailable = false;
    this.emailError = '';

    this.userService.isEmailUnique(this.email).subscribe(
      (data: HttpResponse<any>): void => {
        this.checkingEmail = false;

        const emailMessage: string = this.userService.getEmailMessageByOKResponse(data);

        if (emailMessage !== '') {
          this.emailError = emailMessage;
          return;
        }

        this.emailAvailable = true;
        this.emailError = 'common.email.available';
      },
      (err: HttpErrorResponse): void => {
        this.checkingEmail = false;

        this.emailError = this.userService.getEmailMessageByErrorResponse(err);
      },
    );
  }

  public checkExistingEmailDelayed(): void {
    this.emailAvailable = false;
    this.checkExistingEmail();
  }

  public checkPassword(isOnChange?: boolean): void {
    if (!isOnChange && !this.passwordError) {
      this.passwordValid = UserCommon.isValidPassword(this.password);
      return;
    }

    if (!this.password) {
      this.passwordValid = false;
      this.passwordError = 'modules.registration.pages.registration2.password.missing';
      return;
    }

    this.passwordValid = UserCommon.isValidPassword(this.password);
    if (!this.passwordValid) {
      this.passwordError = 'modules.registration.pages.registration2.password.invalid';
      return;
    }
    this.passwordError = 'modules.frictionless.password.valid';
  }

  public generateRange(start: number, end: number): string[] {
    return range(start, end + 1).map((x): string => padStart(x.toString(), 2, '0'));
  }

  public generateYearRange(): string[] {
    const startYear = new Date().getFullYear() - UserCommon.MIN_AGE;
    const endYear = startYear - UserCommon.MAX_AGE;
    return range(startYear, endYear).map((x): string => x.toString());
  }

  public monthName(num: string): string {
    const index = parseInt(num, 10) - 1;
    const options = [
      'common.months.january',
      'common.months.february',
      'common.months.march',
      'common.months.april',
      'common.months.may',
      'common.months.june',
      'common.months.july',
      'common.months.august',
      'common.months.september',
      'common.months.october',
      'common.months.november',
      'common.months.december',
    ];

    return options[index];
  }

  public checkDate(): void {
    if (!this.birthdayYear || !this.birthdayMonth || !this.birthdayDay) {
      this.birthdayError = 'modules.frictionless.birthday.not_filled';

      return;
    }

    const date = new Date(`${this.birthdayYear}-${this.birthdayMonth}-${this.birthdayDay}`);

    let validDate = !isNaN(date.getTime());
    if (validDate) {
      validDate =
        this.birthdayYear === date.getUTCFullYear().toString() &&
        this.birthdayMonth === this.maybePadZero(date.getUTCMonth() + 1) &&
        this.birthdayDay === this.maybePadZero(date.getUTCDate());
    }

    this.birthdayValid = validDate && DateCommon.yearDiff(date) >= 18;
    if (this.birthdayValid) {
      this.birthdayError = 'modules.frictionless.birthday.valid';
      return;
    }

    this.birthdayError = 'modules.frictionless.birthday.over18';
    if (!validDate) {
      this.birthdayError = 'modules.frictionless.birthday.invalid';
    }
  }

  public checkLocation(): boolean {
    return this.locationAuto || (!!this.locationCountry && !!this.locationState && !!this.locationCity);
  }

  public locationFallback(): void {
    this.locationAuto = false;
  }

  public setStateName(stateName: string): void {
    this.locationState = stateName;
    this.locationCity = undefined;
    this.cities = [];
    this.location.loadCities(this.locationState);
  }

  public setCountryName(countryName: string): void {
    this.locationCountry = countryName;
    this.locationState = undefined;
    this.locationCity = undefined;
    this.regions = [];
    this.cities = [];
    this.location.loadRegions(this.locationCountry);
  }

  protected maybePadZero(n: number): string {
    if (n < 10) {
      return `0${n}`;
    }

    return n.toString();
  }
}
