import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfile } from '@libs/shared/profile/profile';

@Pipe({
  name: 'getName',
  pure: true,
  standalone: false,
})
export class GetName implements PipeTransform {
  constructor(public translate: TranslateService) {
    //
  }

  transform(profile: IProfile | undefined): string {
    if (profile === undefined) {
      return this.translate.instant('common.not_set');
    }

    const formattedUsername: string = this.translate.instant('modules.main.pages.profile.formatted_username', {
      username: profile.username,
    });

    if (profile.age) {
      return this.translate.instant('modules.main.pages.profile.username_and_age', {
        formatted_username: formattedUsername,
        age: profile.age,
      });
    }

    return formattedUsername;
  }
}
