import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IProfilesState, profilesEntityAdapter } from '@libs/store/profiles-v2/state';

const selectProfilesV2Feature = createFeatureSelector<IProfilesState>('profiles');
const selectSearchProfiles = createFeatureSelector<number[]>('storeSearchProfiles');

const { selectEntities, selectIds, selectAll } = profilesEntityAdapter.getSelectors(selectProfilesV2Feature);

export const selectProfilesEntities = selectEntities;
export const selectProfilesIds = selectIds as (state: object) => number[];
export const selectAllProfiles = selectAll;

export const selectProfileById = (profileId: number) =>
  createSelector(selectProfilesEntities, (entities) => entities[profileId]);

export const selectAllSearchProfiles = createSelector(selectSearchProfiles, (state: number[]) => state);
