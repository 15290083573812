export enum LOCATION_OPTIONS {
  PROFILE = 0,
  CUSTOM = 1,
}

export enum ADVANCED_SEARCH_CONTROLS {
  LOCATION_COUNTRY_ID = 'country',
  LOCATION_STATE_ID = 'region',
  LOCATION_CITY_ID = 'city',
  LOCATION_RADIUS_FIRST = 'location_radius_first',
  AGE = 'age_between',
  SORTKEY = 'sortkey',
  HEIGHT = 'height',
  SEX = 'sex',
  MATCH_SEX = 'match_sex',
  BODY_TYPE = 'body_type',
  ETHNICITY = 'ethnicity',
  HAIR_COLOR = 'hair_color',
  EYE_COLOR = 'eye_color',
  HAVE_CHILDREN = 'have_children',
  HAPPY_TO_TRAVEL = 'happy_to_travel',
  RELATIONSHIP = 'relationship',
  SMOKE = 'smoke',
  DRINK = 'drink',
  EDUCATION = 'education',
  OCCUPATION = 'occupation',
  INCOME = 'income',
  NET_WORTH = 'net_worth',
  FAVORITE = 'favorite',
  VIEWED = 'viewed',
  PHOTOS = 'photos',
  SELECT_ONE = 'select_one',
  KEYWORD_SEARCH = 'keyword_search',
}

export enum SORT_ACTIVITY {
  RECENTLY = 'recently',
  NEWEST = 'newest',
  NEAREST = 'nearest',
}
