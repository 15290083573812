import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import { MaterialIcons } from '@libs/shared/material-icons/material-icons.common';
import { IFavoriteAttribute } from '@libs/shared/profile/favorite-attribute';

@Pipe({
  name: 'favoriteAttribute',
  pure: true,
  standalone: false,
})
export class FavoriteAttribute implements PipeTransform {
  constructor(public translate: TranslateService) {
    //
  }

  transform({ isFavorite, profile }: IFavoriteAttribute): IProfileAttributes {
    if (!isFavorite) {
      return {
        favoriteIcon: MaterialIcons.FavoriteBorder,
        imageAlt: 'modules.main.pages.profile.add_to_favorites_short',
      };
    }

    return {
      favoriteIcon: MaterialIcons.Favorite,
      imageAlt: 'modules.main.pages.profile.favorited_short',
      interpolateParams: { gender: profile !== undefined && profile.sex },
    };
  }
}
