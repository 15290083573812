import { ChangeDetectionStrategy, Component, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import { ProfilePreviewComponentCommon } from '@libs/components/profile-preview/profile-preview.component.common';
import { ConversationCommon } from '@libs/shared/conversation/conversation.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ProfileContextActions } from '@libs/store/profile-context';

import { ListScrollPositionService } from '@meupatrocinio/modules/main/services/list-scroll-position/list-scroll-position.service';
import { NewModalComponent } from '@meupatrocinio/modules/main/shared/new-modal/new-modal.component';
import { TenMessageModalComponent } from '@meupatrocinio/modules/main/shared/ten-message-modal/ten-message-modal.component';
import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';

@Component({
  selector: 'mp-profile-preview-grid',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './profile-preview-grid.html',
  standalone: false,
})
export class ProfilePreviewGridComponent extends ProfilePreviewComponentCommon implements OnChanges {
  public attributes: IProfileAttributes[];

  constructor(
    public translate: TranslateService,
    protected auth: AuthenticationService,
    protected profileService: ProfileService,
    protected store: Store<IApplicationState>,
    protected router: Router,
    protected downloadManager: DownloadManagerService,
    protected modal: AdvancedModalService,
    protected listScrollPositionService: ListScrollPositionService,
  ) {
    super(translate, auth, profileService, store, router, downloadManager);
  }

  public handleUrl() {
    this.store.dispatch(
      ProfileContextActions.profileContextReceived({
        profileContext: {
          originName: this.router.url.split('?')[0].split('/').pop(),
          previousUrl: this.router.url,
          promotionName: this.profile.promotionName ?? '',
          score: this.profile.score ?? '',
          recommendationId: this.profile.recommendationId ?? '',
        },
      }),
    );
  }

  forceUpdate(profile: IProfile): void {
    this.profile = profile;

    this.setAttributes();
  }

  setAttributes(): void {
    this.attributes = [
      {
        isVerified: (): boolean => this.isMelt,
        className: 'changed-attribute',
        imagePath: '/assets/img/melt-badge-only.svg',
        imageAlt: 'common.list.melt',
      },
      {
        isVerified: (): boolean => this.isElite(),
        imagePath: '/assets/img/elite-badge-circle.svg',
        imageAlt: 'modules.main.pages.profile.member_elite',
      },
      {
        isVerified: (): boolean => this.isPremium(),
        imagePath: '/assets/img/sbp-diamond-circle.svg',
        imageAlt: 'modules.main.pages.profile.member_sbp',
      },
      {
        isVerified: (): boolean => this.isVerified(),
        imagePath: '/assets/img/shield.svg',
        imageAlt: 'modules.main.pages.profile.member_verified',
      },
      {
        isVerified: (): boolean => this.isNew(),
        imagePath: '/assets/img/new.svg',
        imageAlt: 'modules.main.pages.profile.member_new',
        interpolateParams: { gender: this.getGender() },
      },
    ];
  }

  getProfilePicture(): string {
    return ImageHelper.getProfilePicture(this.profile, this.user, UserCommon.PICTURE_EXTRA_LARGE);
  }

  canOpenConversation(event: MouseEvent): void {
    const isFree: boolean = UserCommon.isDaddyMommyFree(this.user);
    const limitMessageThreshold: boolean = UserCommon.hasSurpassedMessageThreshold(this.user);
    const hasConversation: boolean = ConversationCommon.isConversation(this.currentConversation);

    if (isFree && (limitMessageThreshold || hasConversation)) {
      event.preventDefault();
      event.stopPropagation();
      this.showInterstitial();
    }
  }

  trackGroupByFn(_: number, item: IProfileAttributes): string {
    return item.imageAlt;
  }

  protected showInterstitial(): void {
    if (UserCommon.hasSurpassedMessageThreshold(this.user)) {
      this.modal.open(TenMessageModalComponent);
      return;
    }

    this.modal.open(NewModalComponent);
  }
}
