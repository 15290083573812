import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Subject, timer } from 'rxjs';
import { concatMap, debounceTime, takeUntil } from 'rxjs/operators';

import { GenericViewComponentCommon } from '@libs/components/generic-view/generic-view.component.common';
import { IApplicationState } from '@libs/store/application-state';
import { PullRefreshAction } from '@libs/store/ui/actions/pullrefresh.action';
import { Widths } from '@libs/utils/widths';

import { ListScrollPositionService } from '@meupatrocinio/modules/main/services/list-scroll-position/list-scroll-position.service';
import { LoadingMoreAnimations } from '@meupatrocinio/modules/main/shared/loading-more/animations/loading-more.animations';
import { NagbarService } from '@meupatrocinio/services/nagbar/nagbar.service';

import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';

@Component({
  selector: 'mp-grid-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './grid-view.html',
  animations: [LoadingMoreAnimations.loadingAnimated()],
  standalone: false,
})
export class GridViewComponent extends GenericViewComponentCommon implements OnDestroy, AfterViewInit {
  @ViewChild('listEndDelimiter') listEndDelimiter: ElementRef<HTMLDivElement>;
  @ViewChild('scroll') scroll: CdkVirtualScrollViewport;

  private readonly cancelSubscription: Subject<void> = new Subject();
  private readonly scrollingEnd = new Subject<void>();

  private scrollPosition = 0;
  public isScrolling = false;
  public columnsPerRow = 2;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected store: Store<IApplicationState>,
    protected nagbarService: NagbarService,
    protected router: Router,
    protected listScrollPositionService: ListScrollPositionService,
    private scrollDispatcher: ScrollDispatcher,
    protected globalObjectService: GlobalObjectService,
    protected downloadManager: DownloadManagerService,
    @Inject(DOCUMENT) protected document: Document,
  ) {
    super(downloadManager, changeDetectorRef, store, document);

    this.isUniqueItemPerLine = false;
  }

  ngAfterViewInit(): void {
    this.columnsPerRow = this.getCountPerRow(this.globalObjectService.window.innerWidth);
    this.listScrollPositionService.setIsReadyToRestoreScrollPosition(true);

    this.subscriptions.push(
      this.scrollDispatcher
        .scrolled()
        .pipe(takeUntil(this.cancelSubscription))
        .subscribe(() => {
          this.scrollPosition = this.getScrollOffset();
          this.startScrolling();
        }),
    );

    this.scrollStopHandler();

    this.subscriptions.push(this.ensureScreenIsFilled().pipe(takeUntil(this.cancelSubscription)).subscribe());
  }

  ngOnDestroy(): void {
    this.store.dispatch(new PullRefreshAction({ refreshing: false }));
    this.listScrollPositionService.setScrollPosition(this.scrollPosition);
    super.ngOnDestroy();
  }

  private ensureScreenIsFilled() {
    return timer(200).pipe(
      concatMap(() => {
        if (this.canCheckListHeight()) {
          return this.handleLoadProfiles();
        }

        return this.ensureScreenIsFilled();
      }),
    );
  }

  private canCheckListHeight() {
    return (
      this.items.length > 0 &&
      !this.isDownloading() &&
      this.listEndDelimiter !== undefined &&
      this.listEndDelimiter.nativeElement !== undefined
    );
  }

  private handleLoadProfiles() {
    if (this.isListShorterThanScreen() && !this.isAtLastPage()) {
      this.loadMoreItems.emit();

      return this.ensureScreenIsFilled();
    }

    this.cancelSubscription.next();

    return EMPTY;
  }

  private isListShorterThanScreen() {
    return (
      this.listEndDelimiter.nativeElement.offsetTop < this.globalObjectService.window.innerHeight &&
      this.listEndDelimiter.nativeElement.offsetTop > 0
    );
  }

  @HostListener('window:resize') onResize(): void {
    this.columnsPerRow = this.getCountPerRow(this.globalObjectService.window.innerWidth);
    const innerWidth = this.globalObjectService.window.innerWidth;
    this.groupedData = this.groupData(this.columnItems, innerWidth);
    this.scroll?.checkViewportSize();
  }

  public getCountPerRow(width: number): number {
    const isPrimaryList: boolean = this.isPrimaryList();
    const isSecondaryList: boolean = this.isSecondaryList();
    const isSearchList: boolean = this.isSearchList();
    const isSecondaryListWithoutSidebar: boolean = this.isSecondaryListWithoutSidebar();

    const conditions = [
      {
        check: (): boolean => (isPrimaryList || isSecondaryListWithoutSidebar) && width >= Widths.XL,
        value: 7,
      },
      {
        check: (): boolean => (!isPrimaryList || !isSecondaryListWithoutSidebar) && width >= Widths.XL,
        value: 5,
      },
      {
        check: (): boolean => isSecondaryListWithoutSidebar && width >= Widths.MD,
        value: 6,
      },
      {
        check: (): boolean => isSecondaryList && width >= Widths.LG && width < Widths.XL,
        value: 4,
      },
      {
        check: (): boolean => isSearchList && width >= Widths.LG,
        value: 4,
      },
      { check: (): boolean => width >= Widths.LG, value: 6 },
      {
        check: (): boolean => !isPrimaryList && width >= Widths.MD,
        value: 3,
      },
      { check: (): boolean => width >= Widths.MD, value: 5 },
      {
        check: (): boolean => width >= Widths.XSM && width < Widths.MD,
        value: 4,
      },
      { check: (): boolean => width >= Widths.XXSM, value: 3 },
    ];

    for (const condition of conditions) {
      if (condition.check()) {
        return condition.value;
      }
    }

    return 2;
  }

  protected isSecondaryList(): boolean {
    return this.router.url.includes('main/favorites');
  }

  protected isPrimaryList(): boolean {
    return this.router.url.includes('main/home') || this.router.url.includes('main/recommended');
  }

  protected isSearchList(): boolean {
    return this.router.url.includes('main/search');
  }

  protected isSecondaryListWithoutSidebar() {
    return this.isSecondaryList() && !this.nagbarService.hasNagbar$.getValue();
  }

  public handleComponentChanges(changes: SimpleChanges): void {
    if (!changes.items || !changes.items.currentValue) {
      return;
    }

    const { currentValue }: { currentValue: number[] } = changes.items;
    let innerWidth: number = window.innerWidth;

    this.columnItems = currentValue;

    if (currentValue.length === 0) {
      this.columnItems = this.skeleton;
    }

    if (this.isUniqueItemPerLine) {
      innerWidth = 0;
    }

    this.groupedData = this.groupData(this.columnItems, innerWidth);
    this.scroll?.checkViewportSize();
  }

  public groupData(columnItems: number[], innerWidth?: number): number[][] {
    const countPerRow: number = this.getCountPerRow(innerWidth);

    return columnItems.reduce((result, item: number, index: number): number[] => {
      const chunkIndex = Math.floor(index / countPerRow);

      if (!result[chunkIndex]) {
        result[chunkIndex] = [];
      }

      result[chunkIndex].push(item);

      return result;
    }, []);
  }

  private startScrolling() {
    if (!this.isScrolling) {
      this.isScrolling = true;
      this.changeDetectorRef.markForCheck();
    }
    this.scrollingEnd.next();
  }

  private scrollStopHandler(): void {
    const subscription = this.scrollingEnd.pipe(debounceTime(300)).subscribe(() => {
      this.isScrolling = false;
      this.changeDetectorRef.markForCheck();
    });

    this.subscriptions.push(subscription);
  }

  private getScrollOffset(): number {
    return this.scroll?.measureScrollOffset() ?? 0;
  }
}
