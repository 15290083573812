@if (isEmptyList()) {
  <p class="tw-text-center">{{ emptyListText | translate }}</p>
}

@else {
  <ng-container>
    <virtual-scroller
      class="user-list clearfix tw-h-screen"
      #scroll
      [items]="groupedData"
      [parentScroll]="scroll.window"
      [enableUnequalChildrenSizes]="true"
    >
      <div
        class="users"
        *ngFor="let chunk of scroll.viewPortItems; trackBy: trackGroupByFn"
      >
        <mp-profile-preview-list
          *ngFor="let item of chunk; trackBy: trackProfileIdByFn"
          class="user-list-person tw-mb-3"
          [profileId]="item"
        ></mp-profile-preview-list>
      </div>
    </virtual-scroller>
  </ng-container>
}
