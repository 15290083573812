<div class="half-page-simple-header visible-xs visible-sm">
    <a [routerLink]="['/']">
        <img src="/assets/img/meu-patrocinio-logo.svg">
    </a>
</div>
<div class="half-page-banner full-height">
    <div class="content">
        <img [src]="getBackgroundImagePath()">
    </div>
</div>
<div class="half-page-content container complete-registration-onboarding">
    <div class="row">
        <div class="col-md-5">
            <div class="img-block hidden-xs hidden-sm">
                <a [routerLink]="['/']">
                    <mp-brand-image
                        [src]="'login-logo.svg'"
                        [description]="'Logo cadastro'"
                        [class]="{'!tw-h-20': brand === 'meuprincipe'}"
                    />
                </a>
            </div>
            <h1>
                {{ 'modules.frictionless.subtitle' | translate }}
            </h1>
            <p class="login-p">
                {{ 'modules.frictionless.subtext' | translate }}
            </p>
            <div class="step-box clearfix">
                <div class="col-md-10 col-md-offset-1">
                    <h2>
                        {{ 'modules.frictionless.begin' | translate }}
                    </h2>
                    <div class="form-field">
                        <label for="genderSelect">
                            {{ 'modules.frictionless.sex' | translate }}
                        </label>
                        <select
                            class="hover:!tw-border-b-primary-base"
                            id="genderSelect"
                            [(ngModel)]="myGender"
                            name="genderSelect"
                            required
                        >
                            <option
                                disabled
                                [ngValue]="undefined"
                            >
                                {{ 'modules.frictionless.select_option' | translate }}
                            </option>
                            <option
                                *ngFor="let option of genderOptions()"
                                [ngValue]="option"
                            >
                                {{ genderToString(option) | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="form-field">
                        <label for="matchSelect">
                            {{ 'modules.frictionless.match_sex' | translate }}
                        </label>
                        <select
                            class="hover:!tw-border-b-primary-base"
                            id="matchSelect"
                            [(ngModel)]="myMatch"
                            name="matchSelect"
                            (change)="setLookingFor()"
                            required
                        >
                            <option
                                disabled
                                [ngValue]="undefined"
                            >
                                {{ 'modules.frictionless.select_option' | translate }}
                            </option>
                            <option
                                *ngFor="let option of matchSexOptions()"
                                [ngValue]="option"
                            >
                                {{ matchSexToString(option) | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="form-field">
                        <label for="typeSelect">
                            {{ 'modules.frictionless.type' | translate }}
                        </label>
                        <select
                            class="hover:!tw-border-b-primary-base"
                            id="typeSelect"
                            [(ngModel)]="myType"
                            name="typeSelect"
                            required
                        >
                            <option
                                disabled
                                [ngValue]="undefined"
                            >
                                {{ 'modules.frictionless.select_option' | translate }}
                            </option>
                            <option
                                *ngFor="let option of typeOptions()"
                                [ngValue]="option"
                            >
                                {{ typeToString(option) | translate }}
                            </option>
                        </select>
                        <small class="warning sugar-explanation [&>*>a]:!tw-text-primary-base">
                            <mp-advanced-translate key="modules.frictionless.what_is_sugar1">
                                <a
                                    class="text-link"
                                    href="javascript:void(0);"
                                    mp-translate-param="daddy"
                                    (click)="sendMyType(TYPE_SUGAR_PARENT)"
                                >
                                    {{ typeToString(TYPE_SUGAR_PARENT) | translate }}
                                </a>
                                <a
                                    class="text-link"
                                    href="javascript:void(0);"
                                    mp-translate-param="baby"
                                    (click)="sendMyType(TYPE_SUGAR_BABY)"
                                >
                                    {{ typeToString(TYPE_SUGAR_BABY) | translate }}
                                </a>
                            </mp-advanced-translate>
                        </small>
                    </div>
                    <button
                        id="registration-button-continue-registration"
                        class="mp-main-button bt-lg bt-shadow full-width !tw-bg-primary-base !tw-border-primary-base"
                        [disabled]="!canContinue()"
                        (click)="commitStep()"
                    >
                        {{ 'modules.frictionless.continue' | translate }}
                    </button>
                    <div class="mini-terms">
                        <mp-advanced-translate key="modules.frictionless.miniterms">
                            <a
                                href="https://www.meupatrocinio.com/termos-e-condicoes-de-uso"
                                mp-translate-param="termsLink"
                                target="_blank"
                            >
                                {{ 'modules.main.pages.terms_conditions.title' | translate }}
                            </a>
                            <a
                                href="https://www.meupatrocinio.com/politica-de-privacidade"
                                mp-translate-param="privacyLink"
                                target="_blank"
                            >
                                {{ 'modules.main.pages.privacy_rules.privacy_policy' | translate }}
                            </a>
                        </mp-advanced-translate>
                    </div>
                </div>
            </div>
            <div class="bottom-login">
                <div>
                    {{ 'modules.frictionless.has_account' | translate }}
                </div>
                <a
                    id="registration-button-return-login"
                    class="!tw-text-primary-base"
                    (click)="login()"
                >
                        {{ 'modules.frictionless.login' | translate }}
                </a>
            </div>
            <div class="business-info small">
                {{ getCnpj() }}
            </div>
        </div>
    </div>
</div>
<div
    class="complete-registration-modal"
    [ngClass]="getModalHiddenClass()"
>
    <div
        class="complete-registration"
        #registrationModal
    >
        <div
            class="dial-box"
            cdkTrapFocus
            *ngIf="!registering"
        >
            <div
                class="header"
                [ngClass]="getConfirmEmailClass()"
            >
                <div [ngClass]="{'confirmation-header': confirmEmail}">
                    <div
                        class="page"
                        *ngIf="canShowStepCount()"
                    >
                        {{ 'modules.frictionless.step' | translate:{step: step - STEP_TERMS} }}
                    </div>
                    <mp-brand-image
                        *ngIf="confirmEmail"
                        [src]="'login-logo.svg'"
                        [description]="'common.site_name' | translate"
                        [class]="{'!tw-h-16': brand === 'meuprincipe'}"
                    />
                    <div
                        class="title"
                        [ngClass]="{'text-center': confirmEmail}"
                    >
                        {{ stepTitle | translate }}
                    </div>
                </div>
                <div *ngIf="canShowAvatar()">
                    <div
                        class="avatar-container"
                        [ngClass]="{ empty: !pictureData }"
                    >
                        <img
                            [src]="pictureData"
                            alt
                        >
                        <input
                            #imageInput
                            type="file"
                            [accept]="getAcceptedImageTypes()"
                            (change)="processPicture($event)"
                            [attr.aria-label]="'modules.frictionless.select_picture' | translate"
                            required
                        >
                    </div>
                </div>
            </div>
            <mp-frictionless-step0 *ngIf="isAtTerms()"></mp-frictionless-step0>
            <mp-frictionless-step1 *ngIf="isAtBasic()"></mp-frictionless-step1>
            <mp-frictionless-step2
                *ngIf="isAtPicture()"
                (skipped)="confirmSkipRegistration()"
            ></mp-frictionless-step2>
            <mp-frictionless-step3
                *ngIf="isAtBody()"
                (skipped)="confirmSkipRegistration()"
            ></mp-frictionless-step3>
            <mp-frictionless-step4
                *ngIf="isAtSocial()"
                (skipped)="confirmSkipRegistration()"
            ></mp-frictionless-step4>
            <mp-frictionless-step5
                *ngIf="isAtFinal()"
                [myGender]="myGender"
                [lookingForMen]="lookingForMen"
                [lookingForWomen]="lookingForWomen"
                (skipped)="confirmSkipRegistration()"
                (resetStep)="resetStep($event)"
            ></mp-frictionless-step5>
            <mp-step-confirmation
                *ngIf="isAtConfirmation()"
                [confirmEmail]="confirmEmail"
                [email]="email"
            ></mp-step-confirmation>
        </div>
    </div>
</div>
<div
    class="fullscreen-loading"
    *ngIf="registering"
    cdkTrapFocus
>
    <div
        id="loading"
        *ngIf="!registrationError"
    >
        <div>
            {{ 'modules.frictionless.saving_data' | translate }}
            <p>{{ 'modules.frictionless.saving_data.do_not_reload' | translate }}</p>
        </div>
        <div class="spin-loader">
            <svg
                height="40px"
                style="enable-background:new 0 0 50 50;"
                version="1.1"
                viewBox="0 0 50 50"
                width="40px"
                x="0px"
                :xml:space="preserve"
                :xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns="http://www.w3.org/2000/svg"
                y="0px"
            >
                <path
                    d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
                    class="!tw-fill-primary-base"
                >
                    <animateTransform
                        attributeName="transform"
                        attributeType="xml"
                        dur="0.6s"
                        from="0 25 25"
                        repeatCount="indefinite"
                        to="360 25 25"
                        type="rotate"
                    ></animateTransform>
                </path>
            </svg>
        </div>
    </div>
    <div *ngIf="registrationError">
        <div>
            <p>{{ 'modules.frictionless.registration_error.line_1' | translate }}</p>
            <mp-advanced-translate key="modules.frictionless.registration_error.line_2">
                <span mp-translate-param="highlight">
                    <strong>
                        {{ 'modules.frictionless.registration_error.highlight' | translate }}
                    </strong>
                    <br>
                </span>
            </mp-advanced-translate>
        </div>
        <button
            class="mp-main-button bt-md try-again"
            cdkFocusInitial
            type="button"
            (click)="backToStart()"
        >
            {{ 'modules.frictionless.try_again' | translate }}
        </button>
        <a href="https://www.meupatrocinio.com/faq" target="_blank">
            {{ 'modules.frictionless.registration_error.support_link' | translate}}
        </a>
    </div>
</div>
