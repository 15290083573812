import { animate, style, transition, trigger } from '@angular/animations';

export const LoadingMoreAnimations = {
  loadingAnimated: (
    options: {
      translateY?: string;
      enterDelay?: number;
      leaveDelay?: number;
      useOpacity?: boolean;
    } = {},
  ) => {
    const { translateY = '60px', enterDelay = 200, leaveDelay = 300, useOpacity = false } = options;

    const getStyles = (y: string) => ({
      transform: `translateY(${y})`,
      ...(useOpacity && { opacity: y === '0' ? 1 : 0 }),
    });

    return trigger('loadingAnimated', [
      transition(':enter', [
        style(getStyles(translateY)),
        animate(`${enterDelay}ms ease-in-out`, style(getStyles('0'))),
      ]),
      transition(':leave', [
        style(getStyles('0')),
        animate(`${leaveDelay}ms ease-in-out`, style(getStyles(translateY))),
      ]),
    ]);
  },
};
