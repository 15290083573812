import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { IApplicationState } from '@libs/store/application-state';
import { PageTitleActions } from '@libs/store/page-title';
import { AdvancedSearchActions } from '@libs/store/search/actions';
import { PullRefreshAction } from '@libs/store/ui/actions/pullrefresh.action';
import { Medias } from '@libs/utils/medias';
import { AdvancedSearchService } from '@meupatrocinio/modules/advanced-search/services/advanced-search.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './advanced-search.component.html',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {
  private downloadManager: DownloadManagerService = inject(DownloadManagerService);
  private globalObjectService: GlobalObjectService = inject(GlobalObjectService);
  private store: Store<IApplicationState> = inject(Store<IApplicationState>);
  private breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  public advancedSearchService = inject(AdvancedSearchService);
  public displayingResult$ = this.advancedSearchService.displayingResult$;

  public profilesId$ = this.store.pipe(select('storeSearchProfiles'));

  ngOnInit(): void {
    this.setPageTitle();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new PullRefreshAction({ refreshing: false }));
  }

  public canShowAdvancedSearchForm(isDisplayingSearch: boolean, isDesktop: boolean) {
    return (!isDesktop && !isDisplayingSearch) || isDesktop;
  }

  public canShowSearchResults(isDisplayingSearch: boolean, isDesktop: boolean) {
    return (!isDesktop && isDisplayingSearch) || isDesktop;
  }

  public applyFilters() {
    this.advancedSearchService.update();
  }

  public loadList() {
    if (this.downloadManager.isDownloading('search') || this.downloadManager.isAtLastPage('search')) {
      return;
    }

    if (!this.advancedSearchService.canLoadMoreItems.value) {
      return;
    }

    this.store.dispatch(AdvancedSearchActions.loadList());
  }

  public saveSearch() {
    this.advancedSearchService.saveSearch();
  }

  public back(displaying: boolean) {
    if (!displaying) {
      this.globalObjectService.window.history.back();
      return;
    }

    this.store.dispatch(AdvancedSearchActions.setIsDisplayingResult({ isDisplayingResult: false }));
  }

  public isDesktop$() {
    return this.breakpointObserver.observe(Medias.MIN_WIDTH_992).pipe(
      map((breakPointState) => {
        return breakPointState.matches;
      }),
    );
  }

  protected setPageTitle() {
    this.store.dispatch(
      PageTitleActions.changePageTitle({
        title: 'common.page_title.search',
      }),
    );
  }
}
