import { Config } from '@meupatrocinio/config';

export class ABTestsConfig {
  public static readonly SEARCH_V2_TEST_NAME = 'MP-20231017_search_v2';
  public static readonly SAFE2PAY_TEST_NAME = 'MP-20250228_safe2pay';

  public static areABTestsEnabled(): boolean {
    return Config.areABTestsEnabled;
  }
}
