import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';

@Pipe({
  name: 'getProfilePicture',
  pure: true,
  standalone: false,
})
export class GetProfilePicture implements PipeTransform {
  protected get user(): UserCommon {
    return this.auth.get();
  }

  constructor(
    public translate: TranslateService,
    public auth: AuthenticationService,
  ) {
    //
  }

  transform(profile: IProfile): string {
    return ImageHelper.getProfilePicture(profile, this.user, UserCommon.PICTURE_LIGHTWEIGHT);
  }
}
