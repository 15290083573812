import { Injectable, inject } from '@angular/core';
import { IDeviceInformation } from '@libs/modules/main/services/customer-support/interfaces/device-information.interface';
import { ISupportRequestData } from '@libs/modules/main/services/customer-support/interfaces/support-request-data.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Config } from '@meupatrocinio/config';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';
import { DeviceDetectorFacadeService } from '@libs/shared/device-detector-facade.service';

@Injectable()
export class CustomerSupportService {
  private readonly store: Store<IApplicationState> = inject(Store);
  private readonly authHttp: AuthHttpService = inject(AuthHttpService);
  private readonly globalObjectService: GlobalObjectService = inject(GlobalObjectService);
  private readonly deviceDetectorFacadeService: DeviceDetectorFacadeService = inject(DeviceDetectorFacadeService);

  public readonly ENDPOINT_CUSTOMER_SUPPORT: string = 'customer-support/';

  private user: UserCommon;
  private userDeviceInformation: readonly IDeviceInformation[] = [];

  private subscriptions: Subscription[] = [];

  constructor() {
    this.subscriptions.push(
      this.store.select('user').subscribe((user: UserCommon): void => {
        if (!user) {
          return;
        }

        this.user = user;
      }),
    );

    this.userDeviceInformation = [
      {
        title: 'Espaço utilizado no local storage:',
        value: this.getLocalStorageUsedSpace(),
      },
      {
        title: 'Versão da aplicação do usuário:',
        value: this.getUserAppVersion(),
      },
      {
        title: 'Navegador:',
        value: this.getBrowserName(),
      },
      {
        title: 'Versão do navegador:',
        value: this.getBrowserVersion(),
      },
      {
        title: 'Sistema operacional:',
        value: this.getOS(),
      },
      {
        title: 'User Agent do navegador:',
        value: this.getUserAgent(),
      },
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private getLocalStorageUsedSpace(): string {
    let totalSpaceUsed = 0;

    for (const key in localStorage) {
      const keyValueSizeInMb: number = (localStorage[key].length * 2) / 1024 / 1024;

      if (!isNaN(keyValueSizeInMb) && localStorage.hasOwnProperty(key)) {
        totalSpaceUsed += keyValueSizeInMb;
      }
    }

    return `${totalSpaceUsed.toFixed(2)}mb`;
  }

  private getBrowserName(): string {
    return this.deviceDetectorFacadeService.getDeviceInfo().browser;
  }

  private getBrowserVersion(): string {
    return this.deviceDetectorFacadeService.getDeviceInfo().browser_version;
  }

  private getOS(): string {
    return this.deviceDetectorFacadeService.getDeviceInfo().os;
  }

  private getUserAgent(): string {
    return this.globalObjectService.window.navigator.userAgent;
  }

  private getUserAppVersion(): string {
    return Config.version;
  }

  public sendSupportRequest(
    title: string,
    message: string,
    params: any,
    successCallback: () => void,
    errorCallback: (error: any) => void,
  ): void {
    const endpoint: string = Config.serverIp + this.ENDPOINT_CUSTOMER_SUPPORT;

    const data: ISupportRequestData = {
      ...params,
      subject: title,
      message,
      name: this.user.username,
      custom_private_fields: this.userDeviceInformation,
    };

    this.authHttp.post(endpoint, data).subscribe(
      (): void => {
        successCallback();
      },
      (error: any): void => {
        errorCallback(error);
      },
    );
  }
}
