import { Directive, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';
import { FrictionlessStep1Component } from '../step1.component';

@Directive({
  selector: '[emailInput]',
  standalone: false,
})
export class EmailInputDirective {
  private readonly allowedCharactersRegex = /[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.@]/;

  constructor(
    private ngModel: NgModel,
    private component: FrictionlessStep1Component,
  ) {
    //
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (!this.allowedCharactersRegex.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const pastedText = event.clipboardData?.getData('text') || '';
    const sanitizedText = pastedText
      .split('')
      .filter((character) => this.allowedCharactersRegex.test(character))
      .join('');

    this.ngModel.control.setValue(sanitizedText);
    this.component.checkExistingEmail();
  }
}
