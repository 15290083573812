<div class="tw-flex tw-w-full tw-justify-between tw-mb-5 tw-flex-col min-[992px]:tw-flex-row tw-gap-2">
  <p class="tw-font-bold tw-text-[22px]">
    @if (isDownloading) {
        {{ 'modules.main.pages.search_filter.searching' | translate}}
      } @else {
    {{ numberOfProfilesFound }}
    }
  </p>
  <div class="tw-flex tw-gap-3 tw-justify-between tw-flex-row max-[992px]:tw-flex-row-reverse">
    <button 
      class="tw-text-[14px] tw-underline tw-text-nowrap" 
      (click)="resetFilter()"
    >
      {{ 'modules.main.pages.search_filter.reset_filter' | translate }}
    </button>
    <button
      class="tw-border-solid tw-border-2 tw-border-red-500 tw-h-fit tw-text-nowrap tw-rounded-md tw-p-3 tw-leading-[1] tw-text-red-500 tw-font-bold"
      (click)="saveSearch()"
    >
      {{ 'modules.main.pages.search_filter.save_search' | translate }}
    </button>
  </div>
</div>
<div class="tw-overflow-auto">
  @if (filterList().length !== 0) {
    <ul class="tw-flex tw-gap-2 md:tw-flex-wrap tw-w-max md:tw-w-fit tw-mb-5 md:tw-mb-0">
    @for (filter of filterList(); track $index) {
    <li>
      <div class="hover:tw-transition-colors tw-w-fit tw-text-[14px] tw-gap-3 tw-p-2 tw-px-3  tw-bg-white tw-border tw-rounded-md tw-border-gray-200 tw-flex tw-items-center">
        <button 
          type="button" 
          (click)="filter.focus()"
        >
          {{ filter.label | translate: filter.labelParams }}
        </button>
        @if (!!filter.clearCallback) {
        <button 
          type="button" 
          class="tw-contents" 
          (click)="filter.clearCallback()"
        >
          <i 
            class="hover:tw-transition-colors tw-bg-gray-100 !tw-text-gray-300 tw-rounded-md tw-p-[2px] material-icons !tw-text-[14px] hover:!tw-text-red-500 hover:!tw-bg-red-50"
            aria-hidden="true"
          >
            &#xE14C;
          </i>
        </button>
        }
      </div>
    </li>
    }
  </ul>
  }
</div>
