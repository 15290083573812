<div class="aspect-ratio">
    <div class="main-link content">
        <a
            class="piclink"
            *ngIf="hasProfileId()"
            [routerLink]="['/main', 'profile', profileId]"
        ></a>
        <div class="info">
            <ul
                id="home-list-badge-user"
                class="attributes-list">
                    <mp-badges [profile]="profile"></mp-badges>
            </ul>
            <div
                class="online-tip"
                *ngIf="isOnline()"
            ></div>
            <div
                class="bottom"
                [ngClass]="{ skeleton: !hasProfileId() }"
            >
                <div
                    id="home-list-info-user"
                    class="personal-info"
                    [ngClass]="{ skeleton: !hasProfileId() }"
                >
                    <div *ngIf="!hasProfileId()">
                        <div class="skeleton-pics skeleton-item skeleton-item-square skeleton-item-rounded"></div>
                        <div class="skeleton-username skeleton-item skeleton-item-pill"></div>
                        <div class="skeleton-location skeleton-item skeleton-item-pill"></div>
                    </div>
                    <div *ngIf="hasProfileId()">
                        <div class="pics-count">
                            <i
                                class="material-icons"
                                aria-hidden="true"
                            >
                                &#xE251;
                            </i>
                            <div>
                                {{ profile | numberOfPhotos }}
                            </div>
                        </div>
                        <div
                            class="category"
                            *ngIf="isElite()"
                        >
                            <label class="text-uppercase">
                                {{ 'modules.main.pages.profile.member_elite' | translate }}
                            </label>
                        </div>
                        <h4 class="notranslate">{{ profile | getName }}</h4>
                        <p class="tw-text-xl">{{ profile | getLocation }}</p>
                    </div>
                </div>
                <div
                    class="action"
                    [ngClass]="{ skeleton: !hasProfileId() }"
                >
                    <div *ngIf="hasProfileId()">
                        <a [routerLink]="['/main', 'conversation', 'message', profileId]">
                            <div (click)="canOpenConversation($event)">
                                <i
                                    class="material-icons"
                                    aria-hidden="true"
                                >
                                    &#xE0CB;
                                </i>
                                <div class="sr-only">
                                    {{ 'modules.main.pages.profile.send_message' | translate }}
                                </div>
                            </div>
                        </a>
                        <a
                            href="javascript:void(0)"
                            (click)="toogleLike()"
                        >
                            <ng-container>
                                <i
                                    class="material-icons"
                                    aria-hidden="true"
                                    [innerHTML]="({ isFavorite: isFavorite, profile: profile } | favoriteAttribute).favoriteIcon"
                                    [ngClass]="{ 'active': isLiked() }"
                                ></i>
                                <div class="sr-only">
                                    {{ { isFavorite: isFavorite, profile: profile } | favoriteAttribute | getTranslate }}
                                </div>
                            </ng-container>
                        </a>
                    </div>
                </div>
            </div>
        </div>
      @if (hasProfileId()) {
        <mp-img-srcset
          [url]="profile | getProfilePicture"
          imgClass="avatar"
          place="lists"
        ></mp-img-srcset>
      }
    </div>
</div>
