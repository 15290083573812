import { createActionGroup, props } from '@ngrx/store';

import { ICreateBoletoPayment } from '@libs/modules/payment-v2/interfaces/create-boleto-payment.interface';
import { ICreatePixPayment } from '@libs/modules/payment-v2/interfaces/create-pix-payment.interface';
import { IHandleCreditCardPayment } from '@libs/modules/payment-v2/interfaces/handle-credit-card-payment.interface';
import { IPaymentAttemptData } from '@libs/modules/payment-v2/interfaces/payment-data-attempt.interface';
import { IPrepareCreditCardPayment } from '@libs/modules/payment-v2/interfaces/prepare-credit-card-payment.interface';

export const ProviderSwitchActions = createActionGroup({
  source: 'Provider Switch',
  events: {
    'Prepare Credit Card Payment': props<IPrepareCreditCardPayment>(),
    'Handle Credit Card Payment': props<IHandleCreditCardPayment>(),
    'Handle Boleto Payment': props<ICreateBoletoPayment>(),
    'Handle Pix Payment': props<ICreatePixPayment>(),
    'Tokenize For AllCash': props<{ paymentData: IPaymentAttemptData }>(),
    'Tokenize For Safe2Pay': props<{ paymentData: IPaymentAttemptData }>(),
  },
});
