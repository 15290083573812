
@if (canShowHighLight()) {
  <div class="baby-highlight tw-w-full tw-h-2.5"></div>
}

<div class="list-view-mode-content" [ngClass]="getContentClass()">
    <a
        class="profile-link"
        *ngIf="hasProfileId()"
        [routerLink]="['/main', 'profile', profileId]"
    ></a>
    <div class="skeleton-item avatar" *ngIf="!hasProfileId()"></div>
    <mp-img-srcset *ngIf="hasProfileId()" [url]="profile | getProfilePicture" imgClass="avatar" place="lists"></mp-img-srcset>
    <div class="list-view-mode-details">
        <div class="username">
            <div class="title skeleton-item skeleton-item-pill" *ngIf="!hasProfileId()"></div>
            <div class="title notranslate" *ngIf="hasProfileId()">
                {{ profile | getName }}
            </div>
            <div class="online-tip" *ngIf="isOnline()"></div>
        </div>
        <div class="location skeleton-item skeleton-item-pill" *ngIf="!hasProfileId()"></div>
        <div class="location" *ngIf="hasProfileId()">{{ profile | getLocation }}</div>
        <div class="headline skeleton-item skeleton-item-pill" *ngIf="!hasProfileId()"></div>
        <ng-container *ngIf="hasProfileId()">
            <div class="headline" *ngIf="hasProfileHeadline()">
                {{ profile.headline }}
            </div>
        </ng-container>
    </div>
    <ul class="badges" *ngIf="!hasProfileId()">
        <li class="skeleton-item skeleton-item-pill"></li>
        <li class="skeleton-item skeleton-item-pill"></li>
    </ul>
    <ul class="badges" *ngIf="hasProfileId()">
        <mp-badges [profile]="profile"></mp-badges>
    </ul>
    <ul class="photos" *ngIf="!hasProfileId()">
        <li class="skeleton-item"></li>
        <li class="skeleton-item"></li>
        <li class="skeleton-item"></li>
        <li class="skeleton-item"></li>
        <li class="skeleton-item skeleton-item-pill"></li>
    </ul>
    <ul class="photos" *ngIf="hasProfileId()">
        <li *ngFor="let photo of ( { profile: profile } | getPhotos )">
            <img [src]="photo" />
        </li>
        <li *ngIf="{ photosToDisplay: { profile: profile } | getPhotos, profile: profile } | getRemainingNumberOfPhotos">
            <div class="title">
                {{ 'modules.main.pages.profile.more_photos' | translate: { numPhotos: ({ photosToDisplay: { profile: profile } | getPhotos, profile: profile } | getRemainingNumberOfPhotos) } }}
            </div>
        </li>
    </ul>
    <div class="actions">
        <ng-container *ngIf="!hasProfileId()">
            <div class="action-btn skeleton-item"></div>
            <div class="action-btn skeleton-item"></div>
        </ng-container>
        <button class="action-btn" *ngIf="hasProfileId()" type="button" (click)="toogleLike()">
            <ng-container>
                <i class="material-icons" aria-hidden="true" [innerHTML]="getFavoriteAttributes().favoriteIcon"
                    [ngClass]="{ 'active': isLiked() }"></i>
                <div [ngClass]="{ 'sr-only': !isDesktop }">
                    {{ { isFavorite: isFavorite, profile: profile } | favoriteAttribute | getTranslate }}
                </div>
            </ng-container>
        </button>
        <a *ngIf="hasProfileId()" [routerLink]="['/main', 'conversation', 'message', profileId]">
            <div class="action-btn btn-red !tw-bg-primary-base" (click)="canOpenConversation($event)">
                <i class="material-icons" aria-hidden="true"> &#xE8AF; </i>
                <div [ngClass]="{ 'sr-only': !isDesktop }">
                    {{ 'modules.main.pages.profile.send_message' | translate }}
                </div>
            </div>
        </a>
    </div>
</div>
