import { ISearchState } from '@libs/store/search/interface/search-state.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectFeature = createFeatureSelector<ISearchState>('searchState');

export const AdvancedSearchSelectors = {
  isInitialized: createSelector(selectFeature, (state: ISearchState) => state?.isInitialized),
  currentSearchName: createSelector(selectFeature, (state: ISearchState) => state?.currentSearchName),
  isDisplayingResult: createSelector(selectFeature, (state: ISearchState) => state?.isDisplayingResult),
  searchFilter: createSelector(selectFeature, (state: ISearchState) => state?.profileSearch),
  savedSearches: createSelector(selectFeature, (state: ISearchState) => state?.savedSearches),
  lastCountryName: createSelector(selectFeature, (state: ISearchState) => state?.lasCountryName),
  lastRegionName: createSelector(selectFeature, (state: ISearchState) => state?.lastRegionName),
  lastCityName: createSelector(selectFeature, (state: ISearchState) => state?.lastCityName),
};
