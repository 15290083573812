import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { GenericViewComponentCommon } from '@libs/components/generic-view/generic-view.component.common';
import { IApplicationState } from '@libs/store/application-state';
import { PullRefreshAction } from '@libs/store/ui/actions/pullrefresh.action';
import { Widths } from '@libs/utils/widths';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';

@Component({
  selector: 'mp-list-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './list-view.html',
  standalone: false,
})
export class ListViewComponent extends GenericViewComponentCommon implements OnDestroy {
  constructor(
    protected downloadManager: DownloadManagerService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected store: Store<IApplicationState>,
    @Inject(DOCUMENT) protected document: Document,
  ) {
    super(downloadManager, changeDetectorRef, store, document);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new PullRefreshAction({ refreshing: false }));
  }

  public getCountPerRow(width: number): number {
    const ROW_WITH_5_PROFILES: number = 5;
    const ROW_WITH_4_PROFILES: number = 4;
    const ROW_WITH_3_PROFILES: number = 3;
    const ROW_WITH_2_PROFILES: number = 2;
    const ROW_WITH_1_PROFILE: number = 1;

    if (width === 0) {
      return ROW_WITH_1_PROFILE;
    }

    if (width >= Widths.XL) {
      return ROW_WITH_5_PROFILES;
    }

    if (width >= Widths.LG) {
      return ROW_WITH_4_PROFILES;
    }

    if (width >= Widths.MD) {
      return ROW_WITH_3_PROFILES;
    }

    if (width >= Widths.SM) {
      return ROW_WITH_4_PROFILES;
    }

    return ROW_WITH_2_PROFILES;
  }

  public handleComponentChanges(changes: SimpleChanges): void {
    if (!changes.items || !changes.items.currentValue) {
      return;
    }

    const { currentValue }: { currentValue: number[] } = changes.items;
    let innerWidth: number = window.innerWidth;

    this.columnItems = currentValue;

    if (currentValue.length === 0) {
      this.columnItems = this.skeleton;
    }

    if (this.isUniqueItemPerLine) {
      innerWidth = 0;
    }

    this.groupedData = this.groupData(this.columnItems, innerWidth);
    this.changeDetectorRef.markForCheck();
  }

  public groupData(columnItems: number[], innerWidth?: number): number[][] {
    const countPerRow: number = this.getValidRowSize(this.getCountPerRow(innerWidth));
    const newArray: number[][] = [];
    const incrementedNumber: number = 1;
    const limit: number = Math.ceil(columnItems.length / countPerRow);

    for (let i = 0; i < limit; i++) {
      newArray.push(columnItems.slice(i * countPerRow, (i + incrementedNumber) * countPerRow));
    }

    return newArray;
  }
}
