import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfile } from '@libs/shared/profile/profile';

@Pipe({
  name: 'getLocation',
  pure: true,
  standalone: false,
})
export class GetLocation implements PipeTransform {
  constructor(public translate: TranslateService) {
    //
  }

  transform(profile: IProfile | undefined): string {
    if (profile === undefined) {
      return this.translate.instant('common.not_set');
    }

    return this.translate.instant('modules.main.pages.profile.user_location', {
      city: profile.cityName,
      province: profile.stateName,
    });
  }
}
