import { ViewportScroller } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { delay, filter, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScrollPositionEffects {
  private router: Router = inject(Router);
  private viewportScroller: ViewportScroller = inject(ViewportScroller);

  $handleScrollPosition = createEffect(
    () =>
      this.router.events.pipe(
        filter<Scroll>((e) => e instanceof Scroll),
        delay(200),
        tap({
          next: (e) => {
            if (this.isScrollPositionRecoverable(e)) {
              this.viewportScroller.scrollToPosition(e.position);
              return;
            }

            if (e.anchor !== null) {
              this.viewportScroller.scrollToAnchor(e.anchor);
              return;
            }

            if (!/message\/\d+$/.test(e.routerEvent.url)) {
              this.viewportScroller.scrollToPosition([0, 0]);
            }
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  private isScrollPositionRecoverable(e: Scroll) {
    return e?.position !== undefined && e?.position !== null && this.isProfileList(e.routerEvent.url);
  }

  private isProfileList(url: string) {
    return (
      url.includes('main/home') ||
      url.includes('main/recommended') ||
      url.includes('main/favorites') ||
      url.includes('main/search')
    );
  }
}
