import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import { DownloadManagerServiceCommon } from '@libs/modules/main/services/download-manager.service.common';
import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { MaterialIcons } from '@libs/shared/material-icons/material-icons.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ConversationSelectors, IConversation } from '@libs/store/conversations';
import { MeltProfilesSelectors } from '@libs/store/melt-profiles';
import { ProfileActions } from '@libs/store/profile/actions';
import { ProfilesSelectors } from '@libs/store/profiles-v2';
import { PreviousProfileIdAction } from '@libs/store/ui/actions/previous-profile-id.action';

@Directive()
export abstract class ProfilePreviewComponentCommon implements OnChanges, OnDestroy, OnInit {
  public profile: IProfile | undefined;
  public currentConversation: IConversation;

  @Input() profileId: number;
  @Input() fullSize: boolean;
  protected subscriptions: Subscription[] = [];
  protected favProfileIds: number[] = [];
  protected meltProfileIds: number[] = [];
  protected isFavorite = false;
  protected isMelt = false;

  protected get user(): UserCommon {
    return this.auth.get();
  }

  constructor(
    public translate: TranslateService,
    protected auth: AuthenticationServiceCommon,
    protected profileService: ProfileServiceCommon,
    protected store: Store<IApplicationState>,
    protected router: Router,
    protected downloadManagerService: DownloadManagerServiceCommon,
  ) {
    //
  }

  abstract forceUpdate(profile: IProfile): void;

  abstract getProfilePicture(): string;

  ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .pipe(
          select(ProfilesSelectors.selectProfileById(this.profileId)),
          filter((profile) => profile !== undefined),
          tap({
            next: (profile) => {
              this.forceUpdate(profile);
            },
          }),
          take(1),
        )
        .subscribe(),
    );

    this.refreshFavorites();
    this.refreshMelt();

    this.subscriptions.push(
      this.store
        .pipe(select(ConversationSelectors.selectCurrent))
        .subscribe((currentConversation: IConversation): void => {
          this.currentConversation = currentConversation;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  ngOnChanges(args: SimpleChanges): void {
    if (!args.profileId) {
      return;
    }

    this.setFavorite(args.profileId.currentValue);
    this.setMelt(args.profileId.currentValue);
    this.forceUpdate(args.profileId.currentValue);
  }

  toogleLike(): void {
    if (this.isLiked()) {
      this.isFavorite = false;
      this.store.dispatch(ProfileActions.unFavoriteProfile({ profile: this.profile }));
    } else {
      this.isFavorite = true;
      this.store.dispatch(ProfileActions.favoriteProfile({ profile: this.profile }));
    }
  }

  setPreviousProfileId(): void {
    this.store.dispatch(new PreviousProfileIdAction({ previousProfileId: this.profileId }));
  }

  getName(): string {
    if (this.profile === undefined) {
      return this.translate.instant('common.not_set');
    }

    const formatted_username = this.translate.instant('modules.main.pages.profile.formatted_username', {
      username: this.profile.username,
    });

    if (this.profile.age) {
      return this.translate.instant('modules.main.pages.profile.username_and_age', {
        formatted_username,
        age: this.profile.age,
      });
    }

    return formatted_username;
  }

  getExtendedAttribute(key: string, defaultValue = '', suffix = ''): string {
    if (this.profile === undefined || this.profile.profile_extended === undefined) {
      return defaultValue;
    }

    let status = UserCommon.getStatus(key, this.profile.profile_extended[key]);

    if (status && typeof status === 'string') {
      status = this.translate.instant(status);
    }

    return status + suffix;
  }

  getLocation(): string {
    if (this.profile === undefined) {
      return this.translate.instant('common.not_set');
    }

    return this.translate.instant('modules.main.pages.profile.user_location', {
      city: this.profile.cityName,
      province: this.profile.stateName,
    });
  }

  getHeadline(): string {
    return this.profile.headline;
  }

  isElite(): boolean {
    return UserCommon.isElite(this.profile);
  }

  isOnline(): boolean {
    return UserCommon.isOnline(this.profile);
  }

  isVerified(): boolean {
    return UserCommon.isPhotoVerified(this.profile);
  }

  isBabyPremium(): boolean {
    return UserCommon.isBabyPremium(this.profile);
  }

  isPremium(): boolean {
    return UserCommon.isPremium(this.profile);
  }

  isNew(): boolean {
    return UserCommon.isNew(this.profile);
  }

  isLiked(): boolean {
    return this.isFavorite;
  }

  hasProfileId(): boolean {
    return this.profileId !== undefined;
  }

  hasProfileHeadline(): boolean {
    return this.profile !== undefined && typeof this.profile.headline === 'string';
  }

  getNumberOfPhotos(): any {
    if (this.profile === undefined) {
      return '';
    }

    return this.translate.instant('modules.main.pages.profile.user_number_of_photos', {
      number_photos: this.profile.numberOfPhotos,
    });
  }

  refreshFavorites(): void {
    this.store
      .select('storeMyFavProfiles')
      .pipe(take(1))
      .subscribe((profileIds: number[]): void => {
        this.favProfileIds = profileIds;

        if (this.profile !== undefined) {
          this.setFavorite(this.profile.profile_id);
        }
      });
  }

  refreshMelt(): void {
    this.subscriptions.push(
      this.store
        .pipe(
          select(MeltProfilesSelectors.selectMeltProfiles),
          filter((): boolean => this.profile !== undefined),
        )
        .subscribe((profileIds: number[]): void => {
          this.meltProfileIds = profileIds;

          this.setMelt(this.profile.profile_id);
        }),
    );
  }

  getGender(): number {
    return this.profile !== undefined && this.profile.sex;
  }

  getTranslate(attribute: IProfileAttributes): string {
    if (attribute.imageAlt === '') {
      return '';
    }

    return this.translate.instant(attribute.imageAlt, attribute.interpolateParams);
  }

  getFavoriteAttributes(): IProfileAttributes {
    if (this.isLiked()) {
      return {
        favoriteIcon: MaterialIcons.Favorite,
        imageAlt: 'modules.main.pages.profile.favorited_short',
        interpolateParams: { gender: this.getGender() },
      };
    }

    return {
      favoriteIcon: MaterialIcons.FavoriteBorder,
      imageAlt: 'modules.main.pages.profile.add_to_favorites_short',
    };
  }

  private setFavorite(profileId: number): void {
    this.isFavorite = this.favProfileIds.indexOf(profileId) !== -1;
  }

  private setMelt(profileId: number): void {
    this.isMelt = this.meltProfileIds.indexOf(profileId) !== -1;
  }
}
