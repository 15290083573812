<form [formGroup]="getForm()" (submit)="submit()" class="tw-my-10">
  <fieldset class="tw-flex tw-flex-col tw-mb-5">
    <span>
      {{ "modules.whatsapp.form.label1" | translate }}
    </span>
    <label class="
      tw-flex tw-items-center tw-p-2 tw-w-full tw-gap-4 tw-font-normal
      tw-border-b-2 tw-border-gray-200 focus-within:tw-border-b-[#E00C00]
    "     [for]="CONTROL_NAME_TEL">
      +{{ DEFAULT_DDI }}
      <input [name]="CONTROL_NAME_TEL" [formControlName]="CONTROL_NAME_TEL"
        [id]="CONTROL_NAME_TEL" contactPhoneMask placeholder="(XX) 9XXXX-XXXX" type="tel" maxlength="15"
        class="tw-w-full tw-outline-none tw-font-normal">
    </label>
  </fieldset>
  <fieldset class="tw-flex tw-flex-col tw-mb-6">
    <span>
      {{ "modules.whatsapp.form.label2" | translate }}
    </span>
    <label class="
      tw-flex tw-items-center tw-p-2 tw-w-full tw-gap-4 tw-font-normal
      tw-border-b-2 tw-border-gray-200 focus-within:tw-border-b-[#E00C00]
    " [for]="CONTROL_NAME_CONFIRM_TEL">
      +{{ DEFAULT_DDI }}
      <input [name]="CONTROL_NAME_CONFIRM_TEL"
        [formControlName]="CONTROL_NAME_CONFIRM_TEL" placeholder="(XX) 9XXXX-XXXX" [id]="CONTROL_NAME_CONFIRM_TEL"
        contactPhoneMask type="tel" maxlength="15" class="tw-w-full tw-outline-none tw-font-normal peer">
    </label>
    <ul>
      <li class="tw-text-gray-500 tw-text-[13px]" [ngClass]="{
          'tw-text-green-800': showSuccessFeedbackRequiredNine(),
          'tw-text-red-800': showErrorFeedbackRequiredNine(),
        }">
        {{ "modules.whatsapp.form.validation.required_nine" | translate }}
      </li>
      <li class="tw-text-gray-500 tw-text-[13px]" [ngClass]="{
          'tw-text-green-800': showSuccessFeedbackIfPhoneAreTheSame(),
          'tw-text-red-800': showErrorFeedbackIfPhoneAreNotTheSame()
        }">
        {{ "modules.whatsapp.form.validation.phone_number_equals" | translate }}
      </li>
    </ul>
  </fieldset>
  <fieldset class="tw-my-8">
    <mp-checkbox [form]="getForm()" [controlName]="CONTROL_NAME_LGPD">
      <label class="tw-p-0 tw-text-[14px] tw-text-gray-700 tw-font-normal" [for]="CONTROL_NAME_LGPD">
        {{ "modules.whatsapp.form.lgpd" | translate }}
      </label>
    </mp-checkbox>
  </fieldset>
  @if (!(isLoading$ | async)) {
  <button class="
      tw-bg-[#128c7e] 
      tw-text-white tw-font-bold tw-flex tw-items-center
      tw-gap-3 tw-justify-center tw-w-full tw-p-3 
      tw-rounded-md
      disabled:tw-opacity-20" [disabled]="!getForm().valid">
    {{ "common.whatsapp" | translate }}
    <img class="tw-h-[1.8rem]" src="assets/img/whatsapp.svg">
  </button>
  } @else {
  <div class="tw-w-fit tw-m-auto">
    <mp-payment-loading [loadingText]="'modules.whatsapp.redirect'"></mp-payment-loading>
  </div>
  }
</form>
