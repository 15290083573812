<div 
  id="advanced-filter-container" 
  class="tw-h-full tw-flex tw-flex-col tw-gap-8"
>
  <div class="tw-flex tw-mt-7 tw-flex-col tw-gap-7 tw-shadow-sm tw-w-full tw-bg-white tw-rounded-md tw-h-fit tw-top-0 tw-relative">
    <div>
      <form 
        (change)="trySearch($event)" 
        [formGroup]="searchFilterForm"
      >
        <mp-saved-search (onClick)="restoreSearch($event)"/>
        <div class="tw-flex tw-justify-between tw-px-8">
          <p class="tw-text-[18px] tw-font-bold">
            {{ 'modules.main.pages.search_filter.filters' | translate }}
          </p>
          <button 
            type="button" 
            class="tw-text-[14px] tw-underline"  
            (click)="resetFilter()"
          >
            {{ 'modules.main.pages.search_filter.reset_filter' | translate }}
          </button>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-6 tw-bg-white tw-backdrop-blur-sm">
          <section 
            id="keyword_search"       
            class="tw-p-8"
          >
            <mp-text-input 
              [form]="searchFilterForm" 
              [controlName]="'keyword_search'" 
              [placeholder]="keywordPlaceholder"
              [label]="'modules.main.pages.search_filter.keywords' | translate"
              (onEntter)="update($event)"/>
          </section>
          <section 
            id="location" 
            class="tw-px-8"
          >
            <form [formGroup]="locationPreferenceForm">
              <fieldset>
                <p class="tw-text-[18px] tw-font-bold tw-mb-3">
                  {{ 'modules.main.pages.search_filter.location' | translate }}
                </p>
                <div class="tw-flex tw-flex-col tw-gap-1">
                  <div class="custom-radio-check mini">
                    <input 
                      (click)="resetLocations()" 
                      type="radio" 
                      checked 
                      formControlName="location"
                      id="profileLocation" 
                      [attr.auto-search-off]="true"
                      [value]="0"
                    >
                    <label 
                      for="profileLocation" 
                      class="tw-text-[14px] tw-font-semibold tw-text-gray-950"
                    >
                      {{ 'modules.main.pages.search_filter.location.profile' | translate:{ city: user.cityName } }}
                    </label>
                  </div>
                  <div class="custom-radio-check mini">
                    <input 
                      type="radio" 
                      formControlName="location" 
                      id="customLocation" 
                      [attr.auto-search-off]="true"
                      [value]="1"
                      (click)="isCustomLocationSelected$.set(true)"
                    >
                    <label 
                      for="customLocation" 
                      class="tw-text-[14px] tw-font-semibold tw-text-gray-950"
                    >
                      {{ 'modules.main.pages.search_filter.location.another' | translate }}
                    </label>
                  </div>
                </div>
              </fieldset>
            </form>
            @if (isCustomLocationSelected) {
            <fieldset class="tw-flex tw-flex-col tw-gap-3">
              <select 
                (change)="updateRegionOptions()" 
                id="country" 
                [attr.auto-search-off]="false"
                formControlName="country"
                class="tw-outline-none tw-w-full tw-flex tw-justify-between tw-items-center tw-p-3 tw-text-[13px] tw-border-b tw-border-gray-300 disabled:tw-opacity-35 focus-within:tw-border-red-600"
              >
                <option 
                  [ngValue]="undefined" 
                  selected="selected"
                >
                  {{ 'modules.main.pages.search_filter.location.country.prompt' | translate }}
                </option>
                @for (country of (countries$ | async); track $index) {
                <option [ngValue]="country.Country_str_code">
                  {{ country.Country_str_name }}
                </option>
                }
              </select>
              <select 
                formControlName="region" 
                id="region" 
                [attr.auto-search-off]="false"
                (change)="updateCityOptions()"
                class="tw-outline-none tw-w-full tw-flex tw-justify-between tw-items-center tw-p-3 tw-text-[13px] tw-border-b tw-border-gray-300 disabled:tw-opacity-35 focus-within:tw-border-red-600"
              >
                <option 
                  [ngValue]="undefined" 
                  selected="selected"
                >
                  {{ 'modules.main.pages.search_filter.location.province.all' | translate }}
                </option>
                @for (region of (regions$ | async); track $index) {
                <option [ngValue]="region.Admin1_str_code">
                  {{ region.Admin1_str_name }}
                </option>
                }
              </select>
              <select 
                formControlName="city"
                id="city"
                [attr.auto-search-off]="false"
                class="tw-outline-none tw-w-full tw-flex tw-justify-between tw-items-center tw-p-3 tw-text-[13px] tw-border-b tw-border-gray-300 disabled:tw-opacity-35 focus-within:tw-border-red-600"
              >
                <option 
                  [ngValue]="undefined" 
                  selected="selected"
                >
                  {{ 'modules.main.pages.search_filter.location.city.all' | translate }}
                </option>
                @for (city of (cities$ | async); track $index) {
                <option [ngValue]="city.Feature_int_id">
                  {{ city.Feature_str_name }}
                </option>
                }
              </select>
            </fieldset>
            }
          </section>
          <div>
          <section 
            id="location_radius_first"
            class="tw-p-8"
          >
            <fieldset>
              <div class="tw-flex tw-justify-between tw-cursor-pointer">
                <label class="tw-text-[16px] tw-font-bold">
                  {{ distanceStructure()!.label | translate }}
                </label>
                <div class="current-value">
                  {{ distanceStructure()!.currentValue | translate:{ label: distanceStructure()!.model } }}
                </div>
              </div>
              <nouislider 
                [connect]="distanceStructure()!.connect" 
                [tooltips]="distanceStructure()!.tooltips" 
                [step]="distanceStructure()!.step" 
                [min]="distanceStructure()!.min" 
                [max]="distanceStructure()!.max"
                [(ngModel)]="distanceStructure()!.model" 
                [formControlName]="distanceStructure()!.name" 
                [attr.auto-search-off]="true"
                id="location_radius_first"
              >
              </nouislider>
            </fieldset>
          </section>
            <section 
              id="sortkey" 
              class="tw-px-8"
            >
            <fieldset>
              <div 
                class="tw-flex tw-justify-between tw-h-[54px] tw-cursor-pointer" 
                (click)="handleOpendedOrderByFilter()"
              >
                <label class="tw-m-0 tw-text-[18px] tw-leading-[2rem] tw-self-center">
                 {{ "modules.main.pages.search_filter.order_by" | translate }}
                </label>
                <div class="tw-flex tw-gap-2 tw-cursor-pointer tw-h-fit tw-self-center">
                  <p class="tw-text-[13px] tw-text-end">
                      {{ 'common.list.' + searchFilterForm.get('sortkey').value | translate }}
                  </p>
                  <i 
                    class="material-icons !tw-text-[18px] tw-h-[16px] tw-transform tw-transition-transform tw-duration-300 tw-self-center"
                    [class.tw-rotate-180]="!searchActivityStructure().isHidden" 
                    aria-hidden="true"
                  >
                    &#xe5cf;
                  </i>
                </div>
              </div>
              <div [ngClass]="checklistModifierClass(searchActivityStructure())">
                  @for (item of searchActivityStructure().attributes; track $index) {
                <div class="custom-radio-check mini">
                  <input 
                    [id]="item.id" 
                    [value]="item.id" 
                    type="radio" 
                    formControlName="sortkey"
                    [attr.auto-search-off]="false"
                  >
                  <label [for]="item.id">
                    {{ item.translateKey | translate }}
                  </label>
                </div>
                }
              </div>
            </fieldset>
          </section>
           <div>
            @for (item of searchRangeStructure(); track $index) {
              <section 
                [id]="item.name" 
                class="tw-px-8 tw-pb-2"
              >
                <fieldset>
                  <div 
                    class="tw-flex tw-justify-between tw-h-[54px]" 
                    (click)="handleOpenedRangeFilter(item)"
                  >
                    <label class="tw-m-0 tw-text-[18px] tw-leading-[2rem] tw-self-center">
                      {{ item.label | translate }}
                    </label>
                    <div class="tw-flex tw-gap-2 tw-cursor-pointer tw-h-fit tw-self-center tw-max-w-[130px]">
                      <p class="tw-text-[13px] tw-justify-center">
                        {{ item.currentValue | translate:formatLabelValue(item.name, item.model) }}
                      </p>
                      <i 
                        class="material-icons !tw-text-[18px] tw-h-[16px] tw-transform tw-transition-transform tw-duration-300 tw-self-center"
                        [class.tw-rotate-180]="!item.isHidden" 
                        aria-hidden="true"
                      >
                        &#xe5cf;
                      </i>
                    </div>
                  </div>
                  <div [ngClass]="checklistModifierClass(item)">
                    <nouislider 
                      [attr.auto-search-off]="false"
                      [connect]="[false,true,false]" 
                      [tooltips]="[true,true]" 
                      [step]="item.step"
                      [(ngModel)]="item.model" 
                      [min]="item.min" 
                      [max]="item.max" 
                      [formControlName]="item.name"
                      [ngModelOptions]="{ standalone: true }" 
                      [id]="item.name"/>
                  </div>
                </fieldset>
              </section>
              }
              </div>
            <div 
              id="search-filter-container" 
              class="tw-flex tw-flex-col"
            >
              @for (attribute of searchStructure(); track $index) {
                @if (isEligibleForFilter(attribute.displayForDaddyMommy)) {
                <section 
                  [id]="attribute.name" 
                  class="tw-px-8 tw-pb-4"
                >
                <fieldset class="tw-flex tw-flex-col">
                  <div 
                    class="tw-flex tw-w-full tw-justify-between tw-cursor-pointer tw-h-[45px]"
                    (click)="handleOpenedFilter(attribute)"
                  >
                    <p class="tw-text-[18px] tw-font-bold tw-text-gray-900 tw-max-w-[154px] tw-leading-[2rem] tw-self-center">
                      @if (!isMatchSexAttribute(attribute.name)) {
                      {{ attribute.name | translate }}
                      } @else {
                      {{ getMatchSexFilterLabel(attribute.name) }}
                      }
                    </p>
                    <button class="tw-text-[13px] tw-flex tw-items-center tw-gap-2 tw-text-end">
                      @if (containsMoreThanOneValue(searchFilterForm, attribute.name)) {
                        {{ "modules.main.pages.search_filter.filter_counter"| translate: { value:
                      searchFilterForm.get(attribute.name).value.length } }}
                      } @else if (containsOnlyOneValue(searchFilterForm, attribute.name)) {
                        {{ getFormControlOption(searchFilterForm, attribute) | translate }}
                      } @else {
                        {{ "modules.main.pages.search_filter.filter_empty" | translate }}
                      }
                      <i 
                        class="material-icons !tw-text-[18px] tw-transform tw-transition-transform tw-duration-300 tw-self-center"
                        [class.tw-rotate-180]="!attribute.isHidden" 
                        aria-hidden="true"
                      >
                        &#xe5cf;
                      </i>
                    </button>
                  </div>
                  <ul 
                    class="tw-transition-[height] tw-ease-in-out tw-mt-2"
                    [ngClass]="checklistModifierClass(attribute)"
                  >
                    @for (option of attribute.options(); track {option}; let i = $index) {
                    <li class="custom-radio-check mini">
                      <input type="checkbox"
                        [attr.auto-search-off]="false"
                        (change)="updateFormControl($event, searchFilterForm, attribute.name , attribute.key(option))"
                        [value]="attribute.key(option)"
                        [checked]="checkIfIsIncludes(searchFilterForm, attribute.name, attribute.key(option))"
                        [id]="option + attribute.key(option)" 
                      />
                      <label [for]="option + attribute.key(option)">
                        {{ option | translate }}
                      </label>
                    </li>
                    }
                  </ul>
                </fieldset>
                </section>
                }
              }
            </div>
          </div>
        </div>
      </form>
      <form 
        [formGroup]="basicFilterForm" 
        (change)="trySearch($event)"
      >
        <div 
          id="search-filter-container" 
          class="tw-flex tw-flex-col"
        >
          @for (attribute of searchBasicStructure(); track $index) {
            @if (isEligibleForFilter(attribute.displayForDaddyMommy)) {
            <section 
              [id]="attribute.name" 
              class="tw-px-8 tw-pb-4"
            >
            <fieldset class="tw-flex tw-flex-col">
              <div 
                class="tw-flex tw-w-full tw-justify-between tw-cursor-pointer tw-h-[45px]"
                (click)="handleOpenedBasicFilter(attribute)"
              >
                <p class="tw-text-[18px] tw-font-bold tw-text-gray-900 tw-max-w-[160px] tw-leading-[2rem] tw-self-center">
                  @if (!isMatchSexAttribute(attribute.name)) {
                    {{ attribute.name | translate }}
                  } @else {
                    {{ getMatchSexFilterLabel(attribute.name) }}
                  }
                </p>
                <button class="tw-text-[13px] tw-flex tw-items-center tw-gap-2 tw-max-w-[130px]">
                  @if (containsMoreThanOneValue(basicFilterForm, attribute.name)) {
                    {{ "modules.main.pages.search_filter.filter_counter"| translate: { value:
                  basicFilterForm.get(attribute.name).value.length } }}
                  } @else if (containsOnlyOneValue(basicFilterForm, attribute.name)) {
                    {{ getFormControlOption(basicFilterForm, attribute) | translate }}
                  } @else {
                    {{ "modules.main.pages.search_filter.filter_empty" | translate }}
                  }
                  <i 
                    class="material-icons !tw-text-[18px] tw-transform tw-transition-transform tw-duration-300 tw-self-center"
                    [class.tw-rotate-180]="!attribute.isHidden" 
                    aria-hidden="true"
                  >
                    &#xe5cf;
                  </i>
                </button>
              </div>
              <ul class="tw-transition-[height] tw-ease-in-out tw-mt-2" [ngClass]="checklistModifierClass(attribute)">
                @for (option of attribute.options(user.membership_type_id); track {option}; let i = $index) {
                <li class="custom-radio-check mini">
                  <input type="checkbox"
                    [attr.auto-search-off]="false"
                    (change)="updateFormControl($event, basicFilterForm, attribute.name , attribute.key(option, user.membership_type_id))"
                    [value]="attribute.key(option, user.membership_type_id)"
                    [checked]="checkIfIsIncludes(basicFilterForm, attribute.name, attribute.key(option, user.membership_type_id))"
                    [id]="option + attribute.key(option, user.membership_type_id)" />
                  <label [for]="option + attribute.key(option, user.membership_type_id)">
                    {{ option | translate }}
                  </label>
                </li>
                }
              </ul>
            </fieldset>
            </section>
            }
          }
        </div>
      </form>
    </div>
    <button
      class="tw-m-8 tw-mt-0 tw-py-3 tw-px-2 tw-font-bold tw-text-white tw-text-[18px] tw-bg-red-600 tw-shadow-sm tw-rounded"
      (click)="update($event)"
    >
      {{ "modules.main.pages.search.title" | translate }}
    </button>
  </div>
  <div class="tw-flex tw-gap-3 tw-justify-between tw-pb-6 ">
    <button
      class="tw-border-solid tw-border-2 tw-border-red-500 tw-h-fit tw-text-nowrap tw-rounded-md tw-p-3 tw-leading-[1] tw-text-red-500 tw-font-bold"
      (click)="saveSearch()"
    >
      {{ 'modules.main.pages.search_filter.save_search' | translate }}
    </button>
    <button 
      class="tw-text-[14px] tw-underline tw-text-nowrap" 
      (click)="resetFilter()"
    >
      {{ 'modules.main.pages.search_filter.reset_filter' | translate }}
    </button>
  </div>
</div>
