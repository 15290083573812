import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IGetRemainingPhotos } from '@libs/shared/profile/get-remaining-photos';

@Pipe({
  name: 'getRemainingNumberOfPhotos',
  pure: true,
  standalone: false,
})
export class GetRemainingNumberOfPhotos implements PipeTransform {
  constructor(public translate: TranslateService) {
    //
  }

  transform({ photosToDisplay, profile }: IGetRemainingPhotos): number {
    if (profile === undefined || (photosToDisplay !== undefined && !photosToDisplay.length)) {
      return 0;
    }

    return Math.max(0, profile.numberOfPhotos - photosToDisplay.length - 1);
  }
}
