import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { ISearch } from '@libs/shared/search/interface/search.interface';
import { AdvancedSearchActions } from '@libs/store/search/actions';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';
import { Store } from '@ngrx/store';

@Component({
  templateUrl: './save-search-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SaveSearchModalComponent {
  private formBuilder = inject(UntypedFormBuilder);
  private data = inject<{ filter: ISearch }>(MODAL_DATA);
  private modalRef = inject(ModalRef);
  private store = inject(Store);

  public form = this.formBuilder.group({
    ['name']: new UntypedFormControl('', { validators: Validators.required }),
  });

  public close() {
    this.modalRef.close();
  }

  public submit() {
    this.store.dispatch(
      AdvancedSearchActions.createOrUpdateSavedSearch({
        filter: this.data.filter,
        searchName: FormHelpers.getFormControlValue(this.form, 'name'),
      }),
    );
    this.close();
  }
}
