<div *ngIf="isEmptyList()">
  <p class="text-center">{{ emptyListText | translate }}</p>
</div>

<ng-container *ngIf="!isEmptyList()">
  <div [ngClass]="{'tw-pointer-events-none': isScrolling}">
    <div class="list-header" *ngIf="canShowHeaderTitle()">
      <div class="title">
        {{ listTitle | translate }}
      </div>
      <div class="subtitle">
        {{ listSubtitle | translate }}
      </div>
    </div>
    <cdk-virtual-scroll-viewport
      scrollWindow
      [itemSize]="240"
      id="home-user-list"
      class="user-list tw-p-0 tw-mt-2"
      #scroll
    >
      <div class="tw-grid tw-h-auto tw-gap-2.5"
           [ngClass]="{
              'tw-grid-cols-1': columnsPerRow === 1,
              'tw-grid-cols-2': columnsPerRow === 2,
              'tw-grid-cols-3': columnsPerRow === 3,
              'tw-grid-cols-4': columnsPerRow === 4,
              'tw-grid-cols-5': columnsPerRow === 5,
              'tw-grid-cols-6': columnsPerRow === 6,
              'tw-grid-cols-7': columnsPerRow === 7,
           }"
           *cdkVirtualFor="let chunk of groupedData; trackBy: trackGroupByFn">
        <mp-profile-preview-grid
          *ngFor="let item of chunk; trackBy: trackProfileIdByFn"
          class="user-list-person"
          [profileId]="item"
        ></mp-profile-preview-grid>
      </div>
      <div #listEndDelimiter></div>
    </cdk-virtual-scroll-viewport>
    <div class="loading-wrapper">
      <div class="loading-wrapper__fixed">
        <div
          class="loading-wrapper__loading-more"
          *ngIf="isDownloading()"
          [@loadingAnimated]
        >
          <mp-loading></mp-loading>
        </div>
      </div>
    </div>
  </div>
</ng-container>
