import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { AdvancedSearchCommon } from '@libs/shared/search/advanced-search.common';
import { ADVANCED_SEARCH_CONTROLS, LOCATION_OPTIONS } from '@libs/shared/search/enum';
import { INamedSearch } from '@libs/shared/search/interface/named-search.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { selectAllSearchProfiles } from '@libs/store/profiles-v2/selectors';
import { AdvancedSearchActions } from '@libs/store/search/actions';
import { AdvancedSearchSelectors } from '@libs/store/search/selectors';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';
import { Medias } from '@libs/utils/medias';
import { ScreenSizeHelpers } from '@libs/utils/screen-size-helpers/screen-size-helpers';
import { IAdvancedSearchFilterConfig } from '@meupatrocinio/modules/advanced-search/interfaces/advanced-search-filter-config.interface';
import { ISearchActivity } from '@meupatrocinio/modules/advanced-search/interfaces/search-activity.interface';
import { ISearchRangeConfig } from '@meupatrocinio/modules/advanced-search/interfaces/search-range-config';
import { AdvancedFilterStructureService } from '@meupatrocinio/modules/advanced-search/services/advanced-filter-structure.service';
import { AdvancedSearchService } from '@meupatrocinio/modules/advanced-search/services/advanced-search.service';
import { SearchFormManagerService } from '@meupatrocinio/modules/advanced-search/services/search-form-manager.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-advanced-filter',
  templateUrl: './advanced-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AdvancedFilterComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private authentication = inject(AuthenticationService);
  private translate = inject(TranslateService);
  private searchFormManagerService = inject(SearchFormManagerService);
  private advancedSearchService = inject(AdvancedSearchService);
  private downloadManagerService = inject(DownloadManagerService);
  private breakpointObserver = inject(BreakpointObserver);
  private changeDetector = inject(ChangeDetectorRef);
  private advancedFilterStructureService = inject(AdvancedFilterStructureService);

  private destroy$ = new Subject<void>();
  public countries$ = this.advancedSearchService.countries$;
  public regions$ = this.advancedSearchService.regions$;
  public cities$ = this.advancedSearchService.cities$;
  public searchFilter$ = this.advancedSearchService.searchFilter$;
  public distance = this.searchFormManagerService.distance();
  public searchStructure = this.advancedFilterStructureService.searchStructure;
  public searchBasicStructure = this.advancedFilterStructureService.searchBasicStructure;
  public searchRangeStructure = this.advancedFilterStructureService.searchRangeStructure;
  public searchActivityStructure = this.advancedFilterStructureService.searchActivityStructure;
  public distanceStructure = this.advancedFilterStructureService.distanceStructure;
  public isCustomLocationSelected$ = this.searchFormManagerService.isCustomLocationSelected$;

  ngOnInit(): void {
    this.advancedSearchService.canLoadMoreItems.next(false);
    this.advancedFilterStructureService.initializeStructures();
    this.prepareSearch();
    this.syncSearchListWithStore();
    this.detectFilterChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public checkIfIsIncludes(form: UntypedFormGroup, control: string, value: string | number) {
    return this.searchFormManagerService.checkIfOptionIsIncluded(form, control, value);
  }

  public formatLabelValue(name: string, value: number[]) {
    if (name === ADVANCED_SEARCH_CONTROLS.HEIGHT) {
      return AdvancedSearchCommon.showHeightLabel(value);
    }

    return AdvancedSearchCommon.showAgeLabel(value);
  }

  public get searchFilterForm() {
    return this.searchFormManagerService.searchFilterForm;
  }

  public get basicFilterForm() {
    return this.searchFormManagerService.basicFilterForm;
  }

  public get locationPreferenceForm() {
    return this.searchFormManagerService.searchLocationConfig;
  }

  public get isCustomLocationSelected() {
    return Number(FormHelpers.getFormControlValue(this.locationPreferenceForm, 'location')) === LOCATION_OPTIONS.CUSTOM;
  }

  public get user() {
    return this.authentication.get();
  }

  public get keywordPlaceholder() {
    return this.translate.instant('modules.main.pages.search_filter.keywords_placeholder');
  }

  public isDesktop$() {
    return this.breakpointObserver.observe(Medias.MIN_WIDTH_992).pipe(
      map((breakPointState) => {
        return breakPointState.matches;
      }),
    );
  }

  public isMatchSexAttribute(attribute: string) {
    return !attribute ? false : attribute.includes('match_sex');
  }

  protected isInterestedOnlyInWomen() {
    return UserCommon.isMaleStraight(this.user) || UserCommon.isFemaleGay(this.user);
  }

  public getMatchSexFilterLabel(attribute: string) {
    return this.translate.instant(attribute, {
      isInterestedOnlyInWomen: this.isInterestedOnlyInWomen(),
    });
  }

  public updateRegionOptions() {
    this.advancedSearchService.updateRegionOptions();
  }

  public updateCityOptions() {
    this.advancedSearchService.updateCityOptions();
  }

  public updateFormControl(event: Event, form: UntypedFormGroup, control: string, value: string) {
    this.searchFormManagerService.updateFormControl(event, form, control, value);
  }

  public trySearch(event?: Event) {
    if (ScreenSizeHelpers.isMobile()) {
      return;
    }

    this.update(event);
  }

  public update(event?: Event) {
    const element = event?.target as HTMLElement;
    const isAutoSearchOff = element.getAttribute('auto-search-off');

    if (isAutoSearchOff === 'true') {
      return;
    }

    this.store.dispatch(AdvancedSearchActions.setCurrentSearchName({ name: '' }));
    this.advancedSearchService.update();
  }

  public resetFilter() {
    this.advancedSearchService.resetFilter();
  }

  public resetLocations() {
    this.advancedSearchService.isInitializing.set(true);
    this.advancedSearchService.recoveryProfileLocation();
    this.isCustomLocationSelected$.set(false);
  }

  public restoreSearch(namedSearch: INamedSearch) {
    this.store.dispatch(AdvancedSearchActions.recoverSavedSearch({ searchFilter: namedSearch }));
  }

  public saveSearch() {
    this.advancedSearchService.saveSearch();
  }

  public checklistModifierClass(attribute: IAdvancedSearchFilterConfig | ISearchActivity) {
    return attribute.isHidden ? 'tw-h-0 tw-hidden' : 'tw-h-auto';
  }

  public handleOpendedOrderByFilter() {
    this.advancedFilterStructureService.updateSearchActivityHiddenAttribute();
  }

  public handleOpenedRangeFilter(attribute: ISearchRangeConfig) {
    this.advancedFilterStructureService.updateSearchRangeHiddenAttribute(attribute);
  }

  public handleOpenedFilter(attribute: IAdvancedSearchFilterConfig) {
    this.advancedFilterStructureService.updateSearchHiddenAttribute(attribute);
  }

  public handleOpenedBasicFilter(attribute: IAdvancedSearchFilterConfig) {
    this.advancedFilterStructureService.updateBasicSearchHiddenAttribute(attribute);
  }

  public containsMoreThanOneValue(form: UntypedFormGroup, control: string) {
    return form.get(control).value.length > 1;
  }

  public containsOnlyOneValue(form: UntypedFormGroup, control: string) {
    return form.get(control).value.length === 1;
  }

  public getFormControlOption(form: UntypedFormGroup, attribute: IAdvancedSearchFilterConfig) {
    const value = form.get(attribute.name).value[0];

    if (attribute.name !== ADVANCED_SEARCH_CONTROLS.PHOTOS) {
      return attribute.status(value);
    }

    return attribute.status(this.user.membership_type_id, value);
  }

  public isEligibleForFilter(displayForDaddyMommy: boolean) {
    return (
      MembershipCommon.isBaby(this.user.membership_type_id) ||
      (displayForDaddyMommy &&
        UserCommon.isMale(this.user) &&
        MembershipCommon.isDaddyMommyType(this.user.membership_type_id))
    );
  }

  private syncSearchListWithStore() {
    combineLatest([
      this.store.pipe(select(selectAllSearchProfiles)),
      this.store.pipe(select(AdvancedSearchSelectors.isInitialized)),
      this.store.pipe(select(AdvancedSearchSelectors.searchFilter)),
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.advancedSearchService.canLoadMoreItems.value),
      )
      .subscribe(([searchProfiles]) => {
        if (!searchProfiles || !searchProfiles.length) {
          this.downloadManagerService.resetPageCount('search');
          this.store.dispatch(AdvancedSearchActions.loadList());
        }
      });
  }

  private prepareSearch() {
    this.searchFormManagerService.buildSearchForms();
    this.advancedSearchService.isInitializing.set(true);
    this.advancedSearchService.isSearching.set(true);
    this.advancedSearchService.loadLocation();
    this.store.dispatch(AdvancedSearchActions.downloadSavedSearch());
    this.store.dispatch(AdvancedSearchActions.canRecoverSearch());
  }

  private detectFilterChanges() {
    this.advancedSearchService.filterChangeDetector$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.changeDetector.markForCheck();
    });
  }
}
