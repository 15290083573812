export class ScrollCommon {
  static readonly ONE_HUNDRED: number = 100;
  static readonly EXPECTED_SCROLL_PERCENTAGE: number = 83 / ScrollCommon.ONE_HUNDRED;
  static readonly MIN_PREVIOUS_SCROLL_POSITION: number = 70 / ScrollCommon.ONE_HUNDRED;

  static getScrollPercentage(targetDocument: Document): number {
    const documentClientHeight: number = targetDocument.documentElement.clientHeight;
    const documentScrollTop: number | undefined = targetDocument.documentElement.scrollTop;
    const documentScrollHeight: number = targetDocument.documentElement.scrollHeight;
    const bodyScrollTop: number = targetDocument.body.scrollTop;
    const bodyScrollHeight: number | undefined = targetDocument.body.scrollHeight;

    const floatingPercentageResult: number =
      this.handleScrollTop(documentScrollTop, bodyScrollTop) /
      (this.handleScrollHeight(documentScrollHeight, bodyScrollHeight) - documentClientHeight);

    return ScrollCommon.handlePercentage(floatingPercentageResult);
  }

  static isScrollMaxExpectedPosition(previousScrollPercentage: number, currentScrollPercentage: number): boolean {
    return (
      previousScrollPercentage !== 0 &&
      (currentScrollPercentage > ScrollCommon.EXPECTED_SCROLL_PERCENTAGE ||
        (previousScrollPercentage < ScrollCommon.MIN_PREVIOUS_SCROLL_POSITION &&
          currentScrollPercentage > ScrollCommon.EXPECTED_SCROLL_PERCENTAGE))
    );
  }

  static getScrollPosition(): number {
    return window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  static scrollToPosition(position: number): void {
    window.scrollTo(0, position);
  }

  protected static handlePercentage(percentage: number): number {
    return Math.round(percentage * ScrollCommon.ONE_HUNDRED) / ScrollCommon.ONE_HUNDRED;
  }

  protected static handleScrollTop(documentScrollTop: number | undefined, bodyScrollTop: number): number {
    return documentScrollTop ?? bodyScrollTop;
  }

  protected static handleScrollHeight(documentScrollHeight: number | undefined, bodyScrollHeight: number): number {
    return documentScrollHeight ?? bodyScrollHeight;
  }
}
