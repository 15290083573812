<div
    class="list-layout"
    *ngIf="isDesktop"
>
    <div class="list-layout-item">
        <input
            id="listView"
            type="radio"
            [value]="false"
            name="full-size"
            [(ngModel)]="listViewMode"
        >
        <div
            class="symbol symbol-list-layout"
            aria-hidden="true"
        ></div>
        <label for="listView">
            {{ 'modules.main.pages.search_filter.list' | translate }}
        </label>
    </div>
    <div class="list-layout-item">
        <input
            id="gridView"
            type="radio"
            [value]="true"
            name="full-size"
            [(ngModel)]="listViewMode"
        >
        <div
            class="symbol symbol-grid-layout"
            aria-hidden="true"
        ></div>
        <label for="gridView">
            {{ 'modules.main.pages.search_filter.grid' | translate }}
        </label>
    </div>
</div>
@if ((profilesId$ | async).length > 0 || isDownloading) {
    <mp-list-view
        [listName]="'search'"
        *ngIf="isListView()"
        [items]="columnItems"
    (loadMoreItems)="onLoadMoreItemsRequestCallback()"
    ></mp-list-view>
    <mp-grid-view
        [listName]="'search'"
        class="user-list"
        *ngIf="isGridView()"
        [items]="columnItems"
        (loadMoreItems)="onLoadMoreItemsRequestCallback()"
    ></mp-grid-view>
} @else {
    <p class="text-center">
      {{ 'modules.main.pages.search_filter.empty' | translate }}
    </p>
}
