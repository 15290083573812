import { Injectable, inject, signal } from '@angular/core';
import { FormArray, FormBuilder, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AdvancedSearchCommon } from '@libs/shared/search/advanced-search.common';
import { ADVANCED_SEARCH_CONTROLS, LOCATION_OPTIONS, SORT_ACTIVITY } from '@libs/shared/search/enum';
import { ISearch } from '@libs/shared/search/interface/search.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class SearchFormManagerService {
  private authenticationService = inject(AuthenticationService);

  public searchFilterForm = new UntypedFormGroup({});
  public basicFilterForm = new UntypedFormGroup({});
  public searchLocationConfig = new UntypedFormGroup({});

  public isCustomLocationSelected$ = signal<boolean>(false);
  public filter = signal<ISearch>({});
  public distance = signal(AdvancedSearchCommon.DISTANCE_STANDARD);
  public age$ = signal([AdvancedSearchCommon.MIN_AGE, AdvancedSearchCommon.MAX_AGE]);
  public height$ = signal([AdvancedSearchCommon.MIN_HEIGHT, AdvancedSearchCommon.MAX_HEIGHT]);
  public isFormInitialized = signal<boolean>(false);
  private formBuilder = inject(FormBuilder);

  public buildSearchForms() {
    this.buildSearchFilterForm();
    this.buildSearchBasicFilterForm();
    this.buildSearchLocationForm();
    this.isFormInitialized.set(true);
  }

  public updateFormControl(event: Event, form: UntypedFormGroup, control: string, value: string) {
    const formArray = form.get(control) as FormArray;
    const checkbox = event.target as HTMLInputElement;

    if (!formArray) {
      return;
    }

    if (checkbox.checked) {
      formArray.push(this.formBuilder.control(value));
    } else {
      const index = formArray.controls.findIndex((ctrl) => ctrl.value === value);

      if (index !== -1) {
        formArray.removeAt(index);
      }
    }
  }

  public updateFormWithSearchValues(search: ISearch) {
    const searchRef = structuredClone(search);

    const setControlValue = (controlName: string, value: number | string | number[]) => {
      FormHelpers.setFormControlValue(this.searchFilterForm, controlName, value);
    };

    setControlValue(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID, searchRef.location_city_id);
    setControlValue(ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID, searchRef.location_state_id);
    setControlValue(ADVANCED_SEARCH_CONTROLS.LOCATION_COUNTRY_ID, searchRef.location_country_id);
    setControlValue(ADVANCED_SEARCH_CONTROLS.LOCATION_RADIUS_FIRST, searchRef.location_radius_first);
    setControlValue(ADVANCED_SEARCH_CONTROLS.AGE, searchRef.age_between);
    setControlValue(ADVANCED_SEARCH_CONTROLS.HEIGHT, searchRef.height);
    setControlValue(ADVANCED_SEARCH_CONTROLS.SORTKEY, searchRef.sortkey);
    setControlValue(ADVANCED_SEARCH_CONTROLS.KEYWORD_SEARCH, searchRef.keyword_search);

    this.applySearchValuesToForm(this.searchFilterForm, searchRef);
  }

  public updateBasicFormWithSearchValues(search: ISearch) {
    const user = this.authenticationService.get();

    if (!user) {
      throw new Error('User not found');
    }

    const searchRef = structuredClone(search);

    const ensureFormArrayHasControls = (controlName: string, values: any[]) => {
      const formArray = this.basicFilterForm.get(controlName) as FormArray;
      if (formArray) {
        values.forEach((value) => {
          if (!formArray.controls.some((ctrl) => ctrl.value === value)) {
            formArray.push(this.formBuilder.control(value));
          }
        });
      }
    };

    const filterConditions = [
      {
        condition: AdvancedSearchCommon.isFavoritedIncluded(searchRef),
        control: ADVANCED_SEARCH_CONTROLS.FAVORITE,
        value: [AdvancedSearchCommon.FILTER_FAVORITED_BY_ME],
      },
      {
        condition: AdvancedSearchCommon.isFavoritedMeIncluded(searchRef),
        control: ADVANCED_SEARCH_CONTROLS.FAVORITE,
        value: [AdvancedSearchCommon.FILTER_FAVORITED_ME],
      },
      {
        condition: AdvancedSearchCommon.isHasPhotosIncluded(searchRef, user.membership_type_id),
        control: ADVANCED_SEARCH_CONTROLS.PHOTOS,
        value: [AdvancedSearchCommon.FILTER_HAS_PHOTOS],
      },
      {
        condition: AdvancedSearchCommon.isVerifiedPhotosIncluded(searchRef, user.membership_type_id),
        control: ADVANCED_SEARCH_CONTROLS.PHOTOS,
        value: [AdvancedSearchCommon.FILTER_VERIFIED_PHOTOS],
      },
      {
        condition: AdvancedSearchCommon.isFeaturedIncluded(searchRef),
        control: ADVANCED_SEARCH_CONTROLS.PHOTOS,
        value: [AdvancedSearchCommon.FILTER_FEATURED],
      },
      {
        condition: AdvancedSearchCommon.isUnviewedIncluded(searchRef),
        control: ADVANCED_SEARCH_CONTROLS.VIEWED,
        value: [AdvancedSearchCommon.FILTER_NOT_VIEWED],
      },
      {
        condition: AdvancedSearchCommon.isViewedIncluded(searchRef),
        control: ADVANCED_SEARCH_CONTROLS.VIEWED,
        value: [AdvancedSearchCommon.FILTER_VIEWED_BY_ME],
      },
      {
        condition: AdvancedSearchCommon.isViewedMeIncluded(searchRef),
        control: ADVANCED_SEARCH_CONTROLS.VIEWED,
        value: [AdvancedSearchCommon.FILTER_VIEWED_ME],
      },
    ];

    filterConditions
      .filter((item) => item.condition)
      .forEach((item) => ensureFormArrayHasControls(item.control, item.value));
  }

  protected applySearchValuesToForm(form: UntypedFormGroup, search: ISearch) {
    Object.keys(form.controls).map((controlName) => {
      const control = form.get(controlName);

      if (control instanceof FormArray) {
        if (search[controlName] && search[controlName].length > 0) {
          (form.get(controlName) as FormArray).clear();

          (search[controlName] || []).forEach((value: string | number) => {
            control.push(new UntypedFormControl(value));
          });
        }
      }
    });
  }

  public checkIfOptionIsIncluded(form: UntypedFormGroup, control: string, value: number | string) {
    const controlValue = (form.get(control) as UntypedFormArray).value as unknown[];

    return controlValue.includes(value);
  }

  protected buildSearchFilterForm() {
    this.searchFilterForm = this.formBuilder.group({
      [ADVANCED_SEARCH_CONTROLS.LOCATION_COUNTRY_ID]: [''],
      [ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID]: [''],
      [ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID]: [''],
      [ADVANCED_SEARCH_CONTROLS.LOCATION_RADIUS_FIRST]: AdvancedSearchCommon.DISTANCE_STANDARD,
      [ADVANCED_SEARCH_CONTROLS.AGE]: [[AdvancedSearchCommon.MIN_AGE, AdvancedSearchCommon.MAX_AGE]],
      [ADVANCED_SEARCH_CONTROLS.HEIGHT]: [[AdvancedSearchCommon.MIN_HEIGHT, AdvancedSearchCommon.MAX_HEIGHT]],
      [ADVANCED_SEARCH_CONTROLS.SORTKEY]: [SORT_ACTIVITY.RECENTLY],
      [ADVANCED_SEARCH_CONTROLS.BODY_TYPE]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.MATCH_SEX]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.DRINK]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.RELATIONSHIP]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.ETHNICITY]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.EYE_COLOR]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.HAIR_COLOR]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.OCCUPATION]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.HAVE_CHILDREN]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.EDUCATION]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.INCOME]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.SMOKE]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.SEX]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.NET_WORTH]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.HAPPY_TO_TRAVEL]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.KEYWORD_SEARCH]: [''],
    });
  }

  protected buildSearchBasicFilterForm() {
    this.basicFilterForm = this.formBuilder.group({
      [ADVANCED_SEARCH_CONTROLS.FAVORITE]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.VIEWED]: this.formBuilder.array([]),
      [ADVANCED_SEARCH_CONTROLS.PHOTOS]: this.formBuilder.array([]),
    });
  }

  protected buildSearchLocationForm() {
    this.searchLocationConfig = this.formBuilder.group({
      ['location']: [!this.isCustomLocationSelected$() ? LOCATION_OPTIONS.PROFILE : LOCATION_OPTIONS.CUSTOM],
    });
  }

  public clearFormWithSearchValues(user: UserCommon) {
    Object.keys(this.searchFilterForm.controls).map((controlName) => {
      const control = this.searchFilterForm.get(controlName);

      if (control instanceof FormArray) {
        (this.searchFilterForm.get(controlName) as FormArray).clear();
      } else {
        if (controlName === ADVANCED_SEARCH_CONTROLS.AGE) {
          control.setValue([AdvancedSearchCommon.MIN_AGE, AdvancedSearchCommon.MAX_AGE]);
        } else if (controlName === ADVANCED_SEARCH_CONTROLS.HEIGHT) {
          control.setValue([AdvancedSearchCommon.MIN_HEIGHT, AdvancedSearchCommon.MAX_HEIGHT]);
        } else if (controlName === ADVANCED_SEARCH_CONTROLS.LOCATION_RADIUS_FIRST) {
          control.setValue(AdvancedSearchCommon.DISTANCE_STANDARD);
        } else if (controlName === ADVANCED_SEARCH_CONTROLS.SORTKEY) {
          control.setValue(SORT_ACTIVITY.RECENTLY);
        } else {
          control.setValue('');
        }
      }
    });

    Object.keys(this.basicFilterForm.controls).forEach((controlName) => {
      const control = this.basicFilterForm.get(controlName);

      if (control instanceof FormArray) {
        (this.basicFilterForm.get(controlName) as FormArray).clear();
      }
    });

    this.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_COUNTRY_ID).setValue(user.country_id);
    this.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID).setValue(user.state_id);
    this.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID).setValue(user.city_id);
  }
}
