import { createAction, props } from '@ngrx/store';

enum Actions {
  CHANGE_PAGE_TITLE = '[Page Title] - Change',
  PAGE_TITLE_CHANGED = '[Page Title] - Changed',
  SET_DEFAULT_PAGE_TITLE = '[Page Title] - Set Default Page Title',
}

export const changePageTitle = createAction(Actions.CHANGE_PAGE_TITLE, props<{ title: string }>());

export const pageTitleChanged = createAction(Actions.PAGE_TITLE_CHANGED);

export const setDefaultPageTitle = createAction(Actions.SET_DEFAULT_PAGE_TITLE);
