import { WritableSignal } from '@angular/core';
import { MembershipCommon, MembershipType } from '@libs/shared/membership/membership.common';
import { ISearch } from '@libs/shared/search/interface/search.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { ISearchFilterList } from '@meupatrocinio/modules/advanced-search/interfaces/search-filter-list.interface';
import { TranslateService } from '@ngx-translate/core';

export abstract class AdvancedSearchCommon {
  public static readonly STEP_HEIGHT = 1;
  public static readonly MIN_HEIGHT = 150;
  public static readonly MAX_HEIGHT = 240;
  public static readonly STEP_AGE = 1;
  public static readonly MIN_AGE = 18;
  public static readonly MAX_AGE = 65;
  public static readonly STEP_DISTANCE = 1;
  public static readonly MIN_DISTANCE = 0;
  public static readonly MAX_DISTANCE = 500;
  public static readonly DISTANCE_STANDARD = 50;
  public static readonly STANDARD_HEIGHT_RANGE = [this.MIN_HEIGHT, this.MAX_HEIGHT];
  public static readonly STANDARD_AGE_RANGE = [this.MIN_AGE, this.MAX_AGE];
  public static readonly STANDARD_COUNTRY_ID = 'BR';

  public static readonly LOCATION_PROFILE = 0;
  public static readonly LOCATION_GPS = 2;
  public static readonly LOCATION_CUSTOM = 1;

  public static readonly FILTER_FAVORITED_BY_ME = 0;
  public static readonly FILTER_FAVORITED_ME = 1;
  public static readonly FILTER_HAS_PHOTOS = 0;
  public static readonly FILTER_VERIFIED_PHOTOS = 0;
  public static readonly FILTER_VIEWED_BY_ME = 0;
  public static readonly FILTER_VIEWED_ME = 2;
  public static readonly FILTER_NOT_VIEWED = 1;
  public static readonly FILTER_FEATURED = 1;

  public static showHeightLabel(height: number[]): { min_height?: number; max_height?: number } {
    return height?.length === 2 ? { min_height: height[0], max_height: height[1] } : {};
  }

  public static showAgeLabel(age: number[]): { min_age?: string; max_age?: string } {
    if (age?.length !== 2) {
      return {};
    }

    const maxAgePlus = age[1] >= 65 ? '+' : '';

    return {
      min_age: age[0].toString(),
      max_age: `${age[1]}${maxAgePlus}`,
    };
  }

  public static getValidValue(value: number[], defaultValue: number[]) {
    return value.length !== 0 ? value : defaultValue;
  }

  public static updateFilterList(filterList: WritableSignal<ISearchFilterList[]>, configuration: ISearchFilterList) {
    filterList.update((list) => [...list, configuration]);
  }

  public static getMatchSexFilterLabel(translateService: TranslateService, user: UserCommon) {
    return translateService.instant('search-filter.badge.match_sex', {
      isInterestedOnlyInWomen: UserCommon.isMaleStraight(user) || UserCommon.isFemaleGay(user),
    });
  }

  public static isMatchSexAttribute(attribute: string) {
    return attribute === 'match_sex';
  }

  public static getAttributeLabelTitle(attribute: string, translate: TranslateService, user: UserCommon) {
    if (AdvancedSearchCommon.isMatchSexAttribute(attribute)) {
      return AdvancedSearchCommon.getMatchSexFilterLabel(translate, user);
    }

    return translate.instant(`search-filter.badge.${attribute}`);
  }

  public static getFlexStyleModifierClass(isDesktop: boolean) {
    return isDesktop ? 'tw-flex' : 'tw-flex tw-flex-col';
  }

  public static isFavoritedIncluded(search: ISearch) {
    return search.basic_filter.favorite;
  }

  public static isFavoritedMeIncluded(search: ISearch) {
    return search.basic_filter.favorited_me;
  }

  public static isHasPhotosIncluded(search: ISearch, membership_type_id: MembershipType) {
    return search.basic_filter.has_photos && MembershipCommon.isBaby(membership_type_id);
  }

  public static isVerifiedPhotosIncluded(search: ISearch, membership_type_id: MembershipType) {
    return search.basic_filter.verified_photos && MembershipCommon.isDaddyMommyType(membership_type_id);
  }

  public static isFeaturedIncluded(search: ISearch) {
    return search.basic_filter.featured;
  }

  public static isUnviewedIncluded(search: ISearch) {
    return search.basic_filter.unviewed;
  }

  public static isViewedIncluded(search: ISearch) {
    return search.basic_filter.viewed;
  }

  public static isViewedMeIncluded(search: ISearch) {
    return search.basic_filter.viewed_me;
  }
}
