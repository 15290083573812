import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { IEncryptionService } from '@libs/modules/payment-v2/interfaces/encryption-service.interface';
import { IPaymentAttemptData } from '@libs/modules/payment-v2/interfaces/payment-data-attempt.interface';

import { ICreditCardDataPreparedForEncryption } from '@libs/modules/payment-v2/interfaces/encrypt-data-for-allcash.interface';
import { Config } from '@meupatrocinio/config';

@Injectable({
  providedIn: 'root',
})
export class CreditCardEncryptionService implements IEncryptionService {
  public encrypt(data: IPaymentAttemptData) {
    const publicKey = Config.payment.publicKey;
    const preparedData = this.prepareCreditCardInfo(data);

    return from(this.encryptData(preparedData, publicKey));
  }

  protected prepareCreditCardInfo({ cardInfo, holderInfo, purchaseInfo, cardBrand }: IPaymentAttemptData) {
    return {
      amount: purchaseInfo.subtotalAmount.toFixed(2),
      paymentBrand: cardBrand,
      currency: 'BRL',
      card: {
        number: cardInfo.cardNumber,
        holder: holderInfo.name,
        expiryMonth: this.formatExpiryMonth(cardInfo.date),
        expiryYear: this.formatExpiryYear(cardInfo.date),
        cvv: cardInfo.cvcNumber,
      },
    } as ICreditCardDataPreparedForEncryption;
  }

  protected formatExpiryMonth(date: string): string {
    return date.substring(0, 2);
  }

  protected formatExpiryYear(date: string): string {
    return `20${date.substring(3, 5)}`;
  }

  protected async encryptData(data: ICreditCardDataPreparedForEncryption, publicKey: string): Promise<string> {
    try {
      const cryptographicKey = await this.importRsaKey(publicKey);
      const encoded = this.encodeData(data);
      const arrayBuffer = await window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, cryptographicKey, encoded);

      return this.convertFromArrayBufferToBase64(arrayBuffer);
    } catch (_error) {
      throw new Error('Failed to encrypt data');
    }
  }

  protected convertFromArrayBufferToBase64(buffer: ArrayBuffer): string {
    const byteSequence = new Uint8Array(buffer);
    const characters: string[] = Array(byteSequence.length);

    for (const [byteIndex, element] of byteSequence.entries()) {
      characters[byteIndex] = String.fromCharCode(element);
    }

    const binaryDataString = characters.join('');

    return window.btoa(binaryDataString);
  }

  protected convertFromBinaryToArrayBuffer(binaryString: string): ArrayBuffer {
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const byteView = new Uint8Array(arrayBuffer);

    for (let byteIndex = 0; byteIndex < binaryString.length; byteIndex++) {
      byteView[byteIndex] = binaryString.charCodeAt(byteIndex);
    }

    return arrayBuffer;
  }

  protected encodeData(data: ICreditCardDataPreparedForEncryption): Uint8Array {
    const textEncoder = new TextEncoder();

    return textEncoder.encode(JSON.stringify(data));
  }

  protected async importRsaKey(publicKey: string): Promise<CryptoKey> {
    const pemHeader = '-----BEGIN PUBLIC KEY-----';
    const pemFooter = '-----END PUBLIC KEY-----';
    const keyFormat = 'spki';
    const algorithmConfig: RsaHashedImportParams = {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    };
    const pemContents = publicKey.substring(pemHeader.length, publicKey.length - pemFooter.length);
    const contentsBinary = window.atob(pemContents);
    const contentsBuffer = this.convertFromBinaryToArrayBuffer(contentsBinary);

    return window.crypto.subtle.importKey(keyFormat, contentsBuffer, algorithmConfig, true, ['encrypt']);
  }
}
