import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ListName } from '@libs/modules/main/services/download-manager.service.common';
import { BoostCommon } from '@libs/shared/boost/boost.common';
import { ScrollCommon } from '@libs/shared/scroll/scroll.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { arrayBuild } from '@libs/utils/array-functions';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';

@Directive()
export abstract class GenericViewComponentCommon implements OnChanges, OnDestroy {
  public lastEndIndex = 0;
  public columnItems: number[] = [];
  public groupedData: number[][] = [];
  public refreshing = false;
  public lastUserListOffsetHeight = 0;
  public isUniqueItemPerLine = true;
  public previousScrollPercentage = 0;
  public user: UserCommon | undefined;

  protected subscriptions: Subscription[] = [];

  @Input() listName: ListName;
  @Input() items: number[] = [];
  @Input() isDownloadingCallback: () => boolean;
  @Input() emptyListText = '';
  @Input() showHeaderTitle = false;
  @Input() showHeader = false;
  @Input() maxItemsPerRow = 7;
  @Input() listTitle = '';
  @Input() listSubtitle = '';
  @Input() isAtLastPage: () => boolean = () => false;

  @Output() readonly refreshList: EventEmitter<void> = new EventEmitter();
  @Output() readonly loadMoreItems: EventEmitter<void> = new EventEmitter();

  protected readonly skeleton: number[] = arrayBuild(30);

  constructor(
    protected downloadManager: DownloadManagerService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected store: Store<IApplicationState>,
    @Inject(DOCUMENT) protected document: Document,
  ) {
    this.subscriptions.push(
      this.store.select('user').subscribe((user: UserCommon): void => {
        this.user = user;
        this.changeDetectorRef.markForCheck();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleComponentChanges(changes);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  public onLoadMoreItemsRequest(): void {
    const currentScrollPercentage: number = ScrollCommon.getScrollPercentage(this.document);

    if (!ScrollCommon.isScrollMaxExpectedPosition(this.previousScrollPercentage, currentScrollPercentage)) {
      return;
    }

    this.loadMoreItems.emit();
  }

  public updatePreviousScrollPercentage(currentScrollPercentage: number): void {
    this.previousScrollPercentage = currentScrollPercentage;
    this.changeDetectorRef.markForCheck();
  }

  @HostListener('window:scroll') onScroll(): void {
    const currentScrollPercentage: number = ScrollCommon.getScrollPercentage(this.document);

    this.onLoadMoreItemsRequest();
    this.updatePreviousScrollPercentage(currentScrollPercentage);
  }

  public isDownloading(): boolean {
    return this.downloadManager.isDownloading(this.listName);
  }

  public isEmptyList(): boolean {
    return this.items.length === 0 && !this.isDownloading() && !this.refreshing;
  }

  public abstract getCountPerRow(width?: number): number;

  public abstract handleComponentChanges(changes: SimpleChanges): void;

  public abstract groupData(columnItems: number[], innerWidth?: number): number[][];

  public canShowHeaderTitle(): boolean {
    return this.user !== undefined && this.showHeaderTitle && BoostCommon.isAvailable(this.user);
  }

  public getValidRowSize(items: number): number {
    if (items <= this.maxItemsPerRow) {
      return items;
    }

    return this.maxItemsPerRow;
  }

  public trackGroupByFn(_: number, item: number[]): number {
    return item[0];
  }

  public trackProfileIdByFn(_: number, item: number): number {
    return item;
  }
}
