export class TimeHelpers {
  public static readonly SEVEN_DAYS_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;

  public static hasPassedNMilliseconds(milliseconds: number, timestamp: number): boolean {
    const now = Date.now();

    const timeDifference = now - timestamp;

    return timeDifference >= milliseconds;
  }

  public static isPastDate(timestamp) {
    return this.daysUntil(timestamp) < 0;
  }

  public static daysUntil(dateTimestamp) {
    if (typeof dateTimestamp !== 'number') {
      return 0;
    }

    const dateDifference = this.getDateDifferenceInDays(new Date(), new Date(dateTimestamp * 1000));

    return dateDifference;
  }

  public static getDateDifferenceInDays(latestDate: Date, earliestDate: Date) {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const latest = Date.UTC(latestDate.getFullYear(), latestDate.getMonth(), latestDate.getDate());
    const earliest = Date.UTC(earliestDate.getFullYear(), earliestDate.getMonth(), earliestDate.getDate());
    const dateDifferenceInDays = Math.floor((earliest - latest) / millisecondsPerDay);

    return dateDifferenceInDays;
  }

  public static getDelayBetween(min: number, max: number) {
    if (min >= max) {
      throw new Error('Error on getDelayBetween function: minimum value must be smaller than maximum value');
    }

    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
