<mp-actionbar>
  <mp-actionbar-back-title 
    [title]="'modules.main.pages.search_filter.title' | translate"
    [definedBackAction]="back.bind(this, displayingResult$ | async)"/>
  <div>
    @if (!(displayingResult$ | async)) {
      <mp-actionbar-call-to-action 
        [action]="applyFilters.bind(this)"
        [label]="'modules.main.pages.search.title' | translate"
      />
    } @else {
      <mp-actionbar-call-to-action 
        [outline]="true"
        [action]="back.bind(this, displayingResult$ | async)"
        [label]="'modules.main.pages.search_filter.edit_filters' | translate"
      />
    }
  </div>
</mp-actionbar>
<div class="container tw-gap-2 tw-my-7 tw-mt-0 tw-p-[15px] md:tw-p-0 tw-flex max-md:tw-flex-col">
  <div 
    class="tw-block tw-relative tw-w-full md:tw-max-w-[370px]" 
    [ngClass]="{ 'tw-hidden': !canShowAdvancedSearchForm(displayingResult$ | async, isDesktop$() | async) }"
  >
    <div class="md:tw-pr-5 md:tw-fixed md:tw-overflow-auto md:tw-h-[calc(100%-70px)] md:tw-w-full md:tw-max-w-[370px] tw-overscroll-contain">
      <mp-advanced-filter />
    </div>
  </div>
  <div 
    class="tw-w-full tw-mt-8" 
    [ngClass]="{ 'tw-hidden': !canShowSearchResults(displayingResult$ | async, isDesktop$() | async) }"
  >
    <mp-search-chips></mp-search-chips>
      <mp-search-list 
        class="tw-w-[100%] " 
        (loadMoreItems)="loadList()" 
        [items]="profilesId$ | async"
        [isDesktop]="isDesktop$() | async"
      />
  </div>
</div>
