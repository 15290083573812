import { ValidationErrors, Validators } from '@angular/forms';

import { IValidations } from '@libs/services/payment/validators';

const validation: ValidationErrors[] = [Validators.required, Validators.pattern(/\w+/), Validators.maxLength(26)];

function messages(errors: ValidationErrors): string {
  if (errors === null) {
    return 'modules.main.pages.payment.cc_holder.error.invalid';
  }

  if (errors.required) {
    return 'modules.main.pages.payment.cc_holder.error.missing';
  }

  if (errors.maxlength) {
    return 'modules.main.pages.payment.cc_holder.error.max_length';
  }

  return 'modules.main.pages.payment.cc_holder.error.invalid';
}

export const validations: IValidations = {
  validation,
  messages,
};
