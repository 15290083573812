import { Injectable, inject } from '@angular/core';
import { ProviderV2Actions } from '@libs/modules/payment-v2/actions';
import { IPixResponse } from '@libs/modules/payment-v2/interfaces/pix-response.interface';
import { ITokenizeCreditCardResponse } from '@libs/modules/payment-v2/interfaces/tokenize-credit-card-response.interface';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { PaymentInfoActions, PaymentSelectors } from '@libs/store/payment-info';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';
import { TimeHelpers } from '@libs/utils/time-helpers/time-helpers';
import { PaymentFlowHandlersService } from '@meupatrocinio/modules/payment-v2/payment-flow-handlers/payment-flow-handlers.service';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions/actions';
import { Safe2PayActions } from '@meupatrocinio/modules/payment-v2/providers/safe-2-pay/actions/actions';
import { Safe2PayService } from '@meupatrocinio/modules/payment-v2/providers/safe-2-pay/services/safe-2-pay.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class Safe2PayEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private paymentFlowHandlersService = inject(PaymentFlowHandlersService);
  private safe2PayService = inject(Safe2PayService);

  handleCreditCardPayment$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(Safe2PayActions.handleCreditCardPayment),
      switchMap(({ paymentData, productDescription }) => {
        const { purchaseInfo, holderInfo, cardInfo } = paymentData;

        this.paymentFlowHandlersService.handlePaymentAttemptInitialActions({
          paymentProvider: 'safe2pay',
          value: purchaseInfo.subtotalAmount,
          product: purchaseInfo.product.product_uuid,
          productDescription,
        });
        this.store.dispatch(
          ProviderSwitchActions.tokenizeForAllCash({
            paymentData,
          }),
        );

        return this.safe2PayService.encryptCard(paymentData).pipe(
          concatMap((encryptedCard: string) => {
            if (!encryptedCard || !encryptedCard.length) {
              throw new Error('Failed to encrypt card.');
            }

            return this.safe2PayService.tokenizeCreditCard(encryptedCard).pipe(
              exponentialBackoff(),
              map((response: IAuthResponse<ITokenizeCreditCardResponse>) => {
                return Safe2PayActions.createCreditCardPayment({
                  externalIdentifier: response.data.externalIdentifier,
                  purchaseInfo,
                  holderInfo,
                  cardInfo,
                });
              }),
              catchError(() => {
                return this.paymentFlowHandlersService.handleTokenizationError();
              }),
            );
          }),
        );
      }),
    );
  });

  createCreditCardPayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Safe2PayActions.createCreditCardPayment),
      withLatestFrom(this.store.select(PaymentSelectors.selectCurrentPayment)),
      switchMap(([paymentData, currentPayment]) => {
        const DELAY_TO_CHECK_PAYMENT_STATUS = 4000 as const;
        const { externalIdentifier, purchaseInfo, holderInfo, cardInfo } = paymentData;

        return this.safe2PayService
          .createCreditCardPayment({
            encryptedCard: externalIdentifier,
            purchaseInfo,
            holderInfo,
            cardInfo,
            idempotencyKey: currentPayment.idempotencyKey,
          })
          .pipe(
            exponentialBackoff(),
            concatMap(() => {
              return of(ProviderV2Actions.checkPaymentStatus()).pipe(delay(DELAY_TO_CHECK_PAYMENT_STATUS));
            }),
            catchError(() => {
              return this.paymentFlowHandlersService.handleCreatePaymentError();
            }),
          );
      }),
    );
  });

  createPixPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Safe2PayActions.createPixPayment),
        tap({
          next: () => {
            this.paymentFlowHandlersService.setIsPaying(true);
          },
        }),
        concatMap(({ paymentData }) => {
          return this.safe2PayService.createPixPayment({ paymentData }).pipe(
            exponentialBackoff(),
            map((response: IAuthResponse<IPixResponse>) => {
              const qrCode = response.data.base64Image;
              const copyCode = response.data.qrCode;
              const { product } = paymentData;

              this.store.dispatch(
                PaymentInfoActions.onQrCodeGeneration({
                  productUuid: product.product_uuid,
                }),
              );

              return PaymentInfoActions.qrCodeGeneratedSuccessfully({ qrCode, copyCode });
            }),
            catchError(() => {
              this.paymentFlowHandlersService.handleQRCodeError();

              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  tokenizeOnPaymentsWithAnotherProvider$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProviderSwitchActions.tokenizeForSafe2Pay),
        delay(TimeHelpers.getDelayBetween(1000, 2000)),
        concatMap(({ paymentData }) => {
          return this.safe2PayService.encryptCard(paymentData).pipe(
            concatMap((encryptedCard) => {
              if (!encryptedCard || !encryptedCard.length) {
                throw new Error('Failed to encrypt card.');
              }

              return this.safe2PayService.tokenizeCreditCard(encryptedCard).pipe(
                exponentialBackoff(),
                concatMap(() => {
                  return EMPTY;
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );
}
