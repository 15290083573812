import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import { ProfilePreviewComponentCommon } from '@libs/components/profile-preview/profile-preview.component.common';
import { ConversationCommon } from '@libs/shared/conversation/conversation.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ProfileContextActions } from '@libs/store/profile-context';
import { Medias } from '@libs/utils/medias';

import { NewModalComponent } from '@meupatrocinio/modules/main/shared/new-modal/new-modal.component';
import { TenMessageModalComponent } from '@meupatrocinio/modules/main/shared/ten-message-modal/ten-message-modal.component';
import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { ImageHelper } from '@meupatrocinio/utils/image-helper';

@Component({
  selector: 'mp-profile-preview-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './profile-preview-list.html',
  standalone: false,
})
export class ProfilePreviewListComponent extends ProfilePreviewComponentCommon {
  public attributes: IProfileAttributes[] = [];
  public readonly MAX_PHOTOS: number = 4;

  public isDesktop: boolean;

  constructor(
    public translate: TranslateService,
    protected auth: AuthenticationService,
    protected profileService: ProfileService,
    protected store: Store<IApplicationState>,
    protected router: Router,
    protected downloadManager: DownloadManagerService,
    protected modal: AdvancedModalService,
    protected breakpointObserver: BreakpointObserver,
  ) {
    super(translate, auth, profileService, store, router, downloadManager);

    this.subscriptions.push(
      this.breakpointObserver.observe(Medias.MIN_WIDTH_1200).subscribe((breakPointState: BreakpointState): void => {
        this.isDesktop = breakPointState.matches;
      }),
    );
  }
  public handleUrl() {
    this.store.dispatch(
      ProfileContextActions.profileContextReceived({
        profileContext: {
          originName: this.router.url.split('?')[0].split('/').pop(),
          previousUrl: this.router.url,
          promotionName: this.profile.promotionName ?? '',
          score: this.profile.score ?? '',
          recommendationId: this.profile.recommendationId ?? '',
        },
      }),
    );
  }

  forceUpdate(profile: IProfile): void {
    this.profile = profile;

    this.setAttributes();
  }

  setAttributes(): void {
    this.attributes = [
      {
        isVerified: (): boolean => this.isMelt,
        imagePath: '/assets/img/melt-badge-full.svg',
        imageAlt: '',
        className: 'melt-badge',
      },
      {
        isVerified: (): boolean => this.isElite(),
        imagePath: '/assets/img/daddy-crown.svg',
        imageAlt: 'modules.main.pages.profile.member_elite',
        className: 'elite-badge',
      },
      {
        isVerified: (): boolean => this.isPremium(),
        imagePath: '/assets/img/sbp-diamond-icon.svg',
        imageAlt: 'modules.main.pages.profile.member_sbp',
        className: 'sbp-badge',
      },
      {
        isVerified: (): boolean => this.isVerified(),
        imagePath: '/assets/img/verified-green.svg',
        imageAlt: 'modules.main.pages.profile.member_verified',
        className: 'badge-verified',
      },
      {
        isVerified: (): boolean => this.isNew(),
        imagePath: '/assets/img/new-blue.svg',
        imageAlt: 'modules.main.pages.profile.member_new',
        interpolateParams: { gender: this.getGender() },
        className: 'badge-new',
      },
    ];
  }

  getProfilePicture(): string {
    return ImageHelper.getProfilePicture(this.profile, this.user, UserCommon.PICTURE_EXTRA_LARGE);
  }

  getPhotos(): string[] {
    if (this.profile === undefined) {
      return [];
    }

    return [...(this.profile.public_album || []), ...(this.profile.private_album || [])]
      .slice(0, this.MAX_PHOTOS)
      .map((photo): string => ImageHelper.getImage(this.profile.profile_id, photo, UserCommon.PICTURE_EXTRA_LARGE));
  }

  getRemainingNumberOfPhotos(): number {
    const photosToDisplay = this.getPhotos();
    if (this.profile === undefined || (photosToDisplay !== undefined && !photosToDisplay.length)) {
      return 0;
    }

    return Math.max(0, this.profile.numberOfPhotos - photosToDisplay.length - 1);
  }

  canOpenConversation(event: MouseEvent): void {
    const isFree: boolean = UserCommon.isDaddyMommyFree(this.user);
    const limitMessageThreshold: boolean = UserCommon.hasSurpassedMessageThreshold(this.user);
    const hasConversation: boolean = ConversationCommon.isConversation(this.currentConversation);

    if (isFree && (limitMessageThreshold || hasConversation)) {
      event.preventDefault();
      event.stopPropagation();
      this.showInterstitial();
    }
  }

  getContentClass(): string {
    if (!this.profileId) {
      return 'skeleton';
    }

    if (this.canShowHighLight()) {
      return 'baby-highlight-border';
    }

    return 'no-highlight-radius';
  }

  canShowHighLight(): boolean {
    return this.isBabyPremium();
  }

  trackGroupByFn(_: number, item: IProfileAttributes): string {
    return item.imagePath;
  }

  protected showInterstitial(): void {
    if (UserCommon.hasSurpassedMessageThreshold(this.user)) {
      this.modal.open(TenMessageModalComponent);
      return;
    }

    this.modal.open(NewModalComponent);
  }
}
