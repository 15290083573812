import { inject } from '@angular/core';
import { SEARCH_DEFAULT_VALUES } from '@libs/shared/search/constraints';
import { ADVANCED_SEARCH_CONTROLS } from '@libs/shared/search/enum';
import { INamedSearch } from '@libs/shared/search/interface/named-search.interface';
import { ISearch } from '@libs/shared/search/interface/search.interface';
import { selectAllSearchProfiles } from '@libs/store/profiles-v2/selectors';
import { profilesSearchRefreshed } from '@libs/store/profiles/actions/search.action';
import { AdvancedSearchActions } from '@libs/store/search/actions';
import { AdvancedSearchSelectors } from '@libs/store/search/selectors';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';
import { Config } from '@meupatrocinio/config';
import { IAdvancedSearchFilterConfig } from '@meupatrocinio/modules/advanced-search/interfaces/advanced-search-filter-config.interface';
import { ISearchRangeConfig } from '@meupatrocinio/modules/advanced-search/interfaces/search-range-config';
import { AdvancedFilterStructureService } from '@meupatrocinio/modules/advanced-search/services/advanced-filter-structure.service';
import { AdvancedSearchService } from '@meupatrocinio/modules/advanced-search/services/advanced-search.service';
import { SearchChipsService } from '@meupatrocinio/modules/advanced-search/services/search-chips.service';
import { SearchFormManagerService } from '@meupatrocinio/modules/advanced-search/services/search-form-manager.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';
import { LocationService } from '@meupatrocinio/services/location/location.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { SearchService } from '@meupatrocinio/services/search.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, tap, withLatestFrom } from 'rxjs/operators';

export class AdvancedSearchEffects {
  private action$: Actions = inject(Actions);
  private downloadManagerService: DownloadManagerService = inject(DownloadManagerService);
  private searchService: SearchService = inject(SearchService);
  private advancedSearchService = inject(AdvancedSearchService);
  private searchFormManagerService = inject(SearchFormManagerService);
  private locationService = inject(LocationService);
  private authenticationService = inject(AuthenticationService);
  private store = inject(Store);
  private modalService = inject(ModalService);
  private globalObjectService = inject(GlobalObjectService);
  private translateService = inject(TranslateService);
  private searchChipsService = inject(SearchChipsService);
  private advancedFilterStructureService = inject(AdvancedFilterStructureService);

  resetIsInitializedForm$ = createEffect(
    () =>
      this.authenticationService.onLogout$.pipe(
        map(() => {
          this.searchFormManagerService.isFormInitialized.set(false);
        }),
      ),
    { dispatch: false },
  );

  recoverSavedSearch$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.recoverSavedSearch),
        withLatestFrom(
          this.store.pipe(select(AdvancedSearchSelectors.currentSearchName)),
          this.store.pipe(select(AdvancedSearchSelectors.savedSearches)),
        ),
        filter(([{ searchFilter }, currentSearchName]) => currentSearchName !== searchFilter.name),
        map(([{ searchFilter }, _, savedSearches]) => {
          const savedSearch = savedSearches.find((search) => search.name === searchFilter.name);

          this.globalObjectService.window.scrollTo(0, 0);

          return AdvancedSearchActions.genericRecoverySavedSearchFlow({
            canUpdate: true,
            search: savedSearch.search,
            searchName: savedSearch.name,
          });
        }),
      ),
    { dispatch: true },
  );

  canRecoverySearch$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.canRecoverSearch),
        withLatestFrom(
          this.store.pipe(select(selectAllSearchProfiles)),
          this.store.pipe(select(AdvancedSearchSelectors.isInitialized)),
          this.store.pipe(select(AdvancedSearchSelectors.searchFilter)),
        ),
        map(([_, searchProfilesId, searchAlreadyAccessed, profileSearch]) => {
          if (profileSearch !== undefined && searchAlreadyAccessed) {
            this.advancedSearchService.isSearching.set(false);

            if (!profileSearch.location_state_id) {
              this.searchFormManagerService.filter.set({
                ...profileSearch,
                location_city_id: this.user.city_id,
                location_country_id: this.user.country_id,
                location_state_id: this.user.state_id,
              });
            }

            this.searchFormManagerService.updateFormWithSearchValues(profileSearch);
            this.searchFormManagerService.updateBasicFormWithSearchValues(profileSearch);

            if (
              profileSearch.age_between !== undefined &&
              profileSearch.height !== undefined &&
              profileSearch.location_radius_first !== undefined
            ) {
              this.advancedFilterStructureService.setInitialValuesForRangeStructure({
                age: profileSearch.age_between,
                height: profileSearch.height,
                distance: profileSearch.location_radius_first,
              });
            }

            this.searchFormManagerService.filter.set(profileSearch);

            return AdvancedSearchActions.setQueryParams({ filter: this.searchFormManagerService.filter() });
          }

          if (searchProfilesId.length !== 0) {
            return AdvancedSearchActions.recoverSavedSearchOnLoad();
          }

          return AdvancedSearchActions.handleRecoverSearchAfterLogin({ search: SEARCH_DEFAULT_VALUES });
        }),
      ),
    { dispatch: true },
  );

  handleRecoverSearchAfterLogin$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.handleRecoverSearchAfterLogin),
        map(({ search }) => {
          const recoveredSearch: ISearch = {
            ...search,
            location_city_id: this.user.city_id,
            location_country_id: this.user.country_id,
            location_state_id: this.user.state_id,
          };

          return AdvancedSearchActions.genericRecoverySavedSearchFlow({
            canUpdate: false,
            search: recoveredSearch,
            searchName: '',
          });
        }),
      ),
    { dispatch: true },
  );

  recoverySavedSearchOnLoad$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.recoverSavedSearchOnLoad),
        withLatestFrom(
          this.store.pipe(select(AdvancedSearchSelectors.currentSearchName)),
          this.store.pipe(select(AdvancedSearchSelectors.savedSearches)),
          this.store.pipe(select(AdvancedSearchSelectors.searchFilter)),
        ),
        map(([__, currentSearchName, savedSearches, searchFilter]) => {
          if (currentSearchName !== '') {
            const savedSearch = savedSearches.find((savedSearche) => savedSearche.name === currentSearchName);

            return AdvancedSearchActions.genericRecoverySavedSearchFlow({
              canUpdate: true,
              search: savedSearch.search ?? SEARCH_DEFAULT_VALUES,
              searchName: savedSearch.name ?? '',
            });
          }

          return AdvancedSearchActions.genericRecoverySavedSearchFlow({
            canUpdate: true,
            search: searchFilter || this.searchFormManagerService.filter(),
            searchName: '',
          });
        }),
      ),
    { dispatch: true },
  );

  handleRecoverySavedSearchFlow$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.genericRecoverySavedSearchFlow),
        withLatestFrom(this.store.pipe(select(AdvancedSearchSelectors.isInitialized))),
        map(([{ search, searchName }, initializing]) => {
          this.store.dispatch(AdvancedSearchActions.setCurrentSearchName({ name: searchName }));

          this.searchFormManagerService.updateFormWithSearchValues(search);
          this.searchFormManagerService.updateBasicFormWithSearchValues(search);
          this.searchFormManagerService.filter.set(search);

          this.advancedSearchService.loadLocation();

          if (!initializing) {
            this.store.dispatch(AdvancedSearchActions.setIsInitializedSearch({ isInitialized: true }));
          }

          this.advancedSearchService.update();
          return AdvancedSearchActions.setQueryParams({ filter: this.searchFormManagerService.filter() });
        }),
      ),
    { dispatch: true },
  );

  handleCountries$ = createEffect(
    () =>
      this.advancedSearchService.countries$.pipe(
        filter(() => this.searchFormManagerService.isFormInitialized()),
        withLatestFrom(this.store.pipe(select(AdvancedSearchSelectors.isInitialized))),
        map(([countries, initializing]) => {
          this.advancedSearchService.countries = countries;

          if (this.advancedSearchService.isInitializing() && !initializing) {
            this.advancedSearchService.setDefaultCountry(this.user);
          }

          const countryCode = FormHelpers.getFormControlValue(
            this.searchFormManagerService.searchFilterForm,
            ADVANCED_SEARCH_CONTROLS.LOCATION_COUNTRY_ID,
          );

          this.locationService.loadRegions(countryCode);
        }),
      ),
    { dispatch: false },
  );

  handleRegions$ = createEffect(
    () =>
      this.advancedSearchService.regions$.pipe(
        filter(() => this.searchFormManagerService.isFormInitialized()),
        withLatestFrom(this.store.pipe(select(AdvancedSearchSelectors.isInitialized))),
        tap(([regions, initializing]) => {
          this.advancedSearchService.regions = regions;

          if (this.advancedSearchService.isInitializing() && !initializing) {
            FormHelpers.setFormControlValue(
              this.searchFormManagerService.searchFilterForm,
              ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID,
              this.user.state_id,
            );
            this.locationService.loadCities(this.user.state_id);

            return;
          }
          this.locationService.loadCities(
            FormHelpers.getFormControlValue(
              this.searchFormManagerService.searchFilterForm,
              ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID,
            ),
          );
        }),
      ),
    { dispatch: false },
  );

  handleCities$ = createEffect(
    () =>
      this.advancedSearchService.cities$.pipe(
        filter(() => this.searchFormManagerService.isFormInitialized()),
        withLatestFrom(this.store.pipe(select(AdvancedSearchSelectors.isInitialized))),
        tap({
          next: ([cities, initializing]) => {
            this.advancedSearchService.cities = cities;

            if (this.advancedSearchService.isInitializing() && !initializing) {
              this.advancedSearchService.isInitializing.set(false);
              FormHelpers.setFormControlValue(
                this.searchFormManagerService.searchFilterForm,
                ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID,
                this.user.city_id,
              );

              this.advancedSearchService.setupSearchChips();
            }

            if (this.advancedSearchService.isSearching()) {
              this.advancedSearchService.updateChipsSubject.next();
              this.advancedSearchService.isSearching.set(false);
            }
          },
        }),
      ),
    { dispatch: false },
  );

  update$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.updateSearchFilter),
        concatMap(({ filter }) => {
          this.searchFormManagerService.updateFormWithSearchValues(filter);
          this.searchFormManagerService.updateBasicFormWithSearchValues(filter);
          this.searchFormManagerService.filter.set(filter);
          this.advancedSearchService.setupSearchChips();

          this.advancedSearchService.changeDetectorFilterSubject.next();
          return [AdvancedSearchActions.setSearch({ search: filter }), profilesSearchRefreshed()];
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  loadList$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.loadList),
        filter(() => this.searchFormManagerService.isFormInitialized()),
        concatMap(() =>
          this.downloadManagerService.updateNextPage('search', this.searchFormManagerService.filter()).pipe(
            exponentialBackoff(),
            tap({
              next: () => {
                this.advancedSearchService.applyQueryParams(this.searchFormManagerService.filter());
                this.advancedSearchService.changeDetectorFilterSubject.next();
                this.advancedSearchService.isDownloadingSubject.next();
              },
            }),
          ),
        ),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  downloadSavedSearches$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.downloadSavedSearch),
        concatMap(() =>
          this.downloadManagerService.updateNextPage('savedSearch').pipe(
            exponentialBackoff(),
            catchError(() => {
              this.openModalError();

              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  saveOrUpdate$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.createOrUpdateSavedSearch),
        concatMap(({ filter, searchName }) => {
          return this.searchService.createOrUpdate(searchName, filter).pipe(
            exponentialBackoff(),
            concatMap(() => {
              this.globalObjectService.window.scrollTo(0, 0);

              return of(AdvancedSearchActions.resetAndUpdateSearch());
            }),
            catchError(() => {
              this.openModalError();

              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: true },
  );

  resetAndUpdateSearch$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.resetAndUpdateSearch),
        concatMap(() =>
          this.downloadManagerService.resetAndUpdate('savedSearch').pipe(
            exponentialBackoff(),
            catchError(() => {
              this.openModalError();

              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  deleteSavedSearch = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.deleteSavedSearch),
        withLatestFrom(
          this.store.pipe(select(AdvancedSearchSelectors.savedSearches)),
          this.store.pipe(select(AdvancedSearchSelectors.currentSearchName)),
        ),
        concatMap(([{ id }, savedSearches, currentSearchName]) =>
          this.searchService.delete(id).pipe(
            exponentialBackoff(),
            map(() => {
              const searchToRemove = savedSearches.find((item) => item.id === id);

              if (searchToRemove.name === currentSearchName) {
                this.store.dispatch(AdvancedSearchActions.setCurrentSearchName({ name: '' }));
              }

              const updatedSavedSearches = savedSearches.filter((item) => item.id !== searchToRemove.id);

              return AdvancedSearchActions.setSavedSearches({ savedSearches: updatedSavedSearches });
            }),
            catchError(() => {
              this.openModalError();

              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: true },
  );

  handleFocusSection$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.focusElement),
        filter(({ config, sectionName }) => config !== undefined && sectionName !== undefined),
        delay(500),
        map(({ sectionName, config }) => {
          const element: HTMLElement = this.globalObjectService.window.document.querySelector('section#' + sectionName);

          element.scrollIntoView({ behavior: 'smooth', block: 'center' });

          if (this.isASectionWithVisibilitySwitch(sectionName)) {
            const index = config.findIndex(
              (item: ISearchRangeConfig | IAdvancedSearchFilterConfig) => item.name === sectionName,
            );
            config[index].isHidden = false;
            this.advancedSearchService.changeDetectorFilterSubject.next();
          }

          return element;
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  setIsSearch$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.setIsSearching),
        tap({
          next: ({ isSearching }) => {
            this.advancedSearchService.isSearching.set(isSearching);
          },
        }),
      ),
    { dispatch: false },
  );

  setSearchCips$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AdvancedSearchActions.setSearchChips),
        withLatestFrom(
          this.advancedSearchService.countries$,
          this.advancedSearchService.regions$,
          this.advancedSearchService.cities$,
        ),
        delay(1000),
        tap({
          next: ([_, countries, regions, cities]) => {
            this.searchChipsService.setUpChips(countries, regions, cities);
          },
        }),
      ),
    { dispatch: false },
  );

  protected isCurrentSearch(savedSearches: INamedSearch[], currentSearchName: string) {
    return savedSearches.some((search) => search.name === currentSearchName);
  }

  protected get user() {
    return this.authenticationService.get();
  }

  protected isASectionWithVisibilitySwitch(sectionName: string) {
    return (
      sectionName !== 'location' &&
      sectionName !== 'keyword_search' &&
      sectionName !== 'sortkey' &&
      sectionName !== 'location_radius_first'
    );
  }

  private openModalError() {
    const translatedKey = this.translateService.instant('common.request.error', { supportLink: Config.supportLink });

    this.modalService.open(translatedKey);
  }
}
