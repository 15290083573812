import { HttpHeaders } from '@angular/common/http';

export abstract class AccountCommon {
  public static checkAndAddUserDataCookie(headers: HttpHeaders) {
    const cookies = document.cookie.split(';');
    const suspendedUserCookie = cookies.find((cookie) => cookie.trim().startsWith('_mp_v='));

    if (suspendedUserCookie) {
      try {
        const cookieValue = suspendedUserCookie.split('=')[1];

        if (!cookieValue) {
          return headers;
        }

        const parsedValue = JSON.parse(cookieValue);

        if (
          typeof parsedValue !== 'object' ||
          !parsedValue.v ||
          !parsedValue.t ||
          typeof parsedValue.v !== 'string' ||
          typeof parsedValue.t !== 'string'
        ) {
          return headers;
        }

        const timestamp = parseInt(parsedValue.t, 10);

        if (isNaN(timestamp)) {
          return headers;
        }

        return headers.append('x-user-data', cookieValue);
      } catch {
        return headers;
      }
    }

    return headers;
  }
}
