<div class="payment-modal-form">
    <form
        [formGroup]="paymentModalForm"
        (ngSubmit)="makePayment()"
    >
        <div class="container-fluid no-padding">
            <div class="row">
                <div class="col-xs-12">
                    <div class="form-field feedback">
                        <label for="cardNumber">
                            {{ 'modules.main.pages.payment.cc_number' | translate }}
                        </label>
                        <input
                            id="cardNumber"
                            formControlName="cardNumber"
                            (change)="handleCardNumber()"
                            [placeholder]="'modules.main.pages.payment.cc_number.placeholder' | translate"
                            autocorrect="off"
                            spellcheck="false"
                            name="cardNumber"
                            creditCardMask
                            inputmode="numeric"
                        >
                        <small *ngIf="!isValidCardNumber">{{ getErrorMessage('cardNumber') | translate }}</small>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div class="form-field feedback">
                        <label for="date">
                            {{ 'modules.main.pages.payment.cc_expiry.label' | translate }}
                        </label>
                        <input
                            id="date"
                            monthYearMask
                            formControlName="date"
                            (change)="handleDate()"
                            [placeholder]="'modules.main.pages.payment.cc_expiry.placeholder' | translate"
                            name="date"
                            inputmode="numeric"
                            required
                        >
                        <small *ngIf="!isValidDate">{{ getErrorMessage('date') | translate }}</small>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div class="form-field feedback">
                        <label for="cvv">
                            {{ 'modules.main.pages.payment.cc_cvv' | translate }}
                        </label>
                        <mp-tooltip
                            class="payment-tooltip"
                            [tooltipText]="tooltipText | translate"
                            [tooltipIcon]="'&#xe88e;'"
                            [tooltipPosition]="tooltipPosition"
                        >
                        </mp-tooltip>
                        <input
                            id="cvv"
                            pattern="[0-9]*"
                            formControlName="cvv"
                            [placeholder]="'modules.main.pages.payment.cc_cvv.placeholder' | translate"
                            (change)="checkCVVValidation()"
                            autocorrect="off"
                            spellcheck="false"
                            name="cvv"
                            inputmode="numeric"
                            minlength="3"
                            maxlength="4"
                            required
                        >
                        <small *ngIf="!isValidCVV">{{ getErrorMessage('cvv') | translate }}</small>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="form-field feedback">
                        <label for="holder">
                            {{ 'modules.main.pages.payment.cc_holder' | translate }}
                        </label>
                        <input
                            id="holder"
                            type="text"
                            formControlName="holder"
                            (change)="checkHolderValidation()"
                            [placeholder]="'modules.main.pages.payment.cc_holder.placeholder' | translate"
                            autocorrect="off"
                            spellcheck="false"
                            maxlength="26"
                            name="holder"
                            required
                        >
                        <small *ngIf="!isValidHolder">{{ getErrorMessage('holder') | translate}}</small>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="form-field">
                        <div class="custom-radio-check mini">
                            <input
                                id="internationalCard"
                                type="checkbox"
                                name="isIssuedAbroad"
                                formControlName="isIssuedAbroad"
                                (change)="handleIsIssuedAbroad($event.target.checked)"
                            >
                            <label for="internationalCard">
                                {{ 'modules.main.pages.payment.cc_international' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    class="col-xs-12"
                    *ngIf="!isIssuedAbroad()"
                >
                    <div class="form-field feedback">
                        <label for="cpf">
                            {{ 'modules.main.pages.payment.cpf' | translate }}
                        </label>
                        <input
                            id="cpf"
                            formControlName="cpf"
                            autocorrect="off"
                            spellcheck="false"
                            cpfMask
                            name="holder"
                            inputmode="numeric"
                            required
                        >
                        @if (paymentModalForm.get('cpf').touched && !paymentModalForm.get('cpf').valid) {
                            <small>{{ getErrorMessage('cpf') | translate }}</small>
                        }
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="form-field feedback">
                        <button
                            class="mp-main-button bt-md bt-shadow full-width"
                            type="submit"
                            [disabled]="!isFormGroupValid()"
                            *ngIf="!showLoading"
                        >
                            {{ submitButtonTranslation | translate }}
                        </button>
                        <mp-payment-loading *ngIf="showLoading"></mp-payment-loading>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
