import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IGetPhotos } from '@libs/shared/profile/get-photos';
import { UserCommon } from '@libs/shared/user/user.common';

import { ImageHelper } from '@meupatrocinio/utils/image-helper';

@Pipe({
  name: 'getPhotos',
  pure: true,
  standalone: false,
})
export class GetPhotosPipe implements PipeTransform {
  public readonly MAX_PHOTOS: number = 4;

  constructor(public translate: TranslateService) {
    //
  }

  transform({ profile }: IGetPhotos): string[] {
    return [...(profile?.public_album ?? []), ...(profile?.private_album ?? [])]
      .slice(0, this.MAX_PHOTOS)
      .map((photo): string => ImageHelper.getImage(profile.profile_id, photo, UserCommon.PICTURE_EXTRA_LARGE));
  }
}
