import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { PixStateFacadeService } from '@libs/modules/payment-v2/services/pix-state-facade.service';
import { MaterialIcons } from '@libs/shared/material-icons/material-icons.common';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { PaymentInfoActions } from '@libs/store/payment-info';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CPFValidations } from '@libs/services/payment/validators';
import { UpgradeAccountScreenActions } from '@libs/store/upgrade-account';
import { UuidGenerator } from '@libs/utils/uuid-generator/uuid-generator';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions/actions';
import { CPFValidator } from '@meupatrocinio/modules/payment-v2/validators/cpf-validator';
import { ModalService } from '@meupatrocinio/services/modal.service';

@Component({
  selector: 'mp-pix-payment-form',
  templateUrl: 'pix-payment-form.html',
  styleUrls: ['pix-payment-form.scss'],
  standalone: false,
})
export class PixPaymentFormComponent implements OnInit, OnDestroy {
  @Input() type: MembershipType = 0;
  @Input() period = 0;
  @Input() public productDescription = '';
  @Input() productUuid = '';
  @Input() variantUuid = '';
  @Input() shouldHideCpf = false;

  public cpfValidator: CPFValidator;
  public isLoading$: Observable<boolean> = of(false);
  public shouldHideQrCode$: Observable<boolean> = of(false);
  public qrCode$: Observable<string> = of('');
  public copyCode$: Observable<string> = of('');
  public qrCodeGenerationTimestamp$: Observable<number> = of(0);

  public formCpf: UntypedFormGroup;
  public cpfValidationMessage = '';

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store,
    private readonly paymentHelpersService: PaymentHelpersService,
    private readonly modalService: ModalService,
    private readonly translateService: TranslateService,
    private readonly clipboard: Clipboard,
    private readonly pixStateFacadeService: PixStateFacadeService,
  ) {
    this.cpfValidator = new CPFValidator(
      this.paymentHelpersService,
      this.modalService,
      this.translateService,
      this.type,
      this.period,
    );
  }

  public ngOnInit() {
    this.buildPaymentFormGroup();
    this.pixStateFacadeService.copyCode$.subscribe((result) => {
      if (result) {
        localStorage.setItem(
          'pix-premium-previous-data',
          JSON.stringify({
            qrCode: this.pixStateFacadeService.qrCodeValue,
            copyCode: this.pixStateFacadeService.copyCodeValue,
            qrCodeGenerationTimestamp: this.pixStateFacadeService.qrCodeGenerationTimestampValue,
          }),
        );
      }
    });

    this.isLoading$ = this.pixStateFacadeService.isPaying$;
    this.qrCode$ = this.pixStateFacadeService.qrCode$;
    this.copyCode$ = this.pixStateFacadeService.copyCode$;
    this.shouldHideQrCode$ = this.pixStateFacadeService.shouldHideQrCode$;
    this.qrCodeGenerationTimestamp$ = this.pixStateFacadeService.qrCodeGenerationTimestamp$;
    this.checkPreviousPix();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { variantUuid } = changes;

    if (variantUuid !== undefined && !variantUuid.previousValue && this.variantUuid) {
      this.checkPreviousPix();
    }
  }

  checkPreviousPix() {
    const parseJSON = (key: string) => {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    };

    const previousPixData = parseJSON('pix-premium-previous-data');
    const previousPaymentData = parseJSON('payment-premium-previous-data');

    if (!previousPixData || !previousPaymentData) {
      return this.pixStateFacadeService.savePaymentData('payment-premium-previous-data', {
        variantUuid: this.variantUuid,
        productUuid: this.productUuid,
      });
    }

    const { qrCodeGenerationTimestamp, qrCode, copyCode } = previousPixData;
    const now = Date.now();
    const expirationTime = 15 * 60 * 1000;

    const isSamePayment =
      previousPaymentData.productUuid === this.productUuid && previousPaymentData.variantUuid === this.variantUuid;

    const isQrCodeValid = now - qrCodeGenerationTimestamp <= expirationTime;
    if (isQrCodeValid && isSamePayment) {
      this.qrCode$ = of(qrCode);
      this.copyCode$ = of(copyCode);
      this.qrCodeGenerationTimestamp$ = of(qrCodeGenerationTimestamp);
      return;
    }

    this.pixStateFacadeService.removePaymentData('pix-premium-previous-data');
    this.pixStateFacadeService.savePaymentData('payment-premium-previous-data', {
      variantUuid: this.variantUuid,
      productUuid: this.productUuid,
    });
  }

  public ngOnDestroy() {
    this.resetPixData();
  }

  public get icon() {
    return MaterialIcons.ContentCopy;
  }

  public canShowPaymentContent(qrCode: string, copyCode: string) {
    return qrCode !== '' && copyCode !== '';
  }

  public onSubmit() {
    if (!this.isFormValid()) {
      return;
    }

    this.makePixPayment();
  }

  public isFormValid() {
    return this.cpfValidator.isFormValid(this.formCpf.get('cpf').value);
  }

  public makePixPayment() {
    const idempotencyKey = UuidGenerator.generate();

    this.store.dispatch(
      ProviderSwitchActions.handlePixPayment({
        paymentData: {
          taxId: this.formCpf.get('cpf').value,
          product: {
            product_uuid: this.productUuid,
            variant_uuid: this.variantUuid,
          },
          idempotencyKey,
        },
      }),
    );
  }

  public copyToClipboard(url: string) {
    this.clipboard.copy(url);
  }

  public resetPixData() {
    this.store.dispatch(PaymentInfoActions.resetPixData());
  }

  public navigateToUpgradeAccount() {
    this.store.dispatch(UpgradeAccountScreenActions.navigateToUpgradeAccount());
  }

  protected buildPaymentFormGroup() {
    this.formCpf = this.formBuilder.group({
      cpf: ['', CPFValidations.validations.validation],
    });

    const errors = this.formCpf.get('cpf')?.errors;
    this.cpfValidationMessage = CPFValidations.validations.messages(errors);
  }
}
