import { Injectable, OnDestroy, inject, signal } from '@angular/core';
import { ICity } from '@libs/services/location/city/city';
import { ICountry } from '@libs/services/location/country/country';
import { IRegion } from '@libs/services/location/region/region';
import { AdvancedSearchCommon } from '@libs/shared/search/advanced-search.common';
import { ADVANCED_SEARCH_CONTROLS, SORT_ACTIVITY } from '@libs/shared/search/enum';
import { ISearch } from '@libs/shared/search/interface/search.interface';
import { AdvancedSearchActions } from '@libs/store/search/actions';
import { AdvancedSearchSelectors } from '@libs/store/search/selectors';
import { ISearchFilterList } from '@meupatrocinio/modules/advanced-search/interfaces/search-filter-list.interface';
import { AdvancedFilterStructureService } from '@meupatrocinio/modules/advanced-search/services/advanced-filter-structure.service';
import { ILocationChip } from '@meupatrocinio/modules/advanced-search/services/interfaces/location-chip.interface';
import { SearchFormManagerService } from '@meupatrocinio/modules/advanced-search/services/search-form-manager.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { LocationService } from '@meupatrocinio/services/location/location.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Injectable()
export class SearchChipsService implements OnDestroy {
  private searchFormManagerService = inject(SearchFormManagerService);
  private translateService = inject(TranslateService);
  private locationService = inject(LocationService);
  private store = inject(Store);
  private authenticationService = inject(AuthenticationService);
  private advancedFilterStructureService = inject(AdvancedFilterStructureService);
  private destroy$ = new Subject<void>();

  public filterList = signal<ISearchFilterList[]>([]);
  public lastCountry$ = this.store.select(AdvancedSearchSelectors.lastCountryName);
  public lastRegion$ = this.store.select(AdvancedSearchSelectors.lastRegionName);
  public lastCity$ = this.store.select(AdvancedSearchSelectors.lastCityName);

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setupInitialChip() {
    this.filterList.set([]);

    this.setupKeywordChip();
    this.setupDistanceChip();
    this.setupKeyChip();
    this.setupAgeChip();
    this.setupHeightChip();
    this.setUpMultivaluedFilters();

    combineLatest([this.lastCountry$, this.lastRegion$, this.lastCity$])
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(([country, region, city]) => {
        if (!country) {
          return;
        }

        this.setupInitialLocationChip(country, region, city);
      });
  }

  public setUpChips(countries: ICountry[], regions: IRegion[], cities: ICity[]) {
    this.filterList.set([]);

    this.setupKeywordChip();
    this.setupDistanceChip();
    this.setupKeyChip();
    this.setupAgeChip();
    this.setupHeightChip();
    this.setUpMultivaluedFilters();
    this.setupLocationChip(countries, regions, cities);
  }

  private get user() {
    return this.authenticationService.get();
  }

  private focusSearchConfig(sectionName: string) {
    this.store.dispatch(AdvancedSearchActions.setIsDisplayingResult({ isDisplayingResult: false }));
    this.store.dispatch(
      AdvancedSearchActions.focusElement({
        config: this.advancedFilterStructureService.searchStructure(),
        sectionName,
      }),
    );
  }

  private focusSearchRangeConfig(sectionName: string) {
    this.store.dispatch(AdvancedSearchActions.setIsDisplayingResult({ isDisplayingResult: false }));
    this.store.dispatch(
      AdvancedSearchActions.focusElement({
        config: this.advancedFilterStructureService.searchRangeStructure(),
        sectionName,
      }),
    );
  }

  private clearAttributeValues(name: string, value: number[]) {
    const newFilterValue: ISearch = {
      ...this.searchFormManagerService.filter(),
      [name]: value,
    };

    this.store.dispatch(AdvancedSearchActions.setIsSearching({ isSearching: true }));
    this.store.dispatch(AdvancedSearchActions.setIsDisplayingResult({ isDisplayingResult: true }));
    this.store.dispatch(AdvancedSearchActions.updateSearchFilter({ filter: newFilterValue }));
  }

  private setupKeywordChip() {
    if (this.searchFormManagerService.filter().keyword_search === '') {
      return;
    }

    AdvancedSearchCommon.updateFilterList(this.filterList, {
      label: 'modules.main.pages.search_filter.filter_text',
      labelParams: {
        title: this.translateService.instant('modules.main.pages.search_filter.keywords_short'),
        label: this.searchFormManagerService.filter().keyword_search,
      },
      focus: () => this.focusSearchConfig(ADVANCED_SEARCH_CONTROLS.KEYWORD_SEARCH),
      clearCallback: () =>
        this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.KEYWORD_SEARCH).setValue(''),
    });
  }

  public setupInitialLocationChip(country: string, region: string, city: string) {
    const location: ILocationChip = {};
    let label = '';

    if (country) {
      location.country = country;
    }

    if (region) {
      location.state = region;
      label = this.translateService.instant('modules.main.pages.search_filter.formatted_state', location);
    }

    if (city) {
      location.city = city;
      label = this.translateService.instant('modules.main.pages.search_filter.formatted_location', location);
    }

    AdvancedSearchCommon.updateFilterList(this.filterList, {
      label,
      focus: () => this.focusSearchConfig('location'),
    });
  }

  private setupLocationChip(countries: ICountry[], regions: IRegion[], cities: ICity[]) {
    let location: { city?: string; state?: string; country?: string } = {};
    let label = '';

    if (!this.searchFormManagerService.filter().location_country_id) {
      return;
    }

    const country = this.locationService.getCountryName(
      countries,
      this.searchFormManagerService.filter().location_country_id,
    );

    location = { country };
    label = location.country;

    this.store.dispatch(AdvancedSearchActions.setLastCountryName({ name: country }));

    if (this.searchFormManagerService.filter().location_state_id) {
      const region = this.locationService.getRegionName(
        regions,
        this.searchFormManagerService.filter().location_state_id,
      );

      location.state = region;
      label = this.translateService.instant('modules.main.pages.search_filter.formatted_state', location);
      this.store.dispatch(AdvancedSearchActions.setLastRegionName({ name: region }));
    } else {
      this.store.dispatch(AdvancedSearchActions.setLastRegionName({ name: '' }));
    }

    if (this.searchFormManagerService.filter().location_city_id) {
      const city = this.locationService.getCityName(cities, this.searchFormManagerService.filter().location_city_id);

      location.city = city;
      this.store.dispatch(AdvancedSearchActions.setLastCityName({ name: city }));
      label = this.translateService.instant('modules.main.pages.search_filter.formatted_location', location);
    } else {
      this.store.dispatch(AdvancedSearchActions.setLastCityName({ name: '' }));
    }

    AdvancedSearchCommon.updateFilterList(this.filterList, {
      label,
      focus: () => this.focusSearchConfig('location'),
    });
  }

  private setupDistanceChip() {
    AdvancedSearchCommon.updateFilterList(this.filterList, {
      label: 'modules.main.pages.search_filter.distance_km',
      labelParams: {
        label: this.searchFormManagerService.filter().location_radius_first.toString(),
      },
      focus: () => this.focusSearchRangeConfig(ADVANCED_SEARCH_CONTROLS.LOCATION_RADIUS_FIRST),
    });
  }

  private setupKeyChip() {
    let sortkey = this.searchFormManagerService.filter().sortkey;

    if (this.searchFormManagerService.filter().sortkey === '') {
      sortkey = SORT_ACTIVITY.RECENTLY;
    }

    AdvancedSearchCommon.updateFilterList(this.filterList, {
      label: `modules.main.pages.search_filter.sort.${sortkey}`,
      focus: () => this.focusSearchConfig(ADVANCED_SEARCH_CONTROLS.SORTKEY),
    });
  }

  private setupAgeChip() {
    const showAgeLabel = (age: number[]) => {
      if (!age || age.length !== 2) {
        return {};
      }

      let maxAgePlus = '';
      if (age[1] >= AdvancedSearchCommon.MAX_AGE) {
        maxAgePlus = '+';
      }

      return {
        min_age: age[0].toString(),
        max_age: age[1].toString() + maxAgePlus,
      };
    };

    AdvancedSearchCommon.updateFilterList(this.filterList, {
      label: 'modules.main.pages.search_filter.filter_text',
      labelParams: {
        title: this.translateService.instant('modules.main.pages.search_filter.age'),
        label: this.translateService.instant(
          'modules.main.pages.search_filter.age_filter',
          showAgeLabel(
            AdvancedSearchCommon.getValidValue(
              this.searchFormManagerService.filter().age_between,
              AdvancedSearchCommon.STANDARD_AGE_RANGE,
            ),
          ),
        ),
      },
      focus: () => this.focusSearchRangeConfig(ADVANCED_SEARCH_CONTROLS.AGE),
    });
  }

  private setupHeightChip() {
    AdvancedSearchCommon.updateFilterList(this.filterList, {
      label: 'modules.main.pages.search_filter.filter_text',
      labelParams: {
        title: this.translateService.instant('modules.main.pages.profile.details_page.height'),
        label: this.translateService.instant(
          'modules.main.pages.search_filter.height_filter',
          AdvancedSearchCommon.showHeightLabel(
            AdvancedSearchCommon.getValidValue(
              this.searchFormManagerService.filter().height,
              AdvancedSearchCommon.STANDARD_HEIGHT_RANGE,
            ),
          ),
        ),
      },
      focus: () => this.focusSearchRangeConfig(ADVANCED_SEARCH_CONTROLS.HEIGHT),
    });
  }

  private setUpMultivaluedFilters() {
    const attributeMultiValued = this.advancedFilterStructureService
      .searchStructure()
      .filter((item) => item.multiValued && !item.isBasicFilter);

    for (const attribute of attributeMultiValued) {
      const values = this.searchFormManagerService.filter()[attribute.name];

      if (values && Array.isArray(values) && values.length > 0) {
        for (const value of values) {
          const label = attribute.status(value).toString();

          AdvancedSearchCommon.updateFilterList(this.filterList, {
            label: 'modules.main.pages.search_filter.filter_text',
            labelParams: {
              title: AdvancedSearchCommon.getAttributeLabelTitle(attribute.name, this.translateService, this.user),
              label: this.translateService.instant(label),
            },
            clearCallback: () =>
              this.clearAttributeValues(
                attribute.name,
                values.filter((currentValue) => currentValue !== value),
              ),
            focus: () => this.focusSearchConfig(attribute.name),
          });
        }
      }
    }
  }
}
