import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MPWebMissingTranslationHandler } from '@libs/utils/missing-translation-handler.web';
import { AdvancedSearchComponent } from '@meupatrocinio/modules/advanced-search/advanced-search.component';
import { AdvancedFilterComponent } from '@meupatrocinio/modules/advanced-search/components/advanced-filter/advanced-filter.component';
import { SaveSearchModalComponent } from '@meupatrocinio/modules/advanced-search/components/save-search-modal/save-search-modal.component';
import { SavedSearchComponent } from '@meupatrocinio/modules/advanced-search/components/saved-search/saved-search.component';
import { SearchFilterChipsComponent } from '@meupatrocinio/modules/advanced-search/components/search-filter-chips/search-filter-chips.component';
import { AdvancedSearchEffects } from '@meupatrocinio/modules/advanced-search/effects/advanced-search.effects';
import { AdvancedFilterStructureService } from '@meupatrocinio/modules/advanced-search/services/advanced-filter-structure.service';
import { AdvancedSearchService } from '@meupatrocinio/modules/advanced-search/services/advanced-search.service';
import { SearchChipsService } from '@meupatrocinio/modules/advanced-search/services/search-chips.service';
import { SearchFormManagerService } from '@meupatrocinio/modules/advanced-search/services/search-form-manager.service';
import { InputTextComponent } from '@meupatrocinio/modules/main/shared/input/input-text/input-text.component';
import { SearchListComponent } from '@meupatrocinio/modules/main/shared/search-list/search-list.component';
import { SharedModule } from '@meupatrocinio/modules/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { NouisliderModule } from 'ng2-nouislider';

@NgModule({
  declarations: [
    AdvancedSearchComponent,
    AdvancedFilterComponent,
    SearchFilterChipsComponent,
    SavedSearchComponent,
    InputTextComponent,
    SearchListComponent,
    SaveSearchModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NouisliderModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MPWebMissingTranslationHandler,
      },
    }),
    EffectsModule.forFeature([AdvancedSearchEffects]),
  ],
  providers: [
    SearchFormManagerService,
    AdvancedSearchService,
    SearchChipsService,
    AdvancedFilterStructureService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AdvancedSearchModule {
  //
}
