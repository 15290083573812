import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ICity } from '@libs/services/location/city/city';
import { ICountry } from '@libs/services/location/country/country';
import { IRegion } from '@libs/services/location/region/region';

const locationCountryFeature = createFeatureSelector<ICountry[]>('locationStoreCountries');
const locationRegionFeature = createFeatureSelector<IRegion[]>('locationStoreRegions');
const locationCityFeature = createFeatureSelector<ICity[]>('locationStoreCities');

export const selectLocationStoreCountries = createSelector(locationCountryFeature, (state: ICountry[]) => state);

export const selectLocationStoreRegions = createSelector(locationRegionFeature, (state: IRegion[]) => state);

export const selectLocationStoreCities = createSelector(locationCityFeature, (state: ICity[]) => state);
