import { IProfile } from '@libs/shared/profile/profile';
import { createAction, props } from '@ngrx/store';

const BLOCK_ACTIONS_RESET_AND_UPDATE = '[Block Actions] - Reset and Update Blocked List';
const BLOCK_ACTIONS_LIST_UPDATE_SUCCESS = '[Block Actions] - Blocked List Updated Successfully';
const BLOCK_ACTIONS_LIST_UPDATE_ERROR = '[Block Actions] - Blocked List Update Error';
const BLOCK_USER = '[Block Actions] - Block User';
const BLOCK_USER_SUCCESS = '[Block Actions] - Block User Success';

export const resetAndUpdateBlockedList = createAction(BLOCK_ACTIONS_RESET_AND_UPDATE);

export const blockedListUpdated = createAction(BLOCK_ACTIONS_LIST_UPDATE_SUCCESS);

export const blockedListUpdateError = createAction(BLOCK_ACTIONS_LIST_UPDATE_ERROR);

export const blockUser = createAction(BLOCK_USER, props<{ profile: IProfile }>());

export const blockUserSuccess = createAction(BLOCK_USER_SUCCESS);
