import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { IProfile } from '@libs/shared/profile/profile';
import { IApplicationState } from '@libs/store/application-state';
import * as BlockActions from '@libs/store/block/actions';
import { Store } from '@ngrx/store';
@Component({
  selector: 'mp-block-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './block-modal.component.html',
  standalone: false,
})
export class BlockModalComponent {
  private modalRef = inject(ModalRef);
  private router = inject(Router);
  private store = inject(Store<IApplicationState>);

  constructor(@Inject(MODAL_DATA) public data: IProfile) {}

  public closeModal() {
    this.router.navigate(['main']);
    this.modalRef.close();
  }

  public blockeUser() {
    this.store.dispatch(BlockActions.blockUser({ profile: this.data }));
    this.modalRef.close();
  }
}
