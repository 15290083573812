import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, inject, signal } from '@angular/core';
import { INamedSearch } from '@libs/shared/search/interface/named-search.interface';
import { ISearch } from '@libs/shared/search/interface/search.interface';
import { AdvancedSearchActions } from '@libs/store/search/actions';
import { AdvancedSearchSelectors } from '@libs/store/search/selectors';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'mp-saved-search',
  templateUrl: './saved-search.component.html',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedSearchComponent implements OnInit {
  @Output() onClick = new EventEmitter<INamedSearch>();

  private store = inject(Store);
  private downloadManager = inject(DownloadManagerService);

  public savedSearches$ = this.store.pipe(select(AdvancedSearchSelectors.savedSearches));
  public currentSelectedSearch$ = this.store.pipe(select(AdvancedSearchSelectors.currentSearchName));
  public displayResult$ = this.store.pipe(select(AdvancedSearchSelectors.isDisplayingResult));
  public isOpen = signal(false);

  ngOnInit(): void {
    this.initializeSavedSearch();
  }

  public currentSearchModifierClass(search: INamedSearch, currentSearchName: string) {
    return search.name === currentSearchName ? 'tw-bg-[#fff7f7]' : '';
  }

  private initializeSavedSearch() {
    this.downloadManager.updateNextPage('savedSearch');
  }

  public menuEnter() {
    this.isOpen.set(!this.isOpen());
  }

  public menuLeave() {
    this.isOpen.set(false);
  }

  public removeSearch(id: number) {
    this.store.dispatch(AdvancedSearchActions.deleteSavedSearch({ id }));
    this.isOpen.set(false);
  }

  public canOpenList(savedSearch: ISearch[]) {
    return this.isOpen() && savedSearch.length > 0;
  }

  public isEmptySavedSearches(savedSearches: INamedSearch[]) {
    return savedSearches.length === 0;
  }

  public restoreSearch(search: INamedSearch) {
    if (this.downloadManager.isDownloading('search')) {
      return;
    }

    this.onClick.emit(search);
  }
}
