import { ILanguage } from '@libs/shared/language/interfaces/language.interface';

export class LanguageModel {
  public static readonly LANGUAGE_EN_US: ILanguage = {
    lang: 'en-US',
    display: 'English (USA)',
    baseLang: 'en',
  };
  public static readonly LANGUAGE_PT_BR: ILanguage = {
    lang: 'pt-BR',
    display: 'Português (BR)',
    baseLang: 'pt',
  };
  public static readonly LANGUAGE_ES_CO: ILanguage = {
    lang: 'es-CO',
    display: 'Español (ES)',
    baseLang: 'co',
  };

  public static readonly DEFAULT_LANGUAGE: ILanguage = LanguageModel.LANGUAGE_PT_BR;

  public static readonly ALL_LANGUAGES: ILanguage[] = [
    LanguageModel.LANGUAGE_PT_BR,
    LanguageModel.LANGUAGE_EN_US,
    LanguageModel.LANGUAGE_ES_CO,
  ];

  public static getAllAvailableLanguages(): string[] {
    return this.ALL_LANGUAGES.map((language: ILanguage): string => language.lang);
  }
}
