import { AdvancedSearchCommon } from '@libs/shared/search/advanced-search.common';
import { SORT_ACTIVITY } from '@libs/shared/search/enum';
import { ISearch } from '@libs/shared/search/interface/search.interface';

export const SEARCH_DEFAULT_VALUES: ISearch = {
  location_radius_first: AdvancedSearchCommon.DISTANCE_STANDARD,
  location_country_id: AdvancedSearchCommon.STANDARD_COUNTRY_ID,
  location_state_id: '',
  keyword_search: '',
  ethnicity: [],
  hair_color: [],
  income: [],
  net_worth: [],
  eye_color: [],
  smoke: [],
  drink: [],
  body_type: [],
  relationship: [],
  have_children: [],
  education: [],
  happy_to_travel: [],
  travel_intention: [],
  occupation: [],
  sex: [],
  age_between: AdvancedSearchCommon.STANDARD_AGE_RANGE,
  sortkey: SORT_ACTIVITY.RECENTLY,
  openedTab: true,
  basic_filter: {
    has_photos: false,
    featured: false,
    verified_photos: false,
    unviewed: false,
    viewed: false,
    viewed_me: false,
    favorite: false,
    favorited_me: false,
  },
  height: AdvancedSearchCommon.STANDARD_HEIGHT_RANGE,
};
