import { createActionGroup, props } from '@ngrx/store';

import { ICreateBoletoPayment } from '@libs/modules/payment-v2/interfaces/create-boleto-payment.interface';
import { ICreatePixPayment } from '@libs/modules/payment-v2/interfaces/create-pix-payment.interface';

import { IHandleCreditCardPayment } from '@libs/modules/payment-v2/interfaces/handle-credit-card-payment.interface';
import { IPagSeguroCreateCreditCardPayment } from '@meupatrocinio/modules/payment-v2/providers/pagseguro-v2/interfaces/create-credit-card-payment.interface';

export const PagSeguroV2Actions = createActionGroup({
  source: 'PagSeguroV2',
  events: {
    'Handle Credit Card Payment': props<IHandleCreditCardPayment>(),
    'Create Credit Card Payment': props<IPagSeguroCreateCreditCardPayment>(),
    'Create Boleto Payment': props<ICreateBoletoPayment>(),
    'Create Pix Payment': props<ICreatePixPayment>(),
  },
});
