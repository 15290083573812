import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AdvancedSearchService } from '@meupatrocinio/modules/advanced-search/services/advanced-search.service';
import { SearchChipsService } from '@meupatrocinio/modules/advanced-search/services/search-chips.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-search-chips',
  templateUrl: './search-filter-chips.component.html',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SearchFilterChipsComponent implements OnInit, OnDestroy {
  private advancedSearchService = inject(AdvancedSearchService);
  private searchChipsService = inject(SearchChipsService);
  private translateService = inject(TranslateService);
  private downloadManager = inject(DownloadManagerService);
  private store = inject(Store);

  public filterList = this.searchChipsService.filterList;
  public profilesId$ = this.store.pipe(select('storeSearchProfiles'));
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.searchChipsService.setupInitialChip();
    this.updateChips();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get numberOfProfilesFound() {
    return this.translateService.instant('modules.main.pages.search_filter.number_of_results', {
      results: this.downloadManager.totalItems('search') < 1000 ? this.downloadManager.totalItems('search') : 1000,
    });
  }

  public get isDownloading() {
    return this.downloadManager.isDownloading('search');
  }

  public saveSearch() {
    this.advancedSearchService.saveSearch();
  }

  public resetFilter() {
    this.advancedSearchService.resetFilter();
  }

  public updateChips() {
    this.advancedSearchService.updateChips$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.advancedSearchService.setupSearchChips();
    });
  }
}
