import { Injectable } from '@angular/core';
import { ISearch } from '@libs/shared/search/interface/search.interface';

@Injectable()
export class SearchParser {
  public decode(search: string): ISearch {
    const searchJSON = JSON.parse(search);

    if (searchJSON.location) {
      searchJSON.location_country_id = searchJSON.location.country_id;
      searchJSON.location_state_id = searchJSON.location.state_id;
      searchJSON.location_city_id = searchJSON.location.city_id;
      searchJSON.location_radius_first = searchJSON.location.radius_first;

      delete searchJSON.location;
    }

    if (searchJSON.birthdate) {
      searchJSON.age_between = searchJSON.birthdate;
      delete searchJSON.birthdate;
    }

    delete searchJSON.new_search;
    delete searchJSON.search_type;

    return searchJSON;
  }

  public encode(searchJSON: any): string {
    searchJSON.location = {
      country_id: searchJSON.location_country_id,
      state_id: searchJSON.location_state_id,
      city_id: searchJSON.location_city_id,
      radius_first: searchJSON.location_radius_first,
    };

    delete searchJSON.location_country_id;
    delete searchJSON.location_state_id;
    delete searchJSON.location_city_id;
    delete searchJSON.location_radius_first;

    return JSON.stringify(searchJSON);
  }
}
