import { Injectable, WritableSignal, signal } from '@angular/core';
import { MembershipCommon, MembershipType } from '@libs/shared/membership/membership.common';
import { PhotoCommon } from '@libs/shared/profile/photo.common';
import { AdvancedSearchCommon } from '@libs/shared/search/advanced-search.common';
import { ADVANCED_SEARCH_CONTROLS, SORT_ACTIVITY } from '@libs/shared/search/enum';
import { UserCommon } from '@libs/shared/user/user.common';
import { IAdvancedSearchFilterConfig } from '@meupatrocinio/modules/advanced-search/interfaces/advanced-search-filter-config.interface';
import { ISearchActivity } from '@meupatrocinio/modules/advanced-search/interfaces/search-activity.interface';
import { ISearchRangeConfig } from '@meupatrocinio/modules/advanced-search/interfaces/search-range-config';

@Injectable({ providedIn: 'root' })
export class AdvancedFilterStructureService {
  public searchStructure = signal<IAdvancedSearchFilterConfig[]>([]);
  public searchBasicStructure = signal<IAdvancedSearchFilterConfig[]>([]);
  public searchRangeStructure = signal<ISearchRangeConfig[]>([]);
  public searchActivityStructure = signal<ISearchActivity>({});
  public distanceStructure = signal<ISearchRangeConfig | null>(null);

  public initializeStructures() {
    this.buildSearch();
    this.buildBasicSearch();
    this.buildRange();
    this.buildSearchActivity();
    this.buildDistance();
  }

  public setInitialValuesForRangeStructure({
    age,
    height,
    distance,
  }: { age: number[]; height: number[]; distance: number }) {
    this.searchRangeStructure.update((structure) => [
      { ...structure[0], model: age },
      { ...structure[1], model: height },
    ]);
    this.distanceStructure.update((structure) => ({ ...structure, model: distance }));
  }

  public updateSearchHiddenAttribute(attribute: IAdvancedSearchFilterConfig) {
    this.toggleIsHidden(this.searchStructure, attribute);
  }

  public updateBasicSearchHiddenAttribute(attribute: IAdvancedSearchFilterConfig) {
    this.toggleIsHidden(this.searchBasicStructure, attribute);
  }

  public updateSearchRangeHiddenAttribute(attribute: ISearchRangeConfig) {
    this.toggleIsHidden(this.searchRangeStructure, attribute);
  }

  public updateSearchActivityHiddenAttribute() {
    this.searchActivityStructure.update((structure) => {
      structure.isHidden = !structure.isHidden;
      return structure;
    });
  }

  public resetAllHiddenAttributeOfFilters() {
    this.searchActivityStructure.update((structure) => ({ ...structure, isHidden: true }));
    this.searchStructure.update((structure) => {
      const updatedObject = structure.map((item) => ({ ...item, isHidden: true }));
      return updatedObject;
    });
    this.searchRangeStructure.update((structure) => {
      const updatedObject = structure.map((item) => ({ ...item, isHidden: true }));
      return updatedObject;
    });
    this.searchBasicStructure.update((structure) => {
      const updatedObject = structure.map((item) => ({ ...item, isHidden: true }));
      return updatedObject;
    });
  }

  private toggleIsHidden<T extends { name: string; isHidden?: boolean }>(
    itemsSignal: WritableSignal<T[]>,
    attribute: T,
  ) {
    const index = itemsSignal().findIndex((item) => item.name === attribute.name);

    if (index !== -1) {
      itemsSignal.update((structure) => {
        structure[index].isHidden = !attribute.isHidden;

        return structure;
      });
    }
  }

  private buildSearchActivity() {
    this.searchActivityStructure.set({
      attributes: [
        {
          translateKey: 'common.list.recently',
          id: SORT_ACTIVITY.RECENTLY,
        },
        {
          translateKey: 'common.list.newest',
          id: SORT_ACTIVITY.NEWEST,
        },
        {
          translateKey: 'common.list.nearest',
          id: SORT_ACTIVITY.NEAREST,
        },
      ],
      isHidden: true,
    });
  }

  private buildBasicSearch() {
    this.searchBasicStructure.set([
      {
        label: 'search-filter.badge.favorites',
        name: ADVANCED_SEARCH_CONTROLS.FAVORITE,
        status: UserCommon.favoritesStatus,
        key: UserCommon.favoritesKey,
        options: UserCommon.favoritesOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: true,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.viewed',
        name: ADVANCED_SEARCH_CONTROLS.VIEWED,
        status: UserCommon.viewStatus,
        key: UserCommon.viewKey,
        options: UserCommon.viewOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: true,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.photos',
        name: ADVANCED_SEARCH_CONTROLS.PHOTOS,
        status: (membershipId: MembershipType, id: number) =>
          PhotoCommon.photoStatusById(Number(id), MembershipCommon.isBaby(membershipId)),
        key: (name: string, membershipId: MembershipType) =>
          PhotoCommon.photoKeyByName(name, MembershipCommon.isBaby(membershipId)),
        options: (membershipId: MembershipType): string[] =>
          PhotoCommon.photoOptionsByMembership(MembershipCommon.isBaby(membershipId)),
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: true,
        isHidden: true,
      },
    ]);
  }

  private buildSearch() {
    this.searchStructure.set([
      {
        label: 'search-filter.badge.sex',
        name: ADVANCED_SEARCH_CONTROLS.SEX,
        status: UserCommon.getGenderStatus,
        key: UserCommon.getGenderKey,
        options: UserCommon.getGenderOptions,
        displayForDaddyMommy: true,
        displayForBisexual: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.match_sex',
        name: ADVANCED_SEARCH_CONTROLS.MATCH_SEX,
        status: UserCommon.getMatchSexStatus,
        key: UserCommon.getMatchSexKey,
        options: UserCommon.getLookingForOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.body_type',
        name: ADVANCED_SEARCH_CONTROLS.BODY_TYPE,
        status: UserCommon.getBodyTypeStatus,
        key: UserCommon.getBodyTypeKey,
        options: UserCommon.getBodyTypeOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.ethnicity',
        name: ADVANCED_SEARCH_CONTROLS.ETHNICITY,
        status: UserCommon.getEthnicityStatus,
        key: UserCommon.getEthnicityKey,
        options: UserCommon.getEthnicityStatusOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.eye_color',
        name: ADVANCED_SEARCH_CONTROLS.EYE_COLOR,
        status: UserCommon.getEyeColorStatus,
        key: UserCommon.getEyeColorKey,
        options: UserCommon.getEyeColorOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.hair_color',
        name: ADVANCED_SEARCH_CONTROLS.HAIR_COLOR,
        status: UserCommon.getHairColorStatus,
        key: UserCommon.getHairColorKey,
        options: UserCommon.getHairTypeOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.have_children',
        name: ADVANCED_SEARCH_CONTROLS.HAVE_CHILDREN,
        status: UserCommon.getChildrenStatus,
        key: UserCommon.getChildrenKey,
        options: UserCommon.getChildrenOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.happy_to_travel',
        name: ADVANCED_SEARCH_CONTROLS.HAPPY_TO_TRAVEL,
        status: UserCommon.getTravelStatus,
        key: UserCommon.getTravelKey,
        options: UserCommon.getTravelOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.relationship',
        name: ADVANCED_SEARCH_CONTROLS.RELATIONSHIP,
        status: UserCommon.getRelationshipStatus,
        key: UserCommon.getRelationshipKey,
        options: UserCommon.getRelationshipOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.smoke',
        name: ADVANCED_SEARCH_CONTROLS.SMOKE,
        status: UserCommon.getSmokeStatus,
        key: UserCommon.getSmokeKey,
        options: UserCommon.getSmokeOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.drink',
        name: ADVANCED_SEARCH_CONTROLS.DRINK,
        status: UserCommon.getDrinkStatus,
        key: UserCommon.getDrinkKey,
        options: UserCommon.getDrinkOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.education',
        name: ADVANCED_SEARCH_CONTROLS.EDUCATION,
        status: UserCommon.getEducationStatus,
        key: UserCommon.getEducationKey,
        options: UserCommon.getEducationOptions,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.occupation',
        name: ADVANCED_SEARCH_CONTROLS.OCCUPATION,
        status: UserCommon.getOccupationStatus,
        key: UserCommon.getOccupationKey,
        options: UserCommon.getOccupationStatusList,
        displayForDaddyMommy: true,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.income',
        name: ADVANCED_SEARCH_CONTROLS.INCOME,
        status: UserCommon.getIncomeStatus,
        key: UserCommon.getIncomeKey,
        options: UserCommon.getIncomeOptions,
        displayForDaddyMommy: false,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
      {
        label: 'search-filter.badge.net_worth',
        name: ADVANCED_SEARCH_CONTROLS.NET_WORTH,
        status: UserCommon.getNetWorthStatus,
        key: UserCommon.getNetWorthKey,
        options: UserCommon.getNetWorthOptions,
        displayForDaddyMommy: false,
        multiValued: true,
        isBasicFilter: false,
        isHidden: true,
      },
    ]);
  }

  private buildRange() {
    this.searchRangeStructure.set([
      {
        label: 'modules.main.pages.search_filter.age',
        currentValue: 'modules.main.pages.search_filter.age_filter',
        name: ADVANCED_SEARCH_CONTROLS.AGE,
        step: AdvancedSearchCommon.STEP_AGE,
        min: AdvancedSearchCommon.MIN_AGE,
        max: AdvancedSearchCommon.MAX_AGE,
        model: AdvancedSearchCommon.STANDARD_AGE_RANGE,
        isHidden: true,
        connect: [false, true, false],
        tooltips: [true, true],
      },
      {
        label: 'modules.main.pages.profile.details_page.height',
        currentValue: 'modules.main.pages.search_filter.height_filter',
        name: ADVANCED_SEARCH_CONTROLS.HEIGHT,
        step: AdvancedSearchCommon.STEP_HEIGHT,
        min: AdvancedSearchCommon.MIN_HEIGHT,
        max: AdvancedSearchCommon.MAX_HEIGHT,
        model: AdvancedSearchCommon.STANDARD_HEIGHT_RANGE,
        isHidden: true,
        connect: [false, true, false],
        tooltips: [true, true],
      },
    ]);
  }

  buildDistance() {
    this.distanceStructure.set({
      label: 'modules.main.pages.search_filter.distance',
      currentValue: 'modules.main.pages.search_filter.distance_km',
      name: ADVANCED_SEARCH_CONTROLS.LOCATION_RADIUS_FIRST,
      step: AdvancedSearchCommon.STEP_DISTANCE,
      min: AdvancedSearchCommon.MIN_DISTANCE,
      max: AdvancedSearchCommon.MAX_DISTANCE,
      model: AdvancedSearchCommon.DISTANCE_STANDARD,
      isHidden: true,
      connect: [true, false],
      tooltips: [true],
    });
  }
}
