import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { ProviderV2Actions } from '@libs/modules/payment-v2/actions';
import { IPixResponse } from '@libs/modules/payment-v2/interfaces/pix-response.interface';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { PaymentInfoActions, PaymentSelectors } from '@libs/store/payment-info';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';
import { PaymentFlowHandlersService } from '@meupatrocinio/modules/payment-v2/payment-flow-handlers/payment-flow-handlers.service';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions/actions';
import { PagSeguroV2Actions } from '@meupatrocinio/modules/payment-v2/providers/pagseguro-v2/actions';
import { PagSeguroV2Service } from '@meupatrocinio/modules/payment-v2/providers/pagseguro-v2/services/pagseguro-v2.service';

@Injectable()
export class PagSeguroV2Effects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private paymentFlowHandlersService = inject(PaymentFlowHandlersService);
  private pagSeguroService = inject(PagSeguroV2Service);

  handleCreditCardPayment$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PagSeguroV2Actions.handleCreditCardPayment),
        switchMap(({ paymentData, productDescription }) => {
          const { purchaseInfo } = paymentData;

          this.paymentFlowHandlersService.handlePaymentAttemptInitialActions({
            paymentProvider: 'pagseguro',
            value: purchaseInfo.subtotalAmount,
            product: purchaseInfo.product.product_uuid,
            productDescription,
          });

          return [
            PagSeguroV2Actions.createCreditCardPayment({ paymentData }),
            ProviderSwitchActions.tokenizeForAllCash({ paymentData }),
            ProviderSwitchActions.tokenizeForSafe2Pay({ paymentData }),
          ];
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  createCreditCardPayment$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PagSeguroV2Actions.createCreditCardPayment),
        withLatestFrom(this.store.select(PaymentSelectors.selectCurrentPayment)),
        switchMap(([{ paymentData }, currentPayment]) => {
          const DELAY_TO_CHECK_PAYMENT_STATUS = 4000 as const;
          const { purchaseInfo, holderInfo, cardInfo } = paymentData;

          return this.pagSeguroService.encryptCard(paymentData).pipe(
            concatMap((encryptedCard) => {
              if (!encryptedCard || !encryptedCard.length) {
                throw new Error('Failed to encrypt card.');
              }

              return this.pagSeguroService
                .createCreditCardPayment({
                  encryptedCard,
                  purchaseInfo,
                  holderInfo,
                  cardInfo,
                  idempotencyKey: currentPayment.idempotencyKey,
                })
                .pipe(
                  exponentialBackoff(),
                  concatMap(() => {
                    return of(ProviderV2Actions.checkPaymentStatus()).pipe(delay(DELAY_TO_CHECK_PAYMENT_STATUS));
                  }),
                  catchError(() => {
                    return this.paymentFlowHandlersService.handleCreatePaymentError();
                  }),
                );
            }),
            catchError(() => {
              return this.paymentFlowHandlersService.handleEncryptionError();
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  createBoletoPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PagSeguroV2Actions.createBoletoPayment),
        switchMap(({ paymentData }) => {
          this.paymentFlowHandlersService.setIsPaying(true);

          return this.pagSeguroService.createBoletoPayment({ paymentData }).pipe(
            exponentialBackoff(),
            map((response: IAuthResponse<{ url: string }>) => {
              this.paymentFlowHandlersService.handleBoletoGenerationSuccess(response);

              return PaymentInfoActions.setPaymentStatus({
                paymentStatus: PaymentStatus.PAYMENT_NONE,
              });
            }),
            catchError((error) => {
              this.paymentFlowHandlersService.handleBoletoGenerationError(error);

              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  createPixPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PagSeguroV2Actions.createPixPayment),
        tap({
          next: () => {
            this.paymentFlowHandlersService.setIsPaying(true);
          },
        }),
        concatMap(({ paymentData }) => {
          return this.pagSeguroService.createPixPayment({ paymentData }).pipe(
            exponentialBackoff(),
            map((response: IAuthResponse<IPixResponse>) => {
              const qrCode = response.data.base64Image;
              const copyCode = response.data.qrCode;
              const { product } = paymentData;

              this.store.dispatch(
                PaymentInfoActions.onQrCodeGeneration({
                  productUuid: product.product_uuid,
                }),
              );

              return PaymentInfoActions.qrCodeGeneratedSuccessfully({ qrCode, copyCode });
            }),
            catchError(() => {
              this.paymentFlowHandlersService.handleQRCodeError();

              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );
}
