import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfile } from '@libs/shared/profile/profile';

@Pipe({
  name: 'numberOfPhotos',
  pure: true,
  standalone: false,
})
export class NumberOfPhotos implements PipeTransform {
  constructor(public translate: TranslateService) {
    //
  }

  transform(profile: IProfile | undefined): string {
    if (profile === undefined || !profile.numberOfPhotos) {
      return '';
    }

    return this.translate.instant('modules.main.pages.profile.user_number_of_photos', {
      number_photos: profile.numberOfPhotos,
    });
  }
}
