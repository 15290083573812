<div class="modal-dialog modal-report">
  <div class="modal-content">
    <div class="modal-body">
      <p translate="modal.block.disclaimer"></p>
  </div>
      <div class="modal-footer">
          <mp-modal-action-button
              [text]="'modal.block.dimiss' | translate"
              [modifierClass]="'btn-default'"
              (click)="closeModal()"
          ></mp-modal-action-button>
          <mp-modal-action-button
              [text]="'modal.block.accept' | translate"
              [modifierClass]="'btn-danger'"
              (click)="blockeUser()"
          ></mp-modal-action-button>
      </div>
  </div>
</div>
