<form 
  [formGroup]="form" 
  class="tw-bg-white tw-flex tw-flex-col tw-gap-4 md:tw-w-[500px] tw-mx-2 tw-p-10 tw-rounded"
>
  <fieldset>
    <label for="keyword_search">
    {{ "modules.main.pages.search_filter.save_search.prompt" | translate }}
    </label>
  <div class="tw-min-w-[290px] tw-flex tw-items-center tw-p-2 tw-w-full tw-gap-4 tw-font-normal tw-border-b tw-border-gray-200 focus-within:tw-border-b-[#E00C00]">
    <input 
      class="tw-w-full tw-outline-none tw-font-normal"
      [placeholder]="'modules.main.pages.search_filter.advanced_filters.save_search.placeholder' | translate"
      formControlName="name"
    >
  </div>
  </fieldset>
  <div class="tw-full tw-flex tw-justify-end tw-gap-2">
    <button 
      class="tw-border tw-border-gray-600 tw-text-gray-600 tw-rounded-md tw-p-2 tw-px-4 tw-text-[14px]"
      (click)="close()"
      type="button"
    >
      {{ "common.cancel" | translate }}
    </button>
    <button 
      class="tw-bg-red-600 tw-rounded-md tw-p-2 tw-px-4 tw-text-[14px] tw-text-gray-50 disabled:tw-opacity-45"
      (click)="submit()" 
      type="submit"
      [disabled]="!form.valid" 
    >
    {{ "common.save" | translate }}
    </button>
  </div>
</form>
