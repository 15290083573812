import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { ListViewModeAction } from '@libs/store/ui/actions/list-view-mode.action';
import { PullRefreshAction } from '@libs/store/ui/actions/pullrefresh.action';
import { IUiState } from '@libs/store/ui/ui-state';
import { Tick } from '@libs/utils/timeout-typings';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mp-search-list',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './search-list.html',
  standalone: false,
})
export class SearchListComponent implements OnInit, OnChanges, OnDestroy {
  private store = inject(Store);
  private downloadManager = inject(DownloadManagerService);

  public profilesId$ = this.store.pipe(select('storeSearchProfiles'));

  loaderTimeout: Tick;
  refreshing = false;
  blockedProfileIds: number[] = [];

  onLoadMoreItemsRequestCallback: () => void;

  @Input() columnItems: number[] = [];
  @Input() items: number[] = [];
  @Input() isDesktop: boolean;

  @Output() readonly refreshList: EventEmitter<any> = new EventEmitter();
  @Output() readonly loadMoreItems: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.subscriptions.push(
      this.store.select('uiState').subscribe((uiState: IUiState): void => {
        this._listViewMode = uiState.listViewMode || false;
      }),
    );

    this.subscriptions.push(
      this.store.select('storeProfileBlockedUsers').subscribe((storeProfileBlockedUsers: number[]): void => {
        this.blockedProfileIds = storeProfileBlockedUsers;
      }),
    );

    this.onLoadMoreItemsRequestCallback = this.onLoadMoreItemsRequest.bind(this);

    if (!this.items || !this.items.length) {
      this.items = [0];
      this.downloadManager.resetPageCount('search');
      this.loadMoreItems.emit();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];

    if (this.loaderTimeout) {
      clearTimeout(this.loaderTimeout);
    }
    this.store.dispatch(new PullRefreshAction({ refreshing: false }));
  }

  ngOnChanges(args: SimpleChanges) {
    const items = args.items;

    if (!items || !items.currentValue) {
      return;
    }

    if (items.currentValue.length > 0) {
      this.refreshing = false;
    }

    this.columnItems = items.currentValue;

    if (this.columnItems.length > 0) {
      this.recalculateColumnItems();
    }
  }

  public get listViewMode(): boolean {
    return this._listViewMode;
  }

  public get isDownloading() {
    return this.downloadManager.isDownloading('search');
  }

  public set listViewMode(value: boolean) {
    this._listViewMode = value;
    this.store.dispatch(new ListViewModeAction(value));
  }

  protected subscriptions: Subscription[] = [];
  protected _listViewMode = false;

  onLoadMoreItemsRequest(): void {
    this.loadMoreItems.emit();
  }

  isListView(): boolean {
    return this.isDesktop && !this.listViewMode;
  }

  isGridView(): boolean {
    return !this.isDesktop || this.listViewMode;
  }

  recalculateColumnItems(): void {
    this.columnItems = this.columnItems.filter((item: number): boolean => this.blockedProfileIds.indexOf(item) < 0);
  }
}
