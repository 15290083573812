import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { INamedSearch } from '@libs/shared/search/interface/named-search.interface';
import { ISearch } from '@libs/shared/search/interface/search.interface';
import { IAdvancedSearchFilterConfig } from '@meupatrocinio/modules/advanced-search/interfaces/advanced-search-filter-config.interface';
import { ISearchRangeConfig } from '@meupatrocinio/modules/advanced-search/interfaces/search-range-config';

export const AdvancedSearchActions = createActionGroup({
  source: 'Search',
  events: {
    'Set Search': props<{ search: ISearch }>(),
    'Reset And Update Search': emptyProps(),
    'Fetch Search': props<{ search: ISearch }>(),
    'Delete Saved Search': props<{ id: number }>(),
    'Saved Search Removed': props<{ id: number }>(),
    'Create Or Update Saved Search': props<{ searchName: string; filter: ISearch }>(),
    'Set Current Search Name': props<{ name: string }>(),
    'Set Saved Searches': props<{ savedSearches: INamedSearch[] }>(),
    'Set Query Params': props<{ filter: ISearch }>(),
    'Reset Search Form': emptyProps(),
    'Handle Load Countries': emptyProps(),
    'Handle Load Regions': emptyProps(),
    'Handle Load Cities': emptyProps(),
    'Update Search Filter': props<{ filter: ISearch }>(),
    'Load List': emptyProps(),
    'Download Saved Search': emptyProps(),
    'Initialize Filters': emptyProps(),
    'Recover Saved Search': props<{ searchFilter: INamedSearch }>(),
    'Recover Saved Search On Load': emptyProps(),
    'Generic Recovery Saved Search Flow': props<{ canUpdate: boolean; search: ISearch; searchName: string }>(),
    'Load Profile Location Config': emptyProps(),
    'Focus Element': props<{
      sectionName: string;
      config: ISearchRangeConfig[] | IAdvancedSearchFilterConfig[];
    }>(),
    'Set is Searching': props<{ isSearching: boolean }>(),
    'Retrieve Stored Search': emptyProps(),
    'Can Recover Search': emptyProps(),
    'Set is Initialized Search': props<{ isInitialized: boolean }>(),
    'Set Search Chips': emptyProps(),
    'Set Is Displaying Result': props<{ isDisplayingResult: boolean }>(),
    'Set Last Country Name': props<{ name: string }>(),
    'Set Last Region Name': props<{ name: string }>(),
    'Set Last City Name': props<{ name: string }>(),
    'Handle Recover Search After Login': props<{ search: ISearch }>(),
    'Revoke Recover search': emptyProps(),
  },
});
