import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mp-text-input',
  templateUrl: './input-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputTextComponent {
  @Output() onEntter = new EventEmitter();
  @Input() form = new UntypedFormGroup({});
  @Input() placeholder = '';
  @Input() controlName = '';
  @Input() label = '';
}
