import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';
import { ITrialModalOnce } from '@libs/shared/trial/interface';
import { Trial } from '@libs/shared/trial/trial';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { TrialActions } from '@libs/store/trial';

@Injectable({
  providedIn: 'root',
})
export abstract class TrialServiceCommon implements OnDestroy {
  protected subscriptions: Subscription[] = [];
  protected gamificationTrial: Trial = new Trial({
    price: Trial.PRICE_7_DAYS.toNumber(),
    period: Trial.TRIAL_7_DAYS,
    next: {
      period: 1,
      units: 'months',
      price: 357,
    },
    units: 'days',
  });

  readonly MILLISECONDS_IN_A_MINUTE: number = 60000;
  readonly ONE_SECOND_IN_MILLISECONDS: number = 1000;

  protected readonly TRIAL_PERIODS: number[] = [7, 15];

  public readonly DEFAULT_TRIAL_PERIOD: number = 7;

  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpServiceCommon,
    protected router: Router,
    protected auth: AuthenticationServiceCommon,
  ) {
    //
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }

  protected get user() {
    return this.auth.get();
  }

  public getGameTrial(): Trial {
    return this.gamificationTrial;
  }

  public getGameTrialFreeMessageRule = (): boolean => {
    return this.getGameTrial().isAtPlans(this.router.url);
  };

  public isGameTrialFreeMessageSatisfied = (): boolean => {
    return MembershipCommon.isFree(this.user.membership_type_id) && !this.getGameTrial().isEmpty();
  };

  public abstract isElectiveToDeactivateModal(user: UserCommon): boolean;

  public isTrialProduct(planUuid: string) {
    return (
      planUuid === ProductVariantIdentifier.TRIAL_DADDY_PREMIUM_7_DAYS_ALTERNATIVE ||
      planUuid === ProductVariantIdentifier.DADDY_PREMIUM_15_DAYS
    );
  }

  startGamefiedTrial({
    firstTimer,
    satisfied,
    retryStrategy: { retryWhen, millisecondsRetry },
    trial,
    modalData,
  }: ITrialModalOnce): void {
    this.store.dispatch(
      TrialActions.initializeTrialOnceModal({
        trialProps: {
          firstTimer,
          satisfied,
          retryStrategy: {
            retryWhen,
            millisecondsRetry,
          },
          trial,
          modalData,
        },
      }),
    );
  }
}
