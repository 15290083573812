import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';

@Pipe({
  name: 'getTranslate',
  pure: true,
  standalone: false,
})
export class GetTranslate implements PipeTransform {
  constructor(public translate: TranslateService) {
    //
  }

  transform(attribute: IProfileAttributes): string {
    if (attribute.imageAlt === '') {
      return '';
    }

    return this.translate.instant(attribute.imageAlt, attribute.interpolateParams);
  }
}
