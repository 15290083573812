import { AdvancedSearchActions } from '@libs/store/search/actions';
import { ISearchState } from '@libs/store/search/interface/search-state.interface';
import { searchStateInitialState } from '@libs/store/search/state';
import { createReducer, on } from '@ngrx/store';

export const searchStateReducer = createReducer(
  searchStateInitialState,
  on(
    AdvancedSearchActions.setIsInitializedSearch,
    (state: ISearchState, { isInitialized }: { isInitialized: boolean }): ISearchState => {
      return {
        ...state,
        isInitialized,
      };
    },
  ),
  on(AdvancedSearchActions.setIsDisplayingResult, (state: ISearchState, { isDisplayingResult }) => {
    return {
      ...state,
      isDisplayingResult,
    };
  }),
  on(AdvancedSearchActions.setCurrentSearchName, (state: ISearchState, { name }) => {
    return {
      ...state,
      currentSearchName: name,
    };
  }),
  on(AdvancedSearchActions.setSearch, (state: ISearchState, { search }) => {
    return {
      ...state,
      profileSearch: search,
    };
  }),
  on(AdvancedSearchActions.setSavedSearches, (state: ISearchState, { savedSearches }) => {
    return {
      ...state,
      savedSearches,
    };
  }),
  on(AdvancedSearchActions.setLastCountryName, (state: ISearchState, { name }) => {
    return {
      ...state,
      lasCountryName: name,
    };
  }),
  on(AdvancedSearchActions.setLastRegionName, (state: ISearchState, { name }) => {
    return {
      ...state,
      lastRegionName: name,
    };
  }),
  on(AdvancedSearchActions.setLastCityName, (state: ISearchState, { name }) => {
    return {
      ...state,
      lastCityName: name,
    };
  }),
);
