<div
    class="tw-relative tw-m-8" 
    [ngClass]="{ open: canOpenList(savedSearches$ | async) }"
    (click)="menuEnter()" 
    (mouseleave)="menuLeave()"
>
    <p class="tw-font-bold tw-text-[18px]">
        {{ 'modules.main.pages.search_filter.saved_searches' | translate }}
    </p>
    <button
        class="tw-w-full tw-flex tw-justify-between tw-items-center tw-p-3 tw-text-[13px] tw-border-b tw-border-gray-300 disabled:tw-opacity-35 focus-within:tw-border-red-600"
        type="button" 
        id="ddSavedSearch" 
        data-toggle="dropdown" 
        aria-hasprompt="true" 
        aria-expanded="true"
        id="menu-button" 
        aria-expanded="true" 
        aria-haspopup="true" 
        [disabled]="(savedSearches$ | async).length === 0"
    >
        @if (isEmptySavedSearches(savedSearches$ | async)) {
            {{ 'modules.main.pages.search_filter.saved_searches.empty' | translate }}
        }
        @else if ((currentSelectedSearch$ | async) === "") {
            {{ 'modules.main.pages.search_filter.saved_searches_and_options' | translate }}
        } @else {
            {{ currentSelectedSearch$ | async }}
        }
        <i 
            class="material-icons !tw-text-[14px] tw-transform tw-transition-transform tw-duration-300"
            [class.tw-rotate-180]="isOpen()" 
            aria-hidden="true"
        >
            &#xe5cf;
        </i>
    </button>
    @if (isOpen()) {
    <ul 
        class="tw-absolute tw-w-full tw-gap-1 tw-transition tw-ease-in-out tw-border tw-border-gray-100 tw-rounded tw-bg-white tw-z-[1000] tw-shadow-md"
        id="savedSearchList" 
        aria-labelledby="ddSavedSearch"
    >
        @for (search of savedSearches$ | async; track $index) {
        <li class="!tw-flex tw-justify-between tw-items-center tw-cursor-pointer hover:tw-bg-[#fafafa]"
            [ngClass]="currentSearchModifierClass(search, currentSelectedSearch$ | async)">
            <div 
                class="tw-flex tw-items-center tw-gap-2 tw-text-[14px] tw-w-full tw-p-4"
                [ngClass]="{ 'tw-font-semibold': (currentSelectedSearch$ | async) === search.name}"
                (click)="restoreSearch(search)"
            >
                @if ((currentSelectedSearch$ | async) === search.name) {
                <i 
                    class="material-icons !tw-text-[14px] tw-text-gray-600" 
                    aria-hidden="true"
                >
                    &#xe5ca;
                </i>
                }
                <button class="tw-appearance-none tw-w-full tw-text-start">{{ search.name }}</button>
            </div>
            <button 
                (click)="removeSearch(search.id)" 
                class="tw-p-4 tw-flex tw-items-center tw-justify-center" 
                type="button" 
                data-toggle="modal"
                data-target=".delete-search-modal"
            >
                <i 
                    class="material-icons !tw-text-[18px] hover:tw-text-red-700 tw-text-gray-600" 
                    aria-hidden="true"
                >
                    &#xe5cd;
                </i>
            </button>
        </li>
        }
    </ul>
    }
</div>
