import { Location } from '@angular/common';
import { Injectable, inject, signal } from '@angular/core';
import { ICity } from '@libs/services/location/city/city';
import { ICountry } from '@libs/services/location/country/country';
import { IRegion } from '@libs/services/location/region/region';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { AdvancedSearchCommon } from '@libs/shared/search/advanced-search.common';
import { SEARCH_DEFAULT_VALUES } from '@libs/shared/search/constraints';
import { ADVANCED_SEARCH_CONTROLS } from '@libs/shared/search/enum';
import { ISearch } from '@libs/shared/search/interface/search.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { LocationSelectors } from '@libs/store/regv5/location';
import { AdvancedSearchActions } from '@libs/store/search/actions';
import { AdvancedSearchSelectors } from '@libs/store/search/selectors';
import { FormHelpers } from '@libs/utils/form-helpers/form-helpers';
import { ScreenSizeHelpers } from '@libs/utils/screen-size-helpers/screen-size-helpers';
import { SaveSearchModalComponent } from '@meupatrocinio/modules/advanced-search/components/save-search-modal/save-search-modal.component';
import { AdvancedFilterStructureService } from '@meupatrocinio/modules/advanced-search/services/advanced-filter-structure.service';
import { SearchFormManagerService } from '@meupatrocinio/modules/advanced-search/services/search-form-manager.service';
import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { LocationService } from '@meupatrocinio/services/location/location.service';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AdvancedSearchService {
  private searchFormManagerService = inject(SearchFormManagerService);
  private location = inject(Location);
  private locationService = inject(LocationService);
  private store = inject(Store);
  private advancedModalService = inject(AdvancedModalService);
  private authenticationService = inject(AuthenticationService);
  private downloadManagerService = inject(DownloadManagerService);
  private advancedFilterStructureService = inject(AdvancedFilterStructureService);

  public updateChipsSubject = new Subject();
  public changeDetectorFilterSubject = new Subject();
  public isDownloadingSubject = new Subject();
  public updateChips$ = this.updateChipsSubject.asObservable();
  public filterChangeDetector$ = this.changeDetectorFilterSubject.asObservable();

  public countries: ICountry[] = [];
  public regions: IRegion[] = [];
  public cities: ICity[] = [];
  public isInitializing = signal<boolean>(true);
  public isSearching = signal<boolean>(false);
  public queryParams = signal({});

  public countries$ = this.store.pipe(
    select(LocationSelectors.selectLocationStoreCountries),
    map((countries) => countries.filter((country) => this.locationService.filterCountries(country))),
  );
  public regions$ = this.store.pipe(select(LocationSelectors.selectLocationStoreRegions));
  public cities$ = this.store.pipe(select(LocationSelectors.selectLocationStoreCities));
  public searchFilter$ = this.store.pipe(select(AdvancedSearchSelectors.searchFilter));
  public displayingResult$ = this.store.pipe(select(AdvancedSearchSelectors.isDisplayingResult));
  public canLoadMoreItems = new BehaviorSubject(false);

  public setupSearchChips() {
    this.store.dispatch(AdvancedSearchActions.setSearchChips());
  }

  public recoveryProfileLocation() {
    this.searchFormManagerService.filter.set({
      ...this.searchFormManagerService.filter(),
      location_country_id: this.user.country_id,
      location_state_id: this.user.state_id,
      location_city_id: this.user.city_id,
    });

    this.locationService.loadCountries();
    this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID).enable();
    this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID).enable();
  }

  public saveSearch() {
    this.searchFormManagerService.filter.set(this.searchQueryParams);
    this.advancedModalService.open(SaveSearchModalComponent, {
      data: { filter: this.searchFormManagerService.filter() },
    });
  }

  public update(filter?: ISearch) {
    this.canLoadMoreItems.next(true);

    if (ScreenSizeHelpers.isMobile()) {
      this.resetAllHiddenAttributeOfFilters();
    }

    this.isSearching.set(true);
    this.store.dispatch(AdvancedSearchActions.setIsDisplayingResult({ isDisplayingResult: true }));
    this.store.dispatch(AdvancedSearchActions.updateSearchFilter({ filter: filter || this.searchQueryParams }));
  }

  public loadLocation() {
    this.loadCountries();
    this.store.dispatch(AdvancedSearchActions.handleLoadCountries());
  }

  public get searchQueryParams() {
    const getSearchFilterControlValue = (controlName: string): number[] =>
      this.searchFormManagerService.searchFilterForm.get(controlName)?.value;

    const getBasicFilterControlValue = (controlName: string): number[] =>
      this.searchFormManagerService.basicFilterForm.get(controlName)?.value;

    this.queryParams.set({
      age_between: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.AGE),
      body_type: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.BODY_TYPE),
      drink: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.DRINK),
      education: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.EDUCATION),
      ethnicity: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.ETHNICITY),
      eye_color: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.EYE_COLOR),
      hair_color: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.HAIR_COLOR),
      happy_to_travel: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.HAPPY_TO_TRAVEL),
      have_children: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.HAVE_CHILDREN),
      height: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.HEIGHT),
      income: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.INCOME),
      keyword_search: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.KEYWORD_SEARCH),
      location_city_id: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID),
      location_country_id: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.LOCATION_COUNTRY_ID),
      location_state_id: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID),
      location_radius_first: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.LOCATION_RADIUS_FIRST),
      match_sex: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.MATCH_SEX),
      net_worth: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.NET_WORTH),
      occupation: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.OCCUPATION),
      relationship: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.RELATIONSHIP),
      sex: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.SEX),
      smoke: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.SMOKE),
      sortkey: getSearchFilterControlValue(ADVANCED_SEARCH_CONTROLS.SORTKEY),
      openedTab: true,
    });

    this.queryParams.set({
      ...this.queryParams(),
      basic_filter: {
        favorite: [...getBasicFilterControlValue(ADVANCED_SEARCH_CONTROLS.FAVORITE)].includes(
          AdvancedSearchCommon.FILTER_FAVORITED_BY_ME,
        ),
        favorited_me: [...getBasicFilterControlValue(ADVANCED_SEARCH_CONTROLS.FAVORITE)].includes(
          AdvancedSearchCommon.FILTER_FAVORITED_ME,
        ),
        has_photos:
          [...getBasicFilterControlValue(ADVANCED_SEARCH_CONTROLS.PHOTOS)].includes(
            AdvancedSearchCommon.FILTER_HAS_PHOTOS,
          ) && MembershipCommon.isBaby(this.user.membership_type_id),
        verified_photos:
          [...getBasicFilterControlValue(ADVANCED_SEARCH_CONTROLS.PHOTOS)].includes(
            AdvancedSearchCommon.FILTER_VERIFIED_PHOTOS,
          ) && MembershipCommon.isDaddyMommyType(this.user.membership_type_id),
        featured: [...getBasicFilterControlValue(ADVANCED_SEARCH_CONTROLS.PHOTOS)].includes(
          AdvancedSearchCommon.FILTER_FEATURED,
        ),
        unviewed: [...getBasicFilterControlValue(ADVANCED_SEARCH_CONTROLS.VIEWED)].includes(
          AdvancedSearchCommon.FILTER_NOT_VIEWED,
        ),
        viewed: [...getBasicFilterControlValue(ADVANCED_SEARCH_CONTROLS.VIEWED)].includes(
          AdvancedSearchCommon.FILTER_VIEWED_BY_ME,
        ),
        viewed_me: [...getBasicFilterControlValue(ADVANCED_SEARCH_CONTROLS.VIEWED)].includes(
          AdvancedSearchCommon.FILTER_VIEWED_ME,
        ),
      },
    });

    return this.queryParams();
  }

  public updateRegionOptions() {
    this.isSearching.set(false);
    this.searchFormManagerService.filter.update((search) => ({ ...search, location_state_id: null }));
    this.searchFormManagerService.filter.update((search) => ({ ...search, location_city_id: null }));

    const countryCode = FormHelpers.getFormControlValue(
      this.searchFormManagerService.searchFilterForm,
      ADVANCED_SEARCH_CONTROLS.LOCATION_COUNTRY_ID,
    );

    if (typeof countryCode !== 'string') {
      this.searchFormManagerService.searchFilterForm
        .get(ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID)
        .setValue(undefined);
      this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID).setValue(undefined);
      this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID).disable();
      this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID).disable();

      return;
    }

    this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID).setValue(undefined);
    this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID).setValue(undefined);
    this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID).enable();
    this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID).disable();

    this.locationService.loadRegions(countryCode);
  }

  public updateCityOptions() {
    const region: number | string = FormHelpers.getFormControlValue(
      this.searchFormManagerService.searchFilterForm,
      ADVANCED_SEARCH_CONTROLS.LOCATION_STATE_ID,
    );

    this.isSearching.set(false);
    this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID).setValue(undefined);

    if (typeof region === 'string') {
      this.locationService.loadCities(region);
      this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID).enable();
      this.store.dispatch(AdvancedSearchActions.handleLoadCities());

      return;
    }

    this.searchFormManagerService.searchFilterForm.get(ADVANCED_SEARCH_CONTROLS.LOCATION_CITY_ID).disable();
  }

  public setDefaultCountry(user: UserCommon) {
    FormHelpers.setFormControlValue(this.searchFormManagerService.searchFilterForm, 'country', user.country_id);
  }

  public loadCountries() {
    this.locationService.loadCountries();
  }

  public loadRegions(countries: ICountry[]) {
    const countryName = FormHelpers.getFormControlValue(this.searchFormManagerService.searchFilterForm, 'country');
    this.locationService.loadRegions(this.locationService.getCountryCode(countries, countryName));
  }

  public applyQueryParams(search: ISearch) {
    const transformedParams = this.prepareSearchParams(search);
    const searchParams = new URLSearchParams();

    this.populateURLSearchParams(transformedParams, searchParams);

    const queryString = searchParams.toString();
    const currentPath = this.location.path().split('?')[0] || '';

    this.location.replaceState(currentPath, queryString);
  }

  public resetFilter() {
    this.resetAllHiddenAttributeOfFilters();
    this.isInitializing.set(true);
    this.store.dispatch(AdvancedSearchActions.setCurrentSearchName({ name: '' }));
    this.searchFormManagerService.filter.set(SEARCH_DEFAULT_VALUES);
    this.recoveryProfileLocation();
    this.searchFormManagerService.clearFormWithSearchValues(this.user);

    this.downloadManagerService.resetPageCount('search');
    this.update();
  }

  private prepareSearchParams(search: ISearch) {
    let queryParams: ISearch = {};

    for (const key of Object.keys(search)) {
      if (!search[key]) {
        continue;
      }

      if (search[key].length || typeof search[key] === 'number') {
        queryParams[key] = search[key];
        continue;
      }

      if (!Array.isArray(search[key])) {
        queryParams = { ...queryParams, ...search[key] };
      }
    }

    return queryParams;
  }

  private populateURLSearchParams(search: ISearch, searchParams: URLSearchParams) {
    for (const [key, value] of Object.entries(search)) {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          if (val !== undefined && val !== null) {
            searchParams.append(key, String(val));
          }
        });
      } else {
        if (value !== undefined && value !== null) {
          searchParams.set(key, String(value));
        }
      }
    }
  }

  private get user() {
    return this.authenticationService.get();
  }

  public resetAllHiddenAttributeOfFilters() {
    this.advancedFilterStructureService.resetAllHiddenAttributeOfFilters();
  }
}
